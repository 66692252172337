export const CURRENCIES = {
  EMPTY: '?',
  INDIA: '₹',
  USA: '$'
};

export const YEARLY_PRICES = {
  INDIA: '2499',
  USA: '99'
};

export const PACKAGE_TYPES = {
  PREMIUM_ANNUAL: 'PREMIUM_ANNUAL',
  PREMIUM_MONTHLY: 'PREMIUM_MONTHLY'
};

export const PACKAGE_TIME_PERIOD = {
  MONTH: 'MONTH',
  SIX_MONTHS: 'SIX_MONTHS',
  YEAR: 'YEAR'
};
