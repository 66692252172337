import maxBy from 'lodash/maxBy';

import referralClient from 'httpClient/referral';
import { DETAIL_TYPES } from 'constants/constants';
import { getURLImage } from 'utils/helpers';

class ReferralService {
  static getShareDeepLink({ video, type, isInsight }) {
    const image = video.images?.url || getURLImage(video.images);
    const insight = video?.metas?.InsightDescription?.value;
    const metaImage = maxBy(video.images, image => image?.version);

    const body = {
      title: video.name,
      description: isInsight && insight ? insight : video.description,
      image: `${image}/width/1600/height/900`,
      deeplinkParams: {
        imageId: metaImage?.id,
        imageVersion: String(metaImage?.version)
      }
    };

    if (type === DETAIL_TYPES.EPISODE) {
      body.deeplinkParams = {
        seriesId: video.seriesId || video.metas.SeriesID.value,
        episodeId: String(video.id)
      };
    } else if (type === DETAIL_TYPES.SERIES) {
      body.deeplinkParams.seriesId = video.metas.SeriesID.value;
    } else if (type === DETAIL_TYPES.SINGLE_VIDEO) {
      body.deeplinkParams.assetId = String(video.id);
    }

    return referralClient.post('/metatag/share/video', body);
  }
}

export default ReferralService;
