import React, { useEffect, useRef } from 'react';
import { string } from 'prop-types';
import lottie from 'lottie-web';
import cn from 'classnames';
import loadingAnimation from 'assets/animations/loading.json';

const Loading = ({ id = 'status', className = '' }) => {
  const anime = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation
    });

    return () => lottie.stop();
  }, []);

  return (
    <div className={cn('d-flex justify-content-center align-items-center', className)}>
      <div className="loading-wrapper" aria-live="polite" role="status" data-testid={id}>
        <div ref={anime} />
      </div>
    </div>
  );
};

Loading.propTypes = {
  id: string,
  className: string
};

export default Loading;
