import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useSession, useCampaign } from 'hooks';

import RouteFromPath from 'components/routes/RouteFromPath';

import AnalyticManager from 'services/analytics/AnalyticManager';
import GAScreenEvent from 'services/analytics/events/GAScreenEvent';
import { changeRoute } from 'state/actions/routingActions';
import { setAutoplay } from 'state/actions/videoActions';

import routesPaths from 'constants/routesPaths';
import routes from '../routes';

const DETAIL_PAGE_WORD = routesPaths.detail;

const Router = () => {
  const { user } = useSession();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useCampaign();

  const { previousPath, currentPath } = useSelector(state => state.routing);
  const noInternet = useSelector(state => state.statusReducer.noInternet);

  useEffect(() => {
    if (pathname !== currentPath) dispatch(changeRoute({ currentPath: pathname }));
  }, [dispatch, pathname, currentPath]);

  useEffect(() => {
    window.scrollTo(0, 0);

    AnalyticManager.logScreen(GAScreenEvent.screen({ currentPath, previousPath }));

    if (previousPath?.includes(DETAIL_PAGE_WORD) && !currentPath?.includes(DETAIL_PAGE_WORD)) {
      dispatch(setAutoplay({ autoplay: false }));
    }
  }, [previousPath, currentPath, dispatch]);

  return (
    <Switch>
      {routes.map((route, index) => (
        <RouteFromPath
          key={`route${index}`}
          {...route}
          authenticated={user}
          noInternet={noInternet}
        />
      ))}
    </Switch>
  );
};

export default Router;
