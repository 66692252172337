export const EXPIRED_TOKEN_ERROR = '500016';
export const SERVICE_FORBIDDEN_ERROR = '500004';
export const INCORRECT_PASSWORD = '1011';
export const DEVICE_ANOTHER_HOUSEHOLD = '1016';
export const DEVICE_ALREADY_EXISTS_IN_HOUSEHOLD = '1015';
export const HOUSEHOLD_EXCEEDED_LIMIT = '1001';
export const HOUSEHOLD_NOT_EXISTS = '1006';
export const INSIGHT_ALREADY_SAVED = '8013';
export const NOT_ENTITLED_ERROR = '2001';
export const ALREADY_PURCHASED_USER = '3024';
export const NETWORK_ERROR = 'Network Error';
export const LOGIN_POPUP_CLOSED = 'auth/popup-closed-by-user';
export const PAYMENT_FAILED = 'Failed';
