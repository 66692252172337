import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement } from '@stripe/react-stripe-js';

import {
  createCustomer,
  addCard,
  purchase,
  resetGetConfiguration,
  resetCreateCustomer,
  resetAddCard,
  resetPurchase
} from 'state/actions/paymentActions';
import { getValidEntitlementsReset } from 'state/actions/subscriptionActions';
import { useStatus } from 'hooks';
import AnalyticManager from 'services/analytics/AnalyticManager';
import CTPaymentEvent from 'services/analytics/events/CTPaymentEvent';

import { FULFILLED, REJECTED } from 'constants/actionStatusConstants';
import GAPaymentEvent from 'services/analytics/events/GAPaymentEvent';

export default ({
  stripe,
  elements,
  onSuccess,
  packageDetails,
  promocode,
  startDate,
  endDate,
  packageType
}) => {
  const [formError, setFormError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [purchasedFullfilled, setPurchasedFullfilled] = useState(false);

  const dispatch = useDispatch();

  const createCustomerRequest = stripeToken => dispatch(createCustomer({ stripeToken }));
  const addCardRequest = stripeToken => dispatch(addCard({ stripeToken }));
  const purchaseRequest = useCallback(() => {
    dispatch(purchase({ packageDetails, promocode, startDate, endDate, paymentMethod }));
  }, [packageDetails, startDate, endDate, promocode, paymentMethod, dispatch]);

  const { status: createCustomerStatus, error: createCustomerError } = useStatus(createCustomer);
  const { status: addCardStatus, error: addCardError } = useStatus(addCard);
  const { status: purchaseStatus, error: purchaseError } = useStatus(purchase);

  const paymentMethods = useSelector(state => state.payment.methods);
  const hasMethods = !!paymentMethods?.length;

  const clearError = () => {
    setFormError(null);
  };

  const handlePurchase = async e => {
    e.preventDefault();
    dispatch(resetPurchase());
    dispatch(resetCreateCustomer());
    dispatch(resetAddCard());
    clearError();

    if (!stripe || !elements) return;

    setIsDisabled(true);
    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setFormError(error.message);
      setIsLoading(false);
      setIsDisabled(false);
    } else {
      const { funding } = token.card;
      const revenue = packageDetails.price?.amount;
      AnalyticManager.logEvent(CTPaymentEvent.payClick({ promocode, revenue, packageType }));
      AnalyticManager.logEvent(
        GAPaymentEvent.payClick({ promocode, revenue, funding, packageType })
      );
      hasMethods ? addCardRequest(token.id) : createCustomerRequest(token.id);
      setPaymentMethod(funding);
    }
  };

  useEffect(() => {
    if (purchaseStatus === FULFILLED && !purchasedFullfilled) {
      dispatch(getValidEntitlementsReset());
      setPurchasedFullfilled(true);
      onSuccess();
      setIsLoading(false);
    }

    if (purchaseStatus === REJECTED && isLoading) {
      setFormError(purchaseError);
      setIsLoading(false);
      setIsDisabled(false);
    }
  }, [purchaseStatus, onSuccess, dispatch, purchaseError, purchasedFullfilled, isLoading]);

  useEffect(() => {
    if (!purchaseStatus) {
      if (addCardStatus === FULFILLED || createCustomerStatus === FULFILLED) {
        purchaseRequest();
      }
    }

    if (addCardStatus === REJECTED) {
      setFormError(addCardError);
      setIsLoading(false);
      setIsDisabled(false);
    }

    if (createCustomerStatus === REJECTED) {
      setFormError(createCustomerError);
      setIsLoading(false);
      setIsDisabled(false);
    }
  }, [
    addCardStatus,
    hasMethods,
    createCustomerStatus,
    purchaseRequest,
    addCardError,
    createCustomerError,
    purchaseStatus
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetGetConfiguration());
      dispatch(resetCreateCustomer());
      dispatch(resetAddCard());
      dispatch(resetPurchase());
    };
  }, []);

  return { isLoading, handlePurchase, error: formError, clearError, isDisabled };
};
