import { DETAIL_TYPES, COUNTRIES } from 'constants/constants';
import { VIEW } from 'services/constants';
import KalturaConstants from 'constants/kaltura';
import { PACKAGE_TYPES } from 'constants/subscriptions';
import store from 'state/store/storeRegistry';
import { diffDates } from 'utils/dateHelpers';

import {
  NOT_AVAILABLE,
  SUBSCRIPTION_STATUS,
  CURRENCIES,
  SUBSCRIPTIONS,
  PAYMENT_METHOD,
  SCREENS,
  CATEGORY_REGEX,
  USER_TYPE,
  CATEGORY_NAME,
  MARKETING_LANDING_REGEX,
  VALUE_PROP_REGEX,
  PLATFORM
} from './constants';
import ROUTES_MAP from './routes';

export const joinValues = node =>
  node?.objects?.map(object => object.value)?.join() || NOT_AVAILABLE;

export const getValue = value => {
  return value === 0 ? 0 : value || NOT_AVAILABLE;
};

export const setContent = (params, mediaSource) => {
  const media = mediaSource || store.getState().videos.selected;

  if (!media) return;

  Object.assign(params, {
    content_name: getValue(media?.name),
    content_asset_type:
      media?.typeDescription !== DETAIL_TYPES.MOVIE ? DETAIL_TYPES.EPISODE : NOT_AVAILABLE,
    content_genre: joinValues(media?.tags?.Genre),
    content_provider: getValue(media?.tags?.Provider?.objects?.[0]?.value),
    content_series_name: getValue(media?.tags?.SeriesName?.objects?.[0]?.value),
    content_episode_number: getValue(media?.metas?.EpisodeNumber?.value),
    content_duration: getValue(media?.mediaFiles?.[0]?.duration),
    content_category_from: getValue(media?.tags?.Genre?.objects?.[0]?.value),
    content_series_id: getValue(media?.metas?.SeriesID?.value?.toLowerCase()),
    content_subgenre: getValue(media?.tags?.SubGenre?.objects[0]?.value),
    content_id: getValue(media?.id),
    content_video_id: getValue(media?.externalId?.toLowerCase()),
    content_packages: getValue(media?.tags?.Packages?.objects?.[0]?.value),
    content_country: getValue(media?.tags?.Country?.objects[0]?.value),
    primary_skill: joinValues(media?.tags?.Keywords),
    secondary_skill: joinValues(media?.tags?.Category)
  });
};

export const getSubscriptionStatus = () =>
  store.getState().user?.purchaseId ? SUBSCRIPTION_STATUS.paidUser : SUBSCRIPTION_STATUS.freeUser;

export const getCurrentPath = () => store.getState().routing.currentPath;

export const getPreviousPath = () => store.getState().routing.previousPath;

export const getCurrentScreen = () => {
  const currentPath = getCurrentPath();
  if (CATEGORY_REGEX.subcategory.test(currentPath)) {
    return SCREENS.subcategory;
  }
  if (CATEGORY_REGEX.category.test(currentPath)) {
    return SCREENS.category;
  }
  if (MARKETING_LANDING_REGEX.marketingLanding.test(currentPath)) {
    return SCREENS.marketingLanding;
  }
  if (VALUE_PROP_REGEX.marketingLandingValueProp.test(currentPath)) {
    return SCREENS.marketingLandingValueProp;
  }
  return getValue(ROUTES_MAP[currentPath]);
};

export const getPreviousScreen = () => {
  const previousPath = getPreviousPath();
  if (CATEGORY_REGEX.subcategory.test(previousPath)) {
    return SCREENS.subcategory;
  }
  if (CATEGORY_REGEX.category.test(previousPath)) {
    return SCREENS.category;
  }
  if (MARKETING_LANDING_REGEX.marketingLanding.test(previousPath)) {
    return SCREENS.marketingLanding;
  }
  if (VALUE_PROP_REGEX.marketingLandingValueProp.test(previousPath)) {
    return SCREENS.marketingLandingValueProp;
  }
  return getValue(ROUTES_MAP[previousPath]);
};

export const getGADeviceView = () =>
  store.getState().info.deviceView.view === VIEW.desktop ? PLATFORM.desktop : PLATFORM.mobile;

export const getCurrency = () =>
  store.getState().session.country?.code === COUNTRIES.INDIA ? CURRENCIES.INR : CURRENCIES.USD;

export const getSubscriptions = () =>
  store.getState().session.country?.code === COUNTRIES.INDIA
    ? SUBSCRIPTIONS.INDIA
    : SUBSCRIPTIONS.INTERNATIONAL;

export const getUser = () => store.getState().session.user;
export const getUdid = () => store.getState().session.udid;

export const getContentId = () => store.getState().videos.selected?.id;

export const getOrderId = () => store.getState().payment.orderId;

export const getSelectedVideo = () => store.getState()?.videos?.selected;

export const getPaymentMethod = method => PAYMENT_METHOD[method] || NOT_AVAILABLE;

export const getUserType = () =>
  store.getState().session.user ? USER_TYPE.loggedIn : USER_TYPE.guest;

export const isMonthlyPlan = () => {
  const { endDate, purchaseDate } = store.getState().subscriptions.validEntitlements?.[0];
  return diffDates(endDate, purchaseDate, 'month') <= 1.04;
};

export const getPackageType = () => {
  return isMonthlyPlan() ? PACKAGE_TYPES.PREMIUM_MONTHLY : PACKAGE_TYPES.PREMIUM_ANNUAL;
};

export const getSignupChannel = () => store.getState().session.signupMethod;

export const getAnnualPrice = () =>
  store.getState().subscriptions.packageDetails?.PREMIUM_ANNUAL?.price;

export const getWatchedUpToPercentage = (duration, watchTime) => {
  const durationPercent = watchTime / duration;
  return {
    watchedUpto25: durationPercent <= 0.25,
    watchedUpto50: durationPercent > 0.25 && durationPercent <= 0.5,
    watchedUpto75: durationPercent > 0.5 && durationPercent <= 0.75,
    watchedUpto100: durationPercent > 0.75
  };
};

export const getCategoryName = category => {
  switch (category) {
    case KalturaConstants.Categories.ContinueWatching:
    case KalturaConstants.Categories.RecommendedForYou:
    case KalturaConstants.Categories.TrendingNow:
      return CATEGORY_NAME.homeScreenVideo;
    case KalturaConstants.Categories.Books:
      return CATEGORY_NAME.books;
    case KalturaConstants.Categories.Universities:
      return CATEGORY_NAME.universities;
    case KalturaConstants.Categories.ThoughtLeaders:
      return CATEGORY_NAME.thoughtLeaders;
    default:
      return category?.toLowerCase();
  }
};
