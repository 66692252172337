import {
  getCurrentScreen,
  getSubscriptionStatus,
  getUserType,
  getValue,
  getUser
} from 'services/analytics/utils';
import { PROMOTION_CODE_APPLIED_GA } from 'services/analytics/constants';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  campaignDetail: 'campaign_detail'
};

class GACampaignEvent extends AnalyticEvent {
  static campaignDetail({ source, medium, campaign, term, content, coupon }) {
    const user = getUser();

    return new GACampaignEvent(EVENTS.campaignDetail, {
      screen_name: getCurrentScreen(),
      user_type: getUserType(),
      utm_source: getValue(source),
      utm_medium: getValue(medium),
      utm_campaign: getValue(campaign),
      utm_term: getValue(term),
      utm_content: getValue(content),
      subscription_status: getSubscriptionStatus(),
      promocode: getValue(coupon),
      promocode_applied: coupon ? PROMOTION_CODE_APPLIED_GA.yes : PROMOTION_CODE_APPLIED_GA.no,
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId)
    });
  }
}

export default GACampaignEvent;
