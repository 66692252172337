import CategoryService from 'services/CategoryService';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';
import KalturaConstants from 'constants/kaltura';

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  try {
    const {
      data: { params }
    } = await CategoryService.getRootId();
    const { Root: categoryItemId } = params[KalturaConstants.CategoryTreeName];
    const mediaTypes = params[KalturaConstants.MediaTypesName];

    const {
      data: { children }
    } = await CategoryService.getAll({ categoryItemId });

    const home = children.find(child => child.name === KalturaConstants.Categories.HomeRails);
    const browse = children.find(child => child.name === KalturaConstants.Categories.BrowseRails);

    return {
      home: home?.children || [],
      browse: browse?.children || [],
      mediaTypes
    };
  } catch (response) {
    throw parseError(response);
  }
});

export const { fulfilled: fetchCategoriesFulfilled } = fetchCategories;
