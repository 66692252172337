import { PACKAGE_TYPES } from 'constants/subscriptions';
import KalturaConstants from 'constants/kaltura';

export const PLATFORM = {
  desktop: 'd_web',
  mobile: 'm_web'
};

export const SUBSCRIPTION_STATUS = {
  freeUser: 'free_user',
  paidUser: 'paid_user'
};

export const SUBSCRIPTION_STATUS_CT = {
  free: 'Free',
  paid: 'Paid'
};

export const NOT_AVAILABLE = 'na';

export const USER_TYPE = {
  loggedIn: 'loggedin',
  guest: 'guest'
};

export const USER_TYPE_CT = {
  loggedIn: 'logged In',
  loggedOut: 'logged out'
};

export const SCREENS = {
  home: 'home_screen',
  browse: 'browse_screen',
  detail: 'detail_screen',
  category: 'category_screen',
  subcategory: 'subcategory_screen',
  signup: 'sign_up_screen',
  signinWithEmail: 'sign_in_with_email_screen',
  signupWithEmail: 'sign_up_with_email_screen',
  onboarding: 'onboarding_screen',
  forgotPassword: 'forgot_password_screen',
  resetPassword: 'reset_password_screen',
  settings: 'settings_screen',
  subscription: 'subscription_screen',
  playback: 'playback_screen',
  upsellScreen: 'upsell_screen',
  payment: 'payment_screen',
  myInsights: 'my-insights',
  mobileLanding: 'landing_page_1',
  marketingLanding: 'marketing_landing_page',
  marketingLandingValueProp: 'marketing_landing_value_prop_page',
  etPrime: 'et_landing_page',
  plan_details: 'plan_details_screen'
};

export const CHANNELS = {
  email: 'email',
  facebook: 'facebook',
  gmail: 'gmail'
};

export const STATUS = {
  success: 'success',
  fail: 'fail'
};

export const CT_STATUS = {
  success: 'Success',
  fail: 'Failure'
};

export const SERVICES = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
  CLEVERTAP: 'CLEVERTAP'
};

export const SOURCE_EVENT = {
  ANDROID: 'Android',
  IOS: 'iOS',
  DESKTOP_WEB: 'D-Web',
  MOBILE_WEB: 'M-Web',
  TABLE: 'Tablet'
};

export const PROMOTION_CODE_APPLIED = {
  yes: 'Yes',
  no: 'No'
};

export const PROMOTION_CODE_APPLIED_GA = {
  yes: 'yes',
  no: 'no'
};

export const CURRENCIES = {
  USD: 'usd',
  INR: 'inr'
};

export const PAYMENT_METHOD = {
  credit: 'credit_card',
  debit: 'debit_card',
  stripe: 'Stripe'
};

export const SUBSCRIPTIONS = {
  INDIA: {
    [PACKAGE_TYPES.PREMIUM_ANNUAL]: {
      ID: 4288,
      NAME: 'Annual India all access pass Stripe'
    },
    [PACKAGE_TYPES.PREMIUM_MONTHLY]: {
      ID: 4285,
      NAME: 'Monthly India all access pass Stripe'
    }
  },
  INTERNATIONAL: {
    [PACKAGE_TYPES.PREMIUM_ANNUAL]: {
      ID: 3795,
      NAME: 'Annual all access pass Stripe'
    },
    [PACKAGE_TYPES.PREMIUM_MONTHLY]: {
      ID: 4282,
      NAME: 'Monthly all access pass Stripe'
    }
  }
};

export const DATE_FORMAT = {
  SHORT: 'MM/DD/YYYY'
};

export const START_TYPE = {
  start: 'start',
  resume: 'resume',
  stop: 'stop'
};

export const PLAYBACK_TYPE = {
  initiate: 'user_initiated',
  nextInitiate: 'next_user_initiated',
  autoplay: 'autoplay'
};

export const CATEGORY_REGEX = {
  category: new RegExp(/[explore]\w+[/][0-9]+/),
  subcategory: new RegExp(/[explore]\w+[/][0-9]+\w+[/genre]\w+[/][0-9]+/)
};

export const MARKETING_LANDING_REGEX = {
  marketingLanding: new RegExp(/\/mlp\b/)
};

export const VALUE_PROP_REGEX = {
  marketingLandingValueProp: new RegExp(/\/mlpvp\b/)
};

export const CATEGORY_NAME = {
  homeScreenVideo: 'home_screen_video',
  books: 'books',
  universities: 'university',
  thoughtLeaders: 'thought_leaders'
};

export const CATEGORY_SCREEN_TYPE = {
  'Books Genre': 'learn from best-selling books',
  'University Insights Genre': 'learn from harvard business review',
  'Thought Leaders Genre': 'learn from faculty and experts',
  'Course Genre': 'courses'
};

export const ASSET_TYPE = {
  series: 'series',
  singleVideo: 'standalone'
};

export const RAIL_NUMBER = {
  [KalturaConstants.Categories.ContinueWatching]: 1,
  [KalturaConstants.Categories.RecommendedForYou]: 2,
  [KalturaConstants.Categories.TrendingNow]: 3
};

export const SCROLL = {
  top: 'top',
  bottom: 'bottom'
};

export const ET_PRIME = {
  name: 'ETPRIME',
  revenueEp: 99,
  module: {
    id: 4841,
    name: 'ET Prime - 1 Year Free Plan'
  }
};

export const subscriptionScreensException = [
  SCREENS.marketingLanding,
  SCREENS.marketingLandingValueProp
];
