import CleverTapService from '../services/CleverTapService';
import CTAnalyticEvent from './CTAnalyticEvent';

const EVENTS = {
  signup: 'Sign Up'
};

class CTSignUpEvent extends CTAnalyticEvent {
  static signUp({ channel, status, userId }) {
    const eventParams = {
      channel,
      Status: status
    };

    if (userId) {
      eventParams[CleverTapService.USER_ID_PARAM_KEY] = userId;
    }

    return new CTSignUpEvent(EVENTS.signup, eventParams);
  }
}

export default CTSignUpEvent;
