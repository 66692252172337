import React from 'react';
import { func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { useSession } from 'hooks';
import routes from 'constants/routesPaths';

const ActionMenu = ({ onLogClick, className = '' }) => {
  const { user } = useSession();

  const list = [
    {
      item: (
        <Link className="primary text-b2" to={routes.onboarding} onClick={onLogClick}>
          <FormattedMessage id="navigation.signInSignUp" />
        </Link>
      ),
      visible: !user
    },
    {
      item: (
        <Link className="primary text-b2" to={routes.explore}>
          <SearchIcon className="header__icon header__icon--search" data-testid="search-icon" />
        </Link>
      ),

      className: cn({ 'ml-5': !user, 'mx-6': user }),
      visible: true
    },
    {
      item: <NotificationsIcon className="header__icon" data-testid="notifications-icon" />,
      visible: false,
      className: 'mx-6'
    },
    {
      item: (
        <Link className="d-flex" to={routes.settings}>
          <SettingsIcon className="header__icon" data-testid="settings-icon" />
        </Link>
      ),
      visible: user
    }
  ];

  return (
    <ul className={className}>
      {list.map(
        (item, i) =>
          item.visible && (
            <li key={i} className={cn('d-flex', item.className)}>
              {item.item}
            </li>
          )
      )}
    </ul>
  );
};

ActionMenu.propTypes = {
  onLogClick: func.isRequired,
  className: string
};

export default ActionMenu;
