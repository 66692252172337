import { createSlice, nanoid } from '@reduxjs/toolkit';

import {
  signUpFulfilled,
  signInFulfilled,
  signInWithProviderFulfilled,
  fetchTokenFulfilled,
  logoutFulfilled,
  initSession,
  setKs,
  refreshSessionFulfilled,
  changeCountryFulfilled,
  logoutReset,
  fetchInitialDataFulfilled
} from 'state/actions/userActions';

import { getValidEntitlementsFulfilled } from 'state/actions/subscriptionActions';
import { CHANNELS, SOURCE_EVENT } from 'services/analytics/constants';

import { VIEW } from 'services/constants';
import { COUNTRIES } from 'constants/constants';
import { CURRENCIES, YEARLY_PRICES } from 'constants/subscriptions';

const initialState = {
  authenticated: null,
  user: null,
  info: null,
  ks: null,
  udid: null,
  country: null,
  expired: null,
  signupMethod: null,
  signupPlatform: null
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  extraReducers: {
    [fetchTokenFulfilled]: (state, { payload: { id, token, hashType } }) => {
      state.authenticated = {
        id,
        token,
        hashType
      };
    },
    [fetchInitialDataFulfilled]: (state, { payload: { ks, country } }) => {
      state.ks = ks;
      state.country = country;
    },
    [signInFulfilled]: (state, { payload: { ks, user, channel } }) => {
      state.ks = ks;
      state.user = user;
      state.signupMethod = channel;
    },
    [signInWithProviderFulfilled]: (state, { payload: { ks, user, channel, view } }) => {
      state.ks = ks;
      state.user = user;
      state.signupMethod = channel;
      state.signupPlatform =
        view === VIEW.desktop ? SOURCE_EVENT.DESKTOP_WEB : SOURCE_EVENT.MOBILE_WEB;
    },
    [initSession]: state => {
      state.udid = nanoid();
    },
    [setKs]: (state, { payload: { ks } }) => {
      state.ks = ks;
    },
    [logoutFulfilled]: (state, { payload: { expired } }) => ({
      ...initialState,
      udid: state.udid,
      country: state.country,
      expired
    }),
    [logoutReset]: state => {
      state.expired = null;
    },
    [signUpFulfilled]: (state, { payload: { ks, user, householdId, view } }) => {
      state.ks = ks;
      state.user = { ...user, householdId };
      state.signupMethod = CHANNELS.email;
      state.signupPlatform =
        view === VIEW.desktop ? SOURCE_EVENT.DESKTOP_WEB : SOURCE_EVENT.MOBILE_WEB;
    },
    [getValidEntitlementsFulfilled]: (state, { payload: { entitlements } }) => {
      state.user.purchaseId = entitlements?.[0]?.purchaseId;
    },
    [refreshSessionFulfilled]: (state, { payload: { ks } }) => {
      state.ks = ks;
    },
    // TODO: remove change country reducer, this is for qa purposes only
    [changeCountryFulfilled]: (state, { payload: { country } }) => {
      state.country = country;
    }
  }
});

export const selectCountryPrice = state => ({
  amount: state.session.country?.code === COUNTRIES.INDIA ? YEARLY_PRICES.INDIA : YEARLY_PRICES.USA,
  currencySign: state.session.country?.code === COUNTRIES.INDIA ? CURRENCIES.INDIA : CURRENCIES.USA
});

export default sessionSlice.reducer;
