import React from 'react';

import { Slide, ToastContainer } from 'react-toastify';

const Toast = () => (
  <ToastContainer
    className="toast"
    hideProgressBar
    position="bottom-center"
    transition={Slide}
    pauseOnFocusLoss={false}
  />
);

export default Toast;
