import React, { memo } from 'react';
import { string, func, oneOf, bool, node } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { BUTTON_STYLES } from 'constants/styles';

const Button = ({
  title,
  labelId,
  onClick = () => {},
  styleType = BUTTON_STYLES.PRIMARY,
  className,
  disabled = false,
  children,
  type,
  ...props
}) => {
  const intl = useIntl();

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={cn(`button button--${styleType}`, className, { disabled })}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children || <span>{title || intl.formatMessage({ id: labelId })}</span>}
    </button>
  );
};

Button.propTypes = {
  title: string,
  labelId: string,
  onClick: func,
  styleType: oneOf([
    BUTTON_STYLES.PRIMARY,
    BUTTON_STYLES.SECONDARY,
    BUTTON_STYLES.OUTLINE_PRIMARY,
    BUTTON_STYLES.LINK_PRIMARY,
    BUTTON_STYLES.LINK_SECONDARY,
    BUTTON_STYLES.ALERT_PRIMARY
  ]),
  type: oneOf(['button', 'reset', 'submit']),
  className: string,
  disabled: bool,
  children: node
};

export default memo(Button);
