import dayjs from 'dayjs';
import { PACKAGE_TYPES, PACKAGE_TIME_PERIOD } from 'constants/subscriptions';

export const formatMillisecondsToDate = (date, format) => dayjs(date * 1000).format(format);

export const formatDate = (date, format) => dayjs(date).format(format);

export const getOneYearFromNow = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + 1));

export const calcRenewDate = (date, type) =>
  dayjs(date).add(1, type === PACKAGE_TYPES.PREMIUM_ANNUAL ? 'year' : 'month');

export const diffDates = (date1, date2, period) =>
  dayjs(date1 * 1000).diff(dayjs(date2 * 1000), period, true);

export const getSubscriptionTimePeriod = (purchaseDate, endDate) => {
  const isMonthly = diffDates(endDate, purchaseDate, 'month') <= 1.04;
  const isSixMonthly = !isMonthly && diffDates(endDate, purchaseDate, 'month') <= 6.04;
  let timePeriod = PACKAGE_TIME_PERIOD.YEAR;

  if (isMonthly) timePeriod = PACKAGE_TIME_PERIOD.MONTH;
  if (isSixMonthly) timePeriod = PACKAGE_TIME_PERIOD.SIX_MONTHS;

  return timePeriod;
};
