import {
  SCREENS,
  SUBSCRIPTION_STATUS,
  USER_TYPE,
  PROMOTION_CODE_APPLIED_GA,
  DATE_FORMAT,
  NOT_AVAILABLE,
  PAYMENT_METHOD,
  subscriptionScreensException
} from 'services/analytics/constants';
import {
  getCurrency,
  getSubscriptions,
  getValue,
  getPreviousScreen,
  getUser,
  getContentId,
  getOrderId,
  getPackageType,
  getUserType,
  getCurrentScreen
} from 'services/analytics/utils';

import { PACKAGE_TYPES } from 'constants/subscriptions';

import { formatDate } from 'utils/dateHelpers';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  annualPlanSelect: 'annual_plan_select',
  applyPromoCode: 'apply_promocode',
  assetPopup: 'asset_popup',
  enterPromoCode: 'enter_promocode',
  gotAPromoCode: 'got_a_promocode',
  monthlyPlanSelect: 'monthly_plan_select',
  paidAssetSubscriptionClick: 'paid_asset_subscription_click',
  purchase: 'purchase',
  stayOn: 'stay_on',
  subscribeClicked: 'subscription_clicked',
  subscriptionStatus: 'subscription_status',
  subscriptionSuccessful: 'subscription_successful',
  cancelMembership: 'cancel_membership',
  cancelSubscription: 'cancel_subscription'
};

class GASubscriptionEvent extends AnalyticEvent {
  static annualPlanSelect() {
    return new GASubscriptionEvent(EVENTS.annualPlanSelect, {
      currency_ep: getCurrency(),
      module_id: getSubscriptions()[PACKAGE_TYPES.PREMIUM_ANNUAL].ID,
      module_name: getSubscriptions()[PACKAGE_TYPES.PREMIUM_ANNUAL].NAME,
      previous_screen: getPreviousScreen(),
      screen_name: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      user_type: USER_TYPE.loggedIn
    });
  }

  static applyPromoCode({
    price,
    promocode,
    isSuccess,
    type,
    moduleId,
    moduleName,
    subscriptionStatus
  }) {
    return new GASubscriptionEvent(EVENTS.applyPromoCode, {
      screen_name: SCREENS.payment,
      user_type: getUserType(),
      previous_screen: SCREENS.subscription,
      subscription_status: subscriptionStatus || SUBSCRIPTION_STATUS.paidUser,
      currency: getCurrency(),
      revenue_ep: price,
      module_name: moduleName || getSubscriptions()[type].NAME,
      module_id: moduleId || getSubscriptions()[type].ID,
      promocode_applied: isSuccess ? PROMOTION_CODE_APPLIED_GA.yes : PROMOTION_CODE_APPLIED_GA.no,
      promocode
    });
  }

  static assetPopup() {
    return new GASubscriptionEvent(
      EVENTS.assetPopup,
      {
        screen_name: getCurrentScreen(),
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: SUBSCRIPTION_STATUS.freeUser
      },
      true
    );
  }

  static enterPromoCode({ price, type }) {
    return new GASubscriptionEvent(EVENTS.enterPromoCode, {
      screen_name: SCREENS.payment,
      user_type: USER_TYPE.loggedIn,
      previous_screen: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      currency: getCurrency(),
      revenue_ep: price,
      module_name: getSubscriptions()[type].NAME,
      module_id: getSubscriptions()[type].ID
    });
  }

  static gotAPromoCode({ price, type }) {
    return new GASubscriptionEvent(EVENTS.gotAPromoCode, {
      screen_name: SCREENS.payment,
      user_type: USER_TYPE.loggedIn,
      previous_screen: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      currency: getCurrency(),
      revenue_ep: price,
      module_name: getSubscriptions()[type].NAME,
      module_id: getSubscriptions()[type].ID
    });
  }

  static monthlyPlanSelect() {
    return new GASubscriptionEvent(EVENTS.monthlyPlanSelect, {
      currency_ep: getCurrency(),
      module_id: getSubscriptions()[PACKAGE_TYPES.PREMIUM_MONTHLY].ID,
      module_name: getSubscriptions()[PACKAGE_TYPES.PREMIUM_MONTHLY].NAME,
      previous_screen: getPreviousScreen(),
      screen_name: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      user_type: USER_TYPE.loggedIn
    });
  }

  static paidAssetSubscriptionClick(params) {
    return new GASubscriptionEvent(
      EVENTS.paidAssetSubscriptionClick,
      {
        screen_name: SCREENS.upsellScreen,
        user_type: USER_TYPE.loggedIn,
        previous_screen: SCREENS.settings,
        subscription_status: SUBSCRIPTION_STATUS.paidUser,
        currency: params.currency.toLowerCase(),
        module_name: params.moduleName,
        module_id: params.moduleId,
        revenue_ep: params.revenue,
        promocode_applied: PROMOTION_CODE_APPLIED_GA.no,
        promocode: NOT_AVAILABLE
      },
      true
    );
  }

  static purchase({ type, price }) {
    const user = getUser();
    const params = {
      ecommerce: {
        purchase: {
          transaction_id: getValue(getOrderId()), // Order ID generated,
          value: price.amount,
          currency: getCurrency(),
          items: [
            {
              item_name: String(getValue(getContentId())), // send as string. Send the content ID though which the user might have came to subscription page within the same session
              item_id: String(getValue(user?.id)), // send as string
              item_brand: getValue(user?.purchaseId), // end as string
              item_price: getValue(price.amount), // Need to pass ""revenue_ep"" value in value.
              item_category: getSubscriptions()[type].ID, // Need to pass ""module_id"" value in value.
              item_category1: getSubscriptions()[type].NAME // Need to pass ""module_name"" value in value.
            }
          ]
        }
      }
    };

    return new GASubscriptionEvent(EVENTS.purchase, params);
  }

  static subscribeClicked({ startDate, endDate, revenue, packageType }) {
    return new GASubscriptionEvent(EVENTS.subscribeClicked, {
      currency_ep: getCurrency(),
      module_id: getSubscriptions()[packageType].ID,
      module_name: getSubscriptions()[packageType].NAME,
      previous_screen: getPreviousScreen(),
      revenue_ep: getValue(revenue),
      screen_name: SCREENS.subscription,
      subscription_end_date: getValue(endDate && formatDate(endDate, DATE_FORMAT.SHORT)),
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      subscription_start_date: getValue(startDate && formatDate(startDate, DATE_FORMAT.SHORT)),
      user_type: USER_TYPE.loggedIn
    });
  }

  static subscriptionStatus({
    startDate,
    endDate,
    packageType,
    promocode,
    revenue,
    status,
    subscriptionStatus
  }) {
    return new GASubscriptionEvent(EVENTS.subscriptionStatus, {
      currency_ep: getCurrency(),
      module_id: getSubscriptions()[packageType].ID,
      module_name: getSubscriptions()[packageType].NAME,
      promocode: getValue(promocode),
      promocode_applied: promocode ? PROMOTION_CODE_APPLIED_GA.yes : PROMOTION_CODE_APPLIED_GA.no,
      previous_screen: subscriptionScreensException.includes(getCurrentScreen())
        ? SCREENS.subscription
        : getPreviousScreen(),
      revenue_ep: getValue(revenue),
      screen_name: SCREENS.payment,
      status,
      subscription_end_date: getValue(endDate && formatDate(endDate, DATE_FORMAT.SHORT)),
      subscription_status: getValue(subscriptionStatus) || SUBSCRIPTION_STATUS.freeUser,
      subscription_start_date: getValue(startDate && formatDate(startDate, DATE_FORMAT.SHORT)),
      user_type: USER_TYPE.loggedIn
    });
  }

  static cancelMembership() {
    const packageType = getPackageType();
    return new GASubscriptionEvent(EVENTS.cancelMembership, {
      previous_screen: getPreviousScreen(),
      screen_name: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      user_type: USER_TYPE.loggedIn,
      module_id: getSubscriptions()[packageType].ID,
      module_name: getSubscriptions()[packageType].NAME
    });
  }

  static cancelSubscription() {
    const packageType = getPackageType();
    return new GASubscriptionEvent(EVENTS.cancelSubscription, {
      previous_screen: getPreviousScreen(),
      screen_name: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      user_type: USER_TYPE.loggedIn,
      module_id: getSubscriptions()[packageType].ID,
      module_name: getSubscriptions()[packageType].NAME
    });
  }

  static stayOn() {
    const packageType = getPackageType();
    return new GASubscriptionEvent(EVENTS.stayOn, {
      screen_name: SCREENS.subscription,
      user_type: USER_TYPE.loggedIn,
      previous_screen: SCREENS.subscription,
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      module_id: getSubscriptions()[packageType].ID,
      module_name: getSubscriptions()[packageType].NAME
    });
  }

  static subscriptionSuccessful({
    startDate,
    endDate,
    moduleId,
    moduleName,
    revenue,
    packageType,
    orderId
  }) {
    return new GASubscriptionEvent(EVENTS.subscriptionSuccessful, {
      screen_name: SCREENS.payment,
      user_type: USER_TYPE.loggedIn,
      previous_screen:
        getCurrentScreen() === SCREENS.marketingLanding
          ? SCREENS.subscription
          : getPreviousScreen(),
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      transaction_id: getValue(orderId || getOrderId()),
      currency: getCurrency(),
      revenue_ep: getValue(revenue),
      subscription_start_date: getValue(
        startDate instanceof Date ? formatDate(startDate, DATE_FORMAT.SHORT) : startDate
      ),
      subscription_end_date: getValue(
        endDate instanceof Date ? formatDate(endDate, DATE_FORMAT.SHORT) : endDate
      ),
      payment_method: PAYMENT_METHOD.stripe,
      module_name: getValue(packageType ? getSubscriptions()[packageType].NAME : moduleName),
      module_id: getValue(moduleId || getSubscriptions()[packageType].ID)
    });
  }
}

export default GASubscriptionEvent;
