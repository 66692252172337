import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getPaymentGateways,
  getPaymentMethods,
  getPaymentConfiguration
} from 'state/actions/paymentActions';

export default () => {
  const dispatch = useDispatch();
  const gatewayId = useSelector(state => state.payment.gatewayId);
  const configKey = useSelector(state => state.payment.configKey);

  useEffect(() => {
    !gatewayId && dispatch(getPaymentGateways());
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    if (gatewayId) dispatch(getPaymentConfiguration());
  }, [gatewayId, dispatch]);

  return { configKey };
};
