import { useCallback } from 'react';

export default (
  handleValueChange,
  handleFocus,
  handleBlur,
  values,
  errors,
  activeFields,
  touchedFields
) =>
  useCallback(
    fieldKey => ({
      checked: values[fieldKey] || false,
      errors: errors[fieldKey],
      active: String(activeFields[fieldKey] || false),
      touched: touchedFields[fieldKey] || false,
      onChange: ({ target: { checked } }, isInitialSetup) =>
        handleValueChange(fieldKey, checked, isInitialSetup),
      onFocus: () => handleFocus(fieldKey),
      onBlur: () => handleBlur(fieldKey)
    }),
    [handleFocus, handleBlur, handleValueChange, values, errors, activeFields, touchedFields]
  );
