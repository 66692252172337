import { createSlice } from '@reduxjs/toolkit';
import { searchVideoFulfilled, searchVideoPending } from 'state/actions/searchActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  term: '',
  results: null,
  totalCount: 0
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  extraReducers: {
    [searchVideoFulfilled]: (state, { meta, payload: { results, totalCount } }) => {
      state.term = meta.arg.term;
      state.results = results;
      state.totalCount = totalCount;
    },
    [searchVideoPending]: (state, { meta }) => {
      if (state.term !== meta.arg.term) {
        state.results = [];
      }
    },
    [logoutFulfilled]: () => initialState
  }
});

export default searchSlice.reducer;
