export const createExtraParameters = ({
  id,
  householdId,
  stripeToken,
  firstName,
  lastName,
  username,
  paymentMethod
}) => [
  { key: 'UserId', value: id },
  { key: 'HouseholdId', value: householdId },
  { key: 'PaymentMethodName', value: paymentMethod },
  { key: 'PaymentMethodExternalId', value: stripeToken },
  { key: 'CustomerName', value: `${firstName} ${lastName || ''}` },
  { key: 'CustomerMail', value: username }
];
