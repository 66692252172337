import React, { lazy } from 'react';
import { Redirect } from 'react-router';

import routesPaths, { redirections, LIVE_URL } from 'constants/routesPaths';
import { PAGE_LAYOUT } from 'constants/constants';

const HomePage = lazy(() => import('pages/HomePage'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicyPage'));
const TermsOfUse = lazy(() => import('pages/TermsOfUsePage'));
const FeedbackPage = lazy(() => import('pages/FeedbackPage'));
const SettingsPage = lazy(() => import('pages/SettingsPage'));
const SignInPage = lazy(() => import('pages/SignInPage'));
const SignUpPage = lazy(() => import('pages/SignUpPage'));
const ExplorePage = lazy(() => import('pages/ExplorePage'));
const OnboardingPage = lazy(() => import('pages/OnboardingPage'));
const DetailPage = lazy(() => import('pages/DetailPage'));
const CategoryPage = lazy(() => import('pages/CategoryPage'));
const GenrePage = lazy(() => import('pages/GenrePage'));
const PaymentPage = lazy(() => import('pages/PaymentPage'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'));
const SubscriptionPage = lazy(() => import('pages/SubscriptionPage'));
const MyInsightsPage = lazy(() => import('pages/MyInsightsPage'));
const ConsentPage = lazy(() => import('pages/ConsentPage'));
const ChangePasswordPage = lazy(() => import('pages/ChangePasswordPage'));
const MobileTabletLandingPage = lazy(() => import('pages/MobileTabletLandingPage'));

const MarketingLandingPage = lazy(() => import('pages/MarketingLandingPage'));
const MarketingLandingOnboarding = lazy(() => import('pages/MarketingLandingPage/OnboardingPage'));
const MarketingLandingSignInPage = lazy(() => import('pages/MarketingLandingPage/SignInPage'));
const MarketingLandingSignUpWithEmailPage = lazy(() =>
  import('pages/MarketingLandingPage/SignUpWithEmailPage')
);
const MarketingLandingForgotPasswordPage = lazy(() =>
  import('pages/MarketingLandingPage/ForgotPasswordPage')
);
const MarketingLandingPaymentPage = lazy(() => import('pages/MarketingLandingPage/PaymentPage'));

const MarketingLandingWSJPage = lazy(() => import('pages/MarketingLandingPageWSJ'));
const MarketingLandingWSJOnboarding = lazy(() =>
  import('pages/MarketingLandingPageWSJ/OnboardingPage')
);
const MarketingLandingWSJSignInPage = lazy(() =>
  import('pages/MarketingLandingPageWSJ/SignInPage')
);
const MarketingLandingWSJSignUpWithEmailPage = lazy(() =>
  import('pages/MarketingLandingPageWSJ/SignUpWithEmailPage')
);
const MarketingLandingWSJForgotPasswordPage = lazy(() =>
  import('pages/MarketingLandingPageWSJ/ForgotPasswordPage')
);
const MarketingLandingWSJPaymentPage = lazy(() =>
  import('pages/MarketingLandingPageWSJ/PaymentPage')
);

const MarketingLandingValuePropPage = lazy(() => import('pages/MarketingLandingValuePropPage'));
const MarketingLandingValuePropSignInPage = lazy(() =>
  import('pages/MarketingLandingValuePropPage/SignInPage')
);
const MarketingLandingValuePropSignUpWithEmailPage = lazy(() =>
  import('pages/MarketingLandingValuePropPage/SignUpWithEmailPage')
);
const MarketingLandingValuePropForgotPasswordPage = lazy(() =>
  import('pages/MarketingLandingValuePropPage/ForgotPasswordPage')
);
const MarketingLandingValuePropPlanDetailsPage = lazy(() =>
  import('pages/MarketingLandingValuePropPage/PlanDetailsPage')
);

const ExternalRedirect = lazy(() => import('components/routes/ExternalRedirect'));
const ETPrimePage = lazy(() => import('pages/ETPrimePage'));
const ETPrimeOnboardingPage = lazy(() => import('pages/ETPrimePage/OnboardingPage'));
const ETPrimeSignUpPage = lazy(() => import('pages/ETPrimePage/SignUpPage'));
const ETPrimeSignInPage = lazy(() => import('pages/ETPrimePage/SignInPage'));
const ETPrimeForgotPassword = lazy(() => import('pages/ETPrimePage/ForgotPassword'));

const routes = [
  {
    path: routesPaths.home,
    component: <HomePage />,
    exact: true,
    layout: PAGE_LAYOUT.main,
    layoutProps: { showMobileFooter: true, showDownload: true }
  },
  {
    path: routesPaths.onboarding,
    component: <OnboardingPage />,
    layout: PAGE_LAYOUT.auth
  },
  {
    path: routesPaths.privacyPolicy,
    component: <PrivacyPolicy />,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.termsOfUse,
    component: <TermsOfUse />,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.feedback,
    component: <FeedbackPage />,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.settings,
    component: <SettingsPage />,
    private: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.signIn,
    component: <SignInPage />,
    layout: PAGE_LAYOUT.auth
  },
  {
    path: routesPaths.signUp,
    component: <SignUpPage />,
    layout: PAGE_LAYOUT.auth
  },
  {
    path: routesPaths.explore,
    component: <ExplorePage />,
    exact: true,
    layout: PAGE_LAYOUT.main,
    layoutProps: { showMobileFooter: true }
  },
  {
    path: routesPaths.myInsights,
    component: <MyInsightsPage />,
    exact: true,
    private: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.detail,
    component: <DetailPage />,
    exact: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.exploreId,
    component: <CategoryPage />,
    exact: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.genreId,
    component: <GenrePage />,
    exact: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.payment,
    component: <PaymentPage />,
    exact: true,
    private: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.resetPassword,
    component: <ResetPasswordPage />,
    exact: true,
    layout: PAGE_LAYOUT.auth
  },
  {
    path: routesPaths.resetPasswordStg, // TODO: remove when url changes on production emails
    component: <ResetPasswordPage />,
    exact: true,
    layout: PAGE_LAYOUT.auth
  },
  {
    path: routesPaths.forgotPassword,
    component: <ForgotPasswordPage />,
    exact: true,
    layout: PAGE_LAYOUT.auth
  },
  {
    path: routesPaths.subscriptions,
    component: <SubscriptionPage />,
    private: true,
    exact: true,
    layout: PAGE_LAYOUT.main
  },
  // TODO: This home redirection is temporal, should be removed after emeritus page changes
  {
    path: redirections.home,
    // eslint-disable-next-line
    render: ({ location: { search = '' } = {} }) => <Redirect to={`${routesPaths.home}${search}`} />
  },
  {
    path: redirections.live,
    component: <ExternalRedirect to={LIVE_URL} />
  },
  {
    path: routesPaths.consent,
    component: <ConsentPage />,
    exact: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.downloadApp,
    component: <MobileTabletLandingPage />,
    exact: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.changePassword,
    component: <ChangePasswordPage />,
    exact: true,
    private: true,
    layout: PAGE_LAYOUT.main
  },
  {
    path: routesPaths.marketingLanding,
    component: <MarketingLandingPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingOnboarding,
    component: <MarketingLandingOnboarding />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingSignIn,
    component: <MarketingLandingSignInPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingSignUpWithEmail,
    component: <MarketingLandingSignUpWithEmailPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingForgotPassword,
    component: <MarketingLandingForgotPasswordPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingPayment,
    component: <MarketingLandingPaymentPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJ,
    component: <MarketingLandingWSJPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJ2,
    component: <MarketingLandingWSJPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJOnboarding,
    component: <MarketingLandingWSJOnboarding />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJSignIn,
    component: <MarketingLandingWSJSignInPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJSignUpWithEmail,
    component: <MarketingLandingWSJSignUpWithEmailPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJForgotPassword,
    component: <MarketingLandingWSJForgotPasswordPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingWSJPayment,
    component: <MarketingLandingWSJPaymentPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingValueProp,
    component: <MarketingLandingValuePropPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.marketingLandingValuePropSignIn,
    component: <MarketingLandingValuePropSignInPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding,
    layoutProps: {
      hideFooter: true
    }
  },
  {
    path: routesPaths.marketingLandingValuePropSignUpWithEmail,
    component: <MarketingLandingValuePropSignUpWithEmailPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding,
    layoutProps: {
      hideFooter: true
    }
  },
  {
    path: routesPaths.marketingLandingValuePropForgotPassword,
    component: <MarketingLandingValuePropForgotPasswordPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding,
    layoutProps: {
      hideFooter: true
    }
  },
  {
    path: routesPaths.marketingLandingValuePropPlanDetails,
    component: <MarketingLandingValuePropPlanDetailsPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.etPrime,
    component: <ETPrimePage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.etPrimeOnboarding,
    component: <ETPrimeOnboardingPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.etPrimeSignUp,
    component: <ETPrimeSignUpPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.etPrimeSignIn,
    component: <ETPrimeSignInPage />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    path: routesPaths.etPrimeForgotPassword,
    component: <ETPrimeForgotPassword />,
    exact: true,
    layout: PAGE_LAYOUT.marketingLanding
  },
  {
    component: <NotFoundPage />,
    layout: PAGE_LAYOUT.main
  }
];

export default routes;
