import { useSelector, shallowEqual } from 'react-redux';

const useVideos = () =>
  useSelector(
    ({ videos: { selected, episodes, serie, share } }) => ({
      selected,
      episodes,
      serie,
      share
    }),
    shallowEqual
  );

export default useVideos;
