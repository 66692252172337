import kalturaClient from 'httpClient/kaltura';
import { OBJECT_TYPE, CATEGORY_PAGE_SIZE } from './constants';

class InsightsService {
  static saveInsight({ assetId }) {
    return kalturaClient.post('/personalList/action/add', {
      personalList: {
        objectType: OBJECT_TYPE.personalList,
        ksql: `media_id='${assetId}'`,
        name: `MyInsights_${assetId}`,
        partnerListType: 1
      }
    });
  }

  static getInsightsIds({ pageIndex }) {
    return kalturaClient.post('/personalList/action/list', {
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageSize: CATEGORY_PAGE_SIZE,
        pageIndex
      }
    });
  }

  static deleteInsight({ insightId: personalListId }) {
    return kalturaClient.post('/personalList/action/delete', {
      personalListId
    });
  }
}

export default InsightsService;
