import routes from 'constants/routesPaths';
import { SCREENS } from './constants';

const ROUTES_MAP = {
  [routes.home]: SCREENS.home,
  [routes.explore]: SCREENS.browse,
  [routes.detail]: SCREENS.detail,
  [routes.exploreId]: SCREENS.category,
  [routes.genreId]: SCREENS.subcategory,
  [routes.onboarding]: SCREENS.onboarding,
  [routes.signUp]: SCREENS.signup,
  [routes.signIn]: SCREENS.signinWithEmail,
  [routes.forgotPassword]: SCREENS.forgotPassword,
  [routes.resetPassword]: SCREENS.resetPassword,
  [routes.resetPasswordStg]: SCREENS.resetPassword,
  [routes.home]: SCREENS.home,
  [routes.explore]: SCREENS.browse,
  [routes.downloadApp]: SCREENS.mobileLanding,
  [routes.settings]: SCREENS.settings,
  [routes.payment]: SCREENS.payment,
  [routes.myInsights]: SCREENS.myInsights,
  [routes.marketingLanding]: SCREENS.marketingLanding,
  [routes.etPrime]: SCREENS.etPrime,
  [routes.etPrimeOnboarding]: SCREENS.signup,
  [routes.etPrimeSignUp]: SCREENS.signupWithEmail,
  [routes.etPrimeSignIn]: SCREENS.signinWithEmail,
  [routes.etPrimeForgotPassword]: SCREENS.forgotPassword,
  [routes.marketingLandingValuePropPlanDetails]: SCREENS.plan_details
};

export default ROUTES_MAP;
