import createAsyncThunk from 'utils/createAsyncThunk';
import InsightsService from 'services/InsightsService';
import CategoryService from 'services/CategoryService';
import parseError from 'utils/parseError';
import { INSIGHT_ALREADY_SAVED } from 'constants/errors';
import { CATEGORY_PAGE_SIZE } from 'services/constants';

export const saveInsight = createAsyncThunk('insights/saveInsight', async assetId => {
  try {
    const data = await InsightsService.saveInsight({ assetId });

    return data;
  } catch (response) {
    if (response.error.code === INSIGHT_ALREADY_SAVED) {
      throw INSIGHT_ALREADY_SAVED;
    } else {
      throw parseError(response);
    }
  }
});

export const fetchInsights = createAsyncThunk('insights/fetchInsights', async (_, { getState }) => {
  const {
    insights: { data, totalCount }
  } = getState();

  try {
    if (totalCount === 0 || totalCount > data.length) {
      const {
        data: { objects: insightsData, totalCount }
      } = await InsightsService.getInsightsIds({
        pageIndex: data.length / CATEGORY_PAGE_SIZE + 1
      });

      if (!insightsData.length) return { insights: [], totalCount };

      const {
        data: { objects }
      } = await CategoryService.getAssets({
        items: insightsData.map(({ name }) => Number(name.split('_')[1]))
      });

      const insights = objects.map((insight, index) => ({
        ...insight,
        insightId: insightsData[index].id
      }));

      return {
        insights,
        totalCount
      };
    }
    return { insights: [], totalCount };
  } catch (response) {
    throw parseError(response);
  }
});

export const deleteInsight = createAsyncThunk('insights/deleteInsight', async insightId => {
  try {
    await InsightsService.deleteInsight({ insightId });
    return { insightId };
  } catch (response) {
    throw parseError(response);
  }
});

export const { fulfilled: fetchInsightsFulfilled } = fetchInsights;
export const { fulfilled: deleteInsightFulfilled } = deleteInsight;
export const { fulfilled: saveInsightFulfilled } = saveInsight;
