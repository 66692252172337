import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useEntitlements } from 'hooks';
import AnalyticManager from 'services/analytics/AnalyticManager';
import GACampaignEvent from 'services/analytics/events/GACampaignEvent';

const utmParams = {
  source: 'utm_source',
  medium: 'utm_medium',
  campaign: 'utm_campaign',
  term: 'utm_term',
  content: 'utm_content'
};

const KeyParameters = {
  ...utmParams,
  couponCode: 'cc',
  giftCard: 'gc'
};

const historyActionMethods = {
  push: 'PUSH'
};

export default () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();
  const { isUserPremium } = useEntitlements({
    getExpired: false
  });

  useEffect(() => {
    const source = query.get(KeyParameters.source);
    const medium = query.get(KeyParameters.medium);
    const campaign = query.get(KeyParameters.campaign);
    const term = query.get(KeyParameters.term);
    const content = query.get(KeyParameters.content);
    const coupon = query.get(KeyParameters.couponCode) || query.get(KeyParameters.giftCard);

    if (source || medium || campaign || term || content || coupon) {
      // This setTimeout is required due to is needed to get the viewport in App.js first before triggering the event
      setTimeout(() => {
        AnalyticManager.logEvent(
          GACampaignEvent.campaignDetail({ source, medium, campaign, term, content, coupon })
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      history.listen((nextLocation, method) => {
        if (method !== historyActionMethods.push && !isUserPremium) return;

        const params = Object.values(utmParams);
        const previousQuery = new URLSearchParams(search);
        const nextQuery = new URLSearchParams(nextLocation.search);
        let hasChange = false;

        params.forEach(param => {
          const prevParamValue = previousQuery.get(param);

          if (prevParamValue) {
            nextQuery.set(param, prevParamValue);
            hasChange = true;
          }
        });

        if (hasChange) {
          history.replace({ pathname: nextLocation.pathname, search: nextQuery.toString() });
        }
      }),
    [history, search, isUserPremium]
  );
};
