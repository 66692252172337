export const UI_THEMES = {
  dark: 'dark',
  light: 'light'
};

export const BUTTON_STYLES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE_PRIMARY: 'outline-primary',
  LINK_PRIMARY: 'link-primary',
  LINK_SECONDARY: 'link-secondary',
  ALERT_PRIMARY: 'alert-primary'
};

export const TAGS_STYLES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

export const CATEGORY_CAROUSEL_STYLES = {
  PRIMARY: 'primary',
  BROWSE: 'browse',
  SEARCH: 'search'
};

export const CHECKBOX_STYLES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const UPGRADE_SUBSCRIPTION_STYLES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const PREMIUM_FEATURES_STYLES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

export const METRICS_STYLES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const PAYMENT_DETAILS_CARD = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const STEP_PROGRESS_BACKGROUND_COLORS = {
  TERTIARY: 'tertiary',
  WHITE: 'white'
};

export const SEPARATOR_STYLES = {
  PRIMARY: 'primary',
  TERTIARY: 'tertiary'
};
