export default {
  'signIn.title': 'Sign in with Email',
  'signIn.forgotPassword': 'Forgot Password?',
  'signIn.emailInput': 'Enter email address',
  'signIn.passwordInput': 'Enter password',
  'signIn.signIn': 'Sign In',

  'signup.title': 'Sign up with Email',
  'signup.signin': 'Sign in',
  'signup.accountQuestion': 'Already have an account? ',

  'signup.form.firstName': 'Enter First Name',
  'signup.form.lastName': 'Enter Last Name',
  'signup.form.email': 'Enter email address',
  'signup.form.password': 'Enter password',
  'signup.form.terms': 'I accept Emeritus insights’s Terms of Use and Privacy Policy',
  'signup.form.next': 'Next',
  'signup.form.submit': 'Create an account',
  'signup.form.createPassword': 'Create password',
  'signup.form.passwordHint': 'Password must be at least 6 characters long',

  'notFound.title': 'Error 404',
  'notFound.body': 'We can’t seem to find the page you’re looking for.',
  'notFound.goHome': 'Go Home',

  'home.insights.tagInfo': 'Daily Learning',
  'home.categories.viewAll': 'View All {category}',

  'myInsights.title': 'My Insights',
  'myInsights.content': 'To save and access Insights anytime download the Emeritus app',

  'footer.termsOfService': 'Terms of Service',

  'footer.termsOfUse': 'Emeritus Insights Terms of use',
  'footer.privacyPolicy': 'Privacy Policy',

  // errors
  'email.presence': 'You must enter an email to continue',
  'email.invalid': 'Email address is invalid. Please check again',
  'password.presence': 'You must enter a password to continue',
  'password.wrongPassword': 'Wrong password. Please enter the correct password.',
  'password.notEqualToOld': 'Your password cannot be same as the old password',
  'password.notMatched': 'Passwords do not match. Try entering the same password.',
  'password.minLength': 'Your password must be minimum 6 characters',
  'firstName.presence': 'You must enter a first name to continue',
  'firstName.invalid': 'You must enter a valid first name to continue',
  'lastName.presence': 'You must enter a last name to continue',
  'lastName.invalid': 'You must enter a valid last name to continue',
  'terms.presence': 'You must accept the terms and conditions to continue',

  'navigation.links.explore': 'Explore',
  'navigation.links.myInsights': 'My Insights',
  'navigation.links.courses': 'Courses',
  'navigation.signInSignUp': 'SIGN UP / SIGN IN',

  'onboarding.title': 'Welcome to Emeritus!',
  'onboarding.subtitle': 'Learn. Explore. Unlock a whole world of microlearning!',
  'onboarding.titleV2': 'Sign up to continue',
  'onboarding.buttonGmail': 'Continue with Gmail',
  'onboarding.buttonFacebook': 'Continue with Facebook',
  'onboarding.buttonEmail': 'Sign up with Email',
  'onboarding.accountQuestion': 'Already have an account? ',
  'onboarding.signIn': 'Sign in',
  'onboarding.or': 'OR',
  'onboarding.buttonSkip': 'Skip for now and start learning',
  'onboarding.expired': 'Your session expired. Please log in.',
  'onboarding.continueWith': 'Continue with:',
  'onboarding.signUpQuestion': "Don't have an account? ",
  'onboarding.signUp': 'Sign Up',
  'onboarding.mobile.subtitle': 'Continue with:',

  'explore.insights.title': 'Explore Insights',
  'explore.insights.books': 'Learn From Best-Selling Books',
  'explore.insights.universities': 'Learn From Harvard Business Review',
  'explore.insights.leaders': 'Learn From Faculty And Experts',

  'explore.university.title': 'Explore Courses',
  'explore.university.courses': 'In-depth Learning From Top Universities',
  'explore.browseByThemes': 'Browse By Themes',
  'explore.searchPlaceholder': 'Search by title, category or course',
  'explore.viewAllThemes': 'View All Themes',

  'settings.title': 'Settings',
  'settings.tabs.account': 'My Account',
  'settings.tabs.feedback': 'Feedback & Help',
  'settings.tabs.privacy': 'Privacy & Policy',
  'settings.tabs.terms': 'Terms of Use',
  'settings.tabs.logOut': 'Log out',

  'settings.account.changePassword': 'Change password',
  'settings.account.email': 'Email address',
  'settings.account.firstName': 'First Name',
  'settings.account.lastName': 'Last Name',
  'settings.account.manageSubscription': 'Manage subscription',
  'settings.account.password': 'Password: ••••••••••••••••••',
  'settings.account.profile': 'Profile details',
  'settings.account.summary': 'Summary',
  'settings.account.congratulations': 'Congratulations!',
  'settings.account.nowPremium': 'You are now a Emeritus Premium member! Enjoy unlimited features!',
  'settings.account.startBrowsing': 'Start Browsing',
  'settings.account.continueBrowsing': 'Continue Browsing',

  'settings.manageSubscription.cancelPlan': 'Cancel Plan',
  'settings.manageSubscription.cancelRenewal': 'Cancel Subscription Renewal',

  'settings.cancelConfirmation.title': 'Cancel Subscription?',
  'settings.cancelConfirmation.areYouSure': 'Are you sure you want to cancel your subscription?',
  'settings.cancelConfirmation.expireDate':
    'You can access the premium content till the date of expiry.',
  'settings.cancelConfirmation.successMessage': 'Your Subscription was cancelled on {date}',

  'settings.subscribeActions.expireDate': 'Your premium plan will expire on {date}.',
  'settings.subscribeActions.renewFeatures':
    'Post this date, renew your subscription to continue enjoying Premium features.',
  'settings.subscriptionDetail.free': 'FREE',
  'settings.subscriptionDetail.plan': 'Your Plan',
  'settings.subscriptionDetail.cardDetails': 'Card Details',
  'settings.subscriptionDetail.premiumPlan': 'Premium Plan',
  'settings.subscriptionDetail.premium': 'EMERITUS PREMIUM',
  'settings.subscriptionDetail.viewDetails': 'View plan details',
  'settings.subscriptionDetail.hideDetails': 'Hide plan details',
  'settings.subscriptionDetail.features': 'Features you are enjoying',
  'settings.subscriptionDetail.unlimitedVideos': 'Get unlimited access to 1000+ videos',
  'settings.subscriptionDetail.thoughtLeaders':
    'Insights from best-selling books and global thought leaders.',
  'settings.subscriptionDetail.annualAccess':
    'Get annual access to premium content on the platform.',
  'settings.subscriptionDetail.planDetails': 'Your current plan details',
  'settings.subscriptionDetail.planExpired': 'Your premium plan expired on {date}.',
  'settings.subscriptionDetail.freeContent': 'You only have access to free content on Emeritus.',

  'settings.planDetail.expiringOn': 'EXPIRING ON',
  'settings.planDetail.activePlan': 'ACTIVE PLAN',
  'settings.planDetail.purchasedOn': 'PURCHASED ON',
  'settings.planDetail.amount': 'AMOUNT',
  'settings.planDetail.duration': 'DURATION',
  'settings.planDetail.oneYear': '1 year',
  'settings.planDetail.oneMonth': '1 month',
  'settings.planDetail.sixMonths': '6 months',

  'settings.terms.title': 'Emeritus Terms of Services',
  'settings.privacy.title': 'Privacy Statement',
  'settings.feedback.title': 'Need help?',
  'settings.feedback.content':
    'If you have any feedback for us or are facing any issues, please write to us at {email}',

  'manageSubscription.packageOptions.popular': 'Popular Choice',
  'manageSubscription.packageOptions.yearPlan': 'YEARLY PLAN',
  'manageSubscription.packageOptions.monthPlan': 'MONTHLY PLAN',
  'manageSubscription.packageOptions.perMonth': 'Per Month',
  'manageSubscription.packageOptions.perYear': 'Per Year',
  'manageSubscription.packageOptions.equivalentTo': 'Equivalent to {currencySign}{price} Per month',
  'manageSubscription.packageOptions.oneTime': 'One time payment',
  'manageSubscription.packageOptions.hasDiscount': 'Discount Applied',

  'upgradeSubscription.title': 'Unlock Emeritus Premium!',
  'upgradeSubscription.subtitle':
    'Unlimited access to video lessons from the world’s best to build skills for success.',
  'upgradeSubscription.disclaimer': 'Your subscription will auto-renew unless cancelled.',
  'upgradeSubscription.submit': 'Subscribe Now!',
  'upgradeSubscription.lessons': 'New Video Lessons',
  'upgradeSubscription.sizedVideos': 'Bite-sized video',
  'upgradeSubscription.sizedVideosAccess': 'Insights from best-selling books, now in minutes',
  'upgradeSubscription.dailyLessons': 'Exclusive video lessons from Harvard Business Review',
  'upgradeSubscription.leadingUniversities':
    'Learn from thought leaders, experts, top CEOs and more',
  'upgradeSubscription.agreeSubscribing': 'By subscribing you agree to our ',

  'changePasswordPage.confirmPassword': 'Confirm New Password',
  'changePasswordPage.currentPassword': 'Enter Old Password',
  'changePasswordPage.newPassword': 'Create New Password',

  'changePassword.confirmPassword': 'Confirm Password',
  'changePassword.currentPassword': 'Old Password',
  'password.disclaimer': 'Your password must be at least 6 characters minimum, alphanumeric.',
  'changePassword.newPassword': 'New Password',
  'changePassword.passwordChanged': 'Password changed successfully',
  'changePassword.title': 'Change Password',

  'category.info': `Get key takeaways from the world's most influential books ever written.`,
  'category.breadcrumb': 'Browse by Themes',

  'api.categories.HeroBanner': 'Hero Banner',
  'api.categories.ContinueWatching': 'Continue Watching',
  'api.categories.RecommendedForYou': 'Recommended For You',
  'api.categories.Genre': 'Genre',
  'api.categories.Editorial': 'Editorial',
  'api.categories.Show': 'Show',
  'api.categories.Trending Now': 'Free Insights For The Week',

  'api.insights.books': 'Insights from Best-Selling Books',
  'api.description.books': 'Upskill with the world’s most influential books in bite-sized lessons.',
  'api.insights.universities': 'Insights from Harvard Business Review',
  'api.description.universities':
    'Upskill with exclusive access to lessons from Harvard Business Review.',
  'api.insights.leaders': 'Insights from Faculty And Experts',
  'api.description.leaders':
    'Upskill with business leaders, subject matter experts and faculty from top universities.',

  'common.saveButton': 'Save',
  'common.shareButton': 'Share',
  'common.shareSuccessfully': 'Link copied successfully',
  'common.cancel': 'Cancel',
  'common.confirmRemove': 'Yes, remove',
  'common.saveChanges': 'Save Changes',
  'common.close': 'Close',
  'common.login': 'Login',
  'common.and': ' and ',
  'common.termsOfUse': 'Terms of Use',
  'common.privacyPolicy': 'Privacy Policy',
  'common.viewDetailsButton': 'View details',
  'common.exploreNow': 'Explore Now',
  'common.premium': 'Premium',
  'common.redirecting': 'Redirecting...',
  'common.downloadApp': 'Download App',

  'genre.back': 'Back',

  'insight.modalTitle': 'The Insight',
  'insight.modalFooter': 'To save and access this Insight anytime download the Emeritus app',
  'insight.saved': 'Insight successfully saved',
  'insight.deleteMessage': 'Are you sure you want to remove your insight from your saved insights?',
  'insight.deleted': 'Insight successfully deleted',
  'insight.alreadySaved': 'This insight is already saved in My Insights section.',
  'insight.mySavedInsights': 'My Saved Insights',
  'insight.mySavedInsights.episode': 'Episode',
  'insight.mySavedInsights.from': 'From',
  'insight.viewAllInsights': 'View All Saved Insights',
  'insight.emptySectionTitle': 'It looks Empty!',
  'insight.emptySectionMessage': 'Check out our new releases to find your next favourite.',

  'search.count': 'Found {count} results',
  'search.subtitle': 'Showing results for',
  'search.emptyTitle': 'No Videos found for this search item',
  'search.emptySubtitle': 'Please check your spellings or try different keywords',

  'detail.episodes': 'Episodes',
  'detail.episodes.nowPlaying': 'Now Playing',

  'payment.priceSummary': 'Price Summary',

  'payment.makePayment': 'Make Payment',
  'payment.amount': 'Amount',
  'payment.completePayment': 'Complete Payment Details',
  'payment.pay': 'Pay {currency}{price}',
  'payment.paymentVia': 'Payment Via Debit/Credit',
  'payment.planAnnual': 'Plan : Annual',
  'payment.planMonthly': 'Plan : Monthly',
  'payment.renewalDate': 'Auto Renewal Date',
  'payment.renewWarningAnnual': 'Your subscription will auto-renew every year unless cancelled.',
  'payment.renewWarningMonthly': 'Your subscription will auto-renew unless cancelled.',
  'payment.subscriptionDate': 'Subscription Date',
  'payment.successMessage': 'Subscription purchased successfully',

  'payment.promocode.submit': 'APPLY',
  'payment.promocode.clear': 'CLEAR',
  'payment.promocode.successShort': 'You have saved {currencySign}{price} on the bill',
  'payment.promocode.success':
    'Congratulations You’ve Saved {currencySign}{price} on your Premium Subscription.',
  'payment.promocode.amount': 'PAYABLE AMOUNT',
  'payment.promocode.hidePromo': '(Hide promo code?)',
  'payment.promocode.showPromo': '(Got a promo code?)',
  'payment.promocode.enterPromo': 'Enter promo code',
  'payment.promocode.notValid': 'Coupon Not Valid',
  'payment.redemptionCode.enterRedemptionCode': 'Enter Redemption code',
  'payment.redemptionCode.haveRedepmtionCode': 'Have a Redemption code?',
  'payment.redemptionCode.applyItHere': 'Apply it here',
  'payment.redemptionCode.month': 'Month',
  'payment.redemptionCode.freePlan': 'Subscription Plan @ Free',
  'payment.redemptionCode.validTill': 'Valid till {date}',
  'payment.redemptionCode.modalTitle': 'Add Redemption Code',
  'payment.redemptionCode.modalMessage': 'Please enter your redemption code below',
  'payment.redemptionCode.applyCode': 'Apply Code',
  'payment.redemptionCode.codeNotValid': 'Please enter a valid Gift Code',

  'payment.marketingLanding.title': 'Unlock Emeritus Premium',
  'payment.marketingLanding.feature1': 'Exclusive skill-based modules from Harvard Business Review',
  'payment.marketingLanding.feature2':
    '5000+ lessons from business best-sellers, micro courses from top faculty & experts',
  'payment.marketingLanding.feature3': '100+ interactive workshops on Insights LIVE',
  'payment.marketingLanding.feature4':
    'Coaching 1:1 with professional coaches to assess your skill gaps',
  'payment.marketingLanding.securePayment': 'This is a secure payment connection',
  'payment.marketingLanding.paymentFooter': 'In case of payment issues please email us {email}',
  'payment.marketingLanding.renewWarning': 'Your subscription will auto-renew unless cancelled',
  'payment.marketingLandingSuccess.subtitle': 'You are ready to start upskilling!',

  'privacyPolicy.title': 'Privacy Statement',

  'consent.title': 'Information Consent',

  'termsOfUse.title': 'Emeritus Terms of Services',

  'socialShare.title': 'Inspire Others. Share Now!',
  'socialShare.twitter': 'Twitter',
  'socialShare.facebook': 'Facebook',
  'socialShare.email': 'Email',
  'socialShare.linkedIn': 'LinkedIn',
  'socialShare.shareInsight':
    'I think this insight will be valuable for you. You can shape your success with more such lessons, only on Emeritus. "{insight}"',
  'socialShare.shareVideo':
    'I think this lesson will be insightful for you. You can shape your success with more such lessons, only on Emeritus.',

  'clipboardInput.copy': 'Copy Link',

  'mobileLanding.title': 'Daily Learning To Build Skills For Success',
  'mobileLanding.desc':
    'Upskill with unlimited access to 5000+ video lessons from the world’s best',
  'mobileLanding.startLearning': 'START LEARNING NOW',

  'mobileLanding.insight.books': 'Learn From Best-Selling Books',
  'mobileLanding.insight.booksDesc': 'Get insights from the world’s most influential books.',

  'mobileLanding.insight.universities': 'Learn From Harvard Business Review',
  'mobileLanding.insight.universitiesDesc':
    'Get insights from exclusive video lessons from Harvard Business Review.',

  'mobileLanding.insight.leaders': 'Learn From Faculty And Experts',
  'mobileLanding.insight.leadersDesc':
    'Get insights from top CEOs, industry experts, thought leaders and faculty.',

  'mobileLanding.vault': 'Explore our vault',

  'desktop.metrics.title': 'Invest in your learning journey',
  'desktop.metrics.lessons': 'New lessons added every week',
  'desktop.metrics.downloads': 'Million downloads',
  'desktop.metrics.rating': 'Rating on the app store',
  'desktop.metrics.skills': 'Skills to learn from',

  'mobileLanding.metrics.title': 'Learning from the world’s best has never been easier',
  'mobileLanding.metrics.desc':
    'Get access to exclusive video lessons on strategy, marketing, communication, management, business, and more, to help you grow in your career.',

  'mobileLanding.metrics.lessons': 'new lessons added every week',
  'mobileLanding.metrics.downloads': 'million downloads',
  'mobileLanding.metrics.rating': 'rating on the app store',
  'mobileLanding.metrics.skills': 'skills to learn from',
  'mobileLanding.metrics.explore': 'EXPLORE NOW',

  'mobileLanding.download.title': 'Enjoy our in-app experience',
  'mobileLanding.download.desc': 'It’s fast, tiny and installs easily, learn on the go with us!',

  'mobileLanding.prefooter.startLearningFree': 'Start learning for free',

  'mobileLanding.prefooter.resources': 'Resources',
  'mobileLanding.prefooter.about': 'About',

  'login.successfully': 'Logged in Successfully!',

  'resetPassword.title': 'Reset Password',
  'resetPassword.successTitle': 'Password Reset Successful!',
  'resetPassword.successSubtitle': 'Please login using new password.',
  'resetPassword.passwordInput': 'Create New Password',
  'resetPassword.passwordConfirmationInput': 'Confirm New Password',
  'resetPassword.close': 'Close',
  'resetPassword.submit': 'Save Changes',

  'forgotPassword.title': 'Forgot Password',
  'forgotPassword.successTitle': 'Email Sent!',
  'forgotPassword.subtitle':
    'We will send you an email with instructions on how to reset your password.',
  'forgotPassword.successSubtitle1':
    'An email with instructions on how to reset your password has been sent to ',
  'forgotPassword.successSubtitle2':
    ' Check your spam or junk folder if you don’t see the email in your inbox.',
  'forgotPassword.emailInput': 'Enter email address',
  'forgotPassword.submit': 'Email me',

  'noInternet.title': 'Uh-Oh!',
  'noInternet.info': 'Seems like you’re not connected to the network. Please try again.',
  'noInternet.cta': 'Try Again',

  'downloadPopUp.info': 'Download our app for a better Experience',
  'downloadPopUp.cta': 'DOWNLOAD',

  'heroBanner.welcome': 'Welcome!',

  'privateSidebar.logoutModalTitle': 'Are you sure you want to log out?',
  'logoutModal.cta': 'Logout',

  'feedbackSection.title': 'What Learners Like About Emeritus',
  'feedbackSection.feedback1.content':
    'The content is great, and I am glad there’s an app like Emeritus that is making it easy to learn Insights from so many books. I am glad I found you.',
  'feedbackSection.feedback2.content': 'Love the Netflix of learning.',
  'feedbackSection.feedback3.content':
    'Quality of videos are outstanding. UI is freaking awesome. The short mini videos consist of everything we need to know.',
  'feedbackSection.feedback4.content': 'A life savior for those who try to stay away from reading.',
  'feedbackSection.feedback5.content':
    'Highly recommended for its distilled bite sized videos. Awesome way to consume interesting insights.',
  'feedbackSection.feedback6.content':
    'Application was so useful as a platform for learning leadership skills. Where many do not know which authors to learn from, the notifications and suggestions helped make this much easier directing to relevant content.',

  'faqSection.title': 'Frequently Asked Questions',
  'faqSection.showAll': 'View All Frequently Asked Questions',
  'faqSection.faq1.title': '1. What are some of the benefits of Emeritus Premium subscription?',
  'faqSection.faq1.info':
    'As a premium subscriber, you will have access to 5000+ lessons from business best-sellers, micro courses from top faculty and experts and exclusive skill-based modules from Harvard Business Review. Additionally, you can attend 100+ interactive workshops on Insights LIVE and reap the benefits of Coaching 1:1 with professional coaches to assess your skill gaps.',
  'faqSection.faq2.title': '2. What offers and discounts can I avail?',
  'faqSection.faq2.info':
    'We keep you informed from time to time via emails whenever we are running different discounts and offers. Please keep an eye out for the same.',
  'faqSection.faq3.title': '3. My payment is failing. What do I do?',
  'faqSection.faq3.info':
    'If your payment failed, please try again and if still not successful please write to us at insightsupport@emeritus.org and our support team will help resolve your issue.',
  'faqSection.faq4.title': '4. Can I get free trial before subscribing to the premium plan?',
  'faqSection.faq4.info':
    'We currently do not offer a free trial. However, you can sample the lessons on our platform that we have to offer in the ‘Free Insights for the Week’ section, that is refreshed every week.',
  'faqSection.faq5.title': '5. What payment options do you offer?',
  'faqSection.faq5.info':
    'We currently offer In-app purchases on the Android & iOS applications and Debit/Credit card payments on the mobile & desktop sites. In case you need any assistance to purchase the subscription, please write to us at insightsupport@emeritus.org and our support team will help you with the same.',

  'payment.marketingLanding.planDetails': 'Plan Details',
  'payment.marketingLanding.stepPosition': 'Step {position} of {steps}',
  'payment.marketingLanding.subscribeToPremium': 'Subscribe to Premium',
  'payment.marketingLanding.loginSignup': 'Login-Signup',
  'payment.marketingLanding.makePayment': 'Make Payment',

  'payment.marketingLanding.upskillTitle': 'Upskill With Lessons from Best-Selling Books',
  'payment.marketingLanding.upskillFeature1':
    'Learn in-demand skills like communication, leadership, marketing, business, strategy, and others from business best-sellers',
  'payment.marketingLanding.upskillFeature2':
    'Get access to lessons from biographies of top CEOs and world leaders',
  'payment.marketingLanding.upskillFeature3':
    'Get key insights from world’s renowned books within minutes',

  'payment.valueProp.title': 'Now is the time to start upskilling to perform at a new level',

  'payment.marketingLanding.harvardTitle':
    'Get Exclusive Access to Lessons from Harvard Business Review',
  'payment.marketingLanding.harvardFeature1':
    'Now learn from the world’s leading management magazine in video lessons',
  'payment.marketingLanding.harvardFeature2':
    'Learn from HBR authors and Harvard Business School faculty',
  'payment.marketingLanding.harvardFeature3':
    'Level up with lessons on organizational learning, team management, customer centricity, time management, corporate culture, and other areas',

  'payment.marketingLanding.microCoursesTitle': 'Micro Courses by Top Faculty and Experts',
  'payment.marketingLanding.microCoursesFeature1':
    'Learn top skills like leadership, data science, communication, marketing, strategy, decision making and others',
  'payment.marketingLanding.microCoursesFeature2':
    'Dive deeper into business problems and learn how to solve them with subject matter experts',

  'payment.marketingLanding.interactiveWorkshopTitle': 'Learn through Live, Interactive Workshops',
  'payment.marketingLanding.interactiveWorkshopFeature1':
    'Get access to 100+ workshops on Insights LIVE',
  'payment.marketingLanding.interactiveWorkshopFeature2':
    'Upskill with subject matter experts, top faculty and industry leaders',
  'payment.marketingLanding.interactiveWorkshopFeature3':
    'Learn applicable tools and techniques to strengthen in-demand skills such as leadership, data science, decision-making, digital marketing and more',

  'payment.marketingLanding.skillGapAssessmentTitle': 'Skill Gap Assesment by Expert Coaches',
  'payment.marketingLanding.skillGapAssessmentFeature1':
    'Our value-added service ‘Coaching 1:1’ will help you understand your skills gaps ',
  'payment.marketingLanding.skillGapAssessmentFeature2':
    'Get your LinkedIn profile or CV reviewed by professional coaches',
  'payment.marketingLanding.skillGapAssessmentFeature3':
    'Get personalized, actionable insights on the next steps to achieve your career aspirations',

  'payment.marketingLanding.startLearning': 'Start daily learning',
  'payment.marketingLanding.toBuildSkills': 'To build skills for success',
  'payment.marketingLanding.footerImageAlt': 'Device with notification',

  'marketingLanding.etPrimeError.title': 'Uh-Oh!',
  'marketingLanding.etPrimeError.subtitle':
    "We're sorry you are facing issues while activating your Emeritus Premium subscription. Please contact ET Prime support for the same.",

  'marketingLanding.etPrimeBanner.title': 'Unlock your free 1-year Emeritus Premium Subscription',
  'marketingLanding.etPrimeBanner.subtitle':
    'Build skills for success from business best-sellers, subject matter experts and lessons from Harvard Business Review',
  'marketingLanding.etPrimeBanner.submit': 'Activate Now!',
  'marketingLanding.etPrimeBanner.featuresTitle': 'What you get when you subscribe',

  'marketingLanding.common.success.title': 'Congratulations!',
  'marketingLanding.common.success.subtitle': 'You are ready to start upskilling!',
  'marketingLanding.common.successPremiumFeatures.item1':
    'Did you know that your employer is looking for a leader who has soft skills for strong impact?',
  'marketingLanding.common.successPremiumFeatures.item2':
    'Look out for our Coaching 1:1 email to book an appointment with our experts and identify your skill gaps.',
  'marketingLanding.common.successPremiumFeatures.item3':
    'Don’t miss out on our weekly webinar workshops on Insights LIVE.',
  'marketingLanding.common.successPremiumFeatures.item4':
    'Make daily learning a habit with our mobile app.',
  'marketingLanding.common.success.continueBrowsing': 'Continue Browsing',
  'marketingLanding.common.success.downloadApp': 'Download the app',

  'marketingLandingWSJ.common.feature1':
    'Complimentary 12-month digital membership to The Wall Street Journal, giving you unlimited access to award-winning journalism across any device',
  'marketingLandingWSJ.common.feature2':
    'Unlimited access to 5000+ video lessons from business best-sellers, subject matter experts and Harvard Business Review',
  'marketingLandingWSJ.common.feature3':
    '100+ interactive workshops by industry leaders on Insights LIVE',
  'marketingLandingWSJ.common.feature4':
    'Coaching 1:1 with professional coaches to assess your skill gaps',

  'marketingLandingWSJ.banner.title1': 'Unlock Unlimited Access to',
  'marketingLandingWSJ.banner.title2': 'Emeritus Premium',
  'marketingLandingWSJ.banner.separator': 'Now Including',
  'marketingLandingWSJ.banner.subtitle1': 'Subscription',
  'marketingLandingWSJ.banner.subtitle2': 'Worth $350',

  'valueProp.signUp': 'Sign Up',
  'valueProp.getProductPriceFailed': 'Failed to get product price information',
  'valueProp.renewWarning': 'Your subscription will auto-renew unless cancelled',

  'limitedOfferHeader.header': 'Limited Period Offer',
  'limitedOfferHeader.mainText': "Here's {percentage} Off for You"
};
