import { createSlice } from '@reduxjs/toolkit';

import {
  logoutFulfilled,
  signInFulfilled,
  signInWithProviderFulfilled
} from 'state/actions/userActions';
import {
  getExpiredEntitlementsFulfilled,
  getValidEntitlementsFulfilled,
  getPackageDetailsFulfilled,
  cancelRenewalFulfilled,
  getValidEntitlementsReset
} from 'state/actions/subscriptionActions';

const initialState = {
  validEntitlements: null,
  expiredEntitlements: null,
  packageDetails: null
};

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  extraReducers: {
    [getValidEntitlementsFulfilled]: (state, { payload: { entitlements } }) => {
      state.validEntitlements = entitlements;
    },
    [signInFulfilled]: (state, { payload: { entitlements } }) => {
      state.validEntitlements = entitlements;
    },
    [signInWithProviderFulfilled]: (state, { payload: { entitlements } }) => {
      state.validEntitlements = entitlements;
    },
    [getExpiredEntitlementsFulfilled]: (state, { payload: { entitlements } }) => {
      state.expiredEntitlements = entitlements;
    },
    [getPackageDetailsFulfilled]: (state, { payload: { packageDetails } }) => {
      const newPackageDetails = packageDetails.reduce((acc, packageDetail) => {
        const { price, ...rest } = packageDetail.price;
        acc[packageDetail.type] = { ...packageDetail, price: { ...price, ...rest } };
        return acc;
      }, {});

      state.packageDetails = newPackageDetails;
    },
    [cancelRenewalFulfilled]: (state, { payload: { id } }) => {
      if (state.validEntitlements) {
        const index = state.validEntitlements.findIndex(({ productId }) => productId === id);
        if (index !== -1) state.validEntitlements[index].isRenewableForPurchase = false;
      }
    },
    [getValidEntitlementsReset]: state => {
      state.validEntitlements = null;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default subscriptionSlice.reducer;
