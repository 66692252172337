import CategoryService from 'services/CategoryService';
import { CATEGORY_PAGE_SIZE } from 'services/constants';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels',
  async ({ id }, { getState }) => {
    const {
      channels: { byId }
    } = getState();

    const currentData = byId[id]?.data;

    try {
      const { data } = await CategoryService.getChannel({
        id,
        page: (currentData?.length || 0) / CATEGORY_PAGE_SIZE + 1
      });

      return {
        id,
        channels: data.objects || [],
        totalCount: data.totalCount
      };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const { fulfilled: fetchChannelsFulfilled } = fetchChannels;
