import React from 'react';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';

import AnalyticManager from 'services/analytics/AnalyticManager';
import GAMobileLandingEvent from 'services/analytics/events/GAMobileLandingEvent';
import GASignInEvent from 'services/analytics/events/GASignInEvent';
import SecurityIcon from 'assets/icons/security.svg';
import NoteIcon from 'assets/icons/note.svg';

import useSession from 'hooks/useSession';
import routesPaths from 'constants/routesPaths';
import Sidebar from 'components/common/Sidebar';

import { SidebarItemTypes } from '../Item';

const messages = defineMessages({
  header: { id: 'navigation.signInSignUp' }
});

const items = [
  {
    type: SidebarItemTypes.linkIcon,
    labelId: 'common.termsOfUse',
    url: routesPaths.termsOfUse,
    icon: NoteIcon
  },
  {
    type: SidebarItemTypes.linkIcon,
    labelId: 'common.privacyPolicy',
    url: routesPaths.privacyPolicy,
    icon: SecurityIcon
  }
];

const PublicSidebar = ({ toggled, onToggle }) => {
  const { formatMessage } = useIntl();
  const { user } = useSession();

  const handleSignClick = () => {
    AnalyticManager.logEvent(GAMobileLandingEvent.signUp());
    AnalyticManager.logEvent(GASignInEvent.signupSigninInitiate({ user }));
  };

  return (
    <Sidebar
      header={
        <Link to={routesPaths.onboarding} onClick={handleSignClick} className="primary text-b3">
          {formatMessage(messages.header)}
        </Link>
      }
      items={items}
      toggled={toggled}
      onToggle={onToggle}
    />
  );
};

PublicSidebar.propTypes = {
  toggled: bool.isRequired,
  onToggle: func.isRequired
};

export default PublicSidebar;
