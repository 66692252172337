import { SCREENS, ASSET_TYPE, CATEGORY_SCREEN_TYPE } from 'services/analytics/constants';
import ROUTES_MAP from 'services/analytics/routes';
import {
  getPreviousScreen,
  getSubscriptionStatus,
  getUserType,
  getValue,
  getUdid,
  getUser,
  getCategoryName,
  getCurrentScreen
} from 'services/analytics/utils';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  allInsightsClick: 'All insights click',
  categoryScreen: 'category_screen',
  detailScreen: 'detail_screen',
  exploreInsightsFromBooks: 'explore_insights_from_books',
  exploreInsightsFromThoughtLeaders: 'explore_insights_from_thought_leaders',
  exploreInsightsFromUniversities: 'explore_insights_from_universities',
  exploreLearnFromCourses: 'explore_learn_from_courses',
  homeScreen: 'home_screen',
  mylistExploreNow: 'mylist_explore_now',
  navigationExplore: 'navigation_explore',
  selectItem: 'select_item',
  subcategoryArrowClick: 'subcategory_arrow_click',
  subcategoryScreen: 'subcategory_screen',
  subscriptionOpen: 'subscription_open',
  topBannerClick: 'top_banner_click',
  topBannerImpression: 'top_banner_impression',
  valuePropImpression: 'value_prop_impression',
  videoClick: 'video_click',
  viewItemList: 'view_item_list'
};

class GABrowseEvent extends AnalyticEvent {
  static allInsightsClick({ category }) {
    return new GABrowseEvent(
      EVENTS.allInsightsClick,
      {
        screen_name: SCREENS.home,
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: getSubscriptionStatus(),
        insight_category: getValue(category)
      },
      true
    );
  }

  static categoryScreen({ name }) {
    return new GABrowseEvent(EVENTS.categoryScreen, {
      screen_name: SCREENS.category,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      category_screen_type: getValue(CATEGORY_SCREEN_TYPE?.[name])
    });
  }

  static detailScreen({ type }) {
    return new GABrowseEvent(
      EVENTS.detailScreen,
      {
        screen_name: SCREENS.detail,
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: getSubscriptionStatus(),
        detail_page_type: type
      },
      true
    );
  }

  static exploreInsightsFromBooks() {
    return new GABrowseEvent(EVENTS.exploreInsightsFromBooks, {
      screen_name: SCREENS.browse,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus()
    });
  }

  static exploreInsightsFromThoughtLeaders() {
    return new GABrowseEvent(EVENTS.exploreInsightsFromThoughtLeaders, {
      screen_name: SCREENS.browse,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus()
    });
  }

  static exploreInsightsFromUniversities() {
    return new GABrowseEvent(
      EVENTS.exploreInsightsFromUniversities,
      {
        screen_name: SCREENS.browse,
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: getSubscriptionStatus()
      },
      true
    );
  }

  static exploreLearnFromCourses() {
    return new GABrowseEvent(EVENTS.exploreLearnFromCourses, {
      screen_name: SCREENS.browse,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus()
    });
  }

  static homeScreen() {
    return new GABrowseEvent(EVENTS.homeScreen, {
      screen_name: SCREENS.home,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus()
    });
  }

  static mylistExploreNow() {
    return new GABrowseEvent(
      EVENTS.mylistExploreNow,
      {
        screen_name: SCREENS.subcategory,
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: getSubscriptionStatus()
      },
      true
    );
  }

  static navigationExplore({ currentPath = '', category = '', subcategory = '' }) {
    const screenName = ROUTES_MAP[currentPath];

    return new GABrowseEvent(EVENTS.navigationExplore, {
      screen_name: screenName,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      insight_category: getValue(category),
      insight_subcategory: getValue(subcategory)
    });
  }

  static topBannerImpression({ positionEp = '', contentId, seriesId, videoId }) {
    return new GABrowseEvent(EVENTS.topBannerImpression, {
      screen_name: SCREENS.home,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      position_ep: getValue(positionEp),
      content_id: getValue(contentId),
      content_series_id: getValue(seriesId),
      content_video_id: getValue(videoId)
    });
  }

  static topBannerClick({ contentId, seriesId, videoId, contentName }) {
    return new GABrowseEvent(EVENTS.topBannerClick, {
      screen_name: SCREENS.home,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      content_id: getValue(contentId),
      content_series_id: getValue(seriesId),
      content_video_id: getValue(videoId),
      content_name: getValue(contentName)
    });
  }

  static valuePropImpression({ section }) {
    const user = getUser();

    return new GABrowseEvent(EVENTS.valuePropImpression, {
      screen_name: getCurrentScreen(),
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      section: getValue(section),
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId)
    });
  }

  static videoClick({ theme, category, subcategories }) {
    return new GABrowseEvent(
      EVENTS.videoClick,
      {
        screen_name: SCREENS.browse,
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: getSubscriptionStatus(),
        theme: getValue(theme),
        insight_category: getValue(category),
        insight_subcategory: getValue(subcategories)
      },
      true
    );
  }

  static selectItem({ video, media, seriesId, categoryName, categoryNumber, index }) {
    const user = getUser();
    return new GABrowseEvent(EVENTS.selectItem, {
      screen_name: getCurrentScreen(),
      anonymous_id: getUdid(),
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId),
      ecommerce: {
        items: [
          {
            item_name: String(getValue(media?.assetId)),
            item_id: getValue(video.externalId?.toLowerCase()),
            item_brand: getValue(seriesId?.toLowerCase()),
            item_variant: getValue(getCategoryName(categoryName)),
            item_category: seriesId ? ASSET_TYPE.series : ASSET_TYPE.singleVideo,
            item_list_id: getValue(categoryNumber),
            item_list_name: getValue(categoryName),
            index: String(index) // position of the asset which is clicked
          }
        ]
      }
    });
  }

  static viewItemList({ contentList, categoryName, categoryNumber }) {
    const user = getUser();
    return new GABrowseEvent(EVENTS.viewItemList, {
      screen_name: getCurrentScreen(),
      anonymous_id: getUdid(),
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId),

      ecommerce: {
        items: [
          ...contentList.map((slide, index) => ({
            item_id: getValue(String(slide.id)),
            item_name: getValue(slide.externalId?.toLowerCase()),
            item_brand: getValue(slide.metas?.SeriesID?.value?.toLowerCase()),
            item_variant: getValue(getCategoryName(categoryName)),
            item_category: slide.metas?.SeriesID?.value
              ? ASSET_TYPE.series
              : ASSET_TYPE.singleVideo,

            item_list_id: getValue(categoryNumber),
            item_list_name: getValue(categoryName),
            index: String(index + 1)
          }))
        ]
      }
    });
  }

  static subcategoryArrowClick({ insightCategory, insightSubcategory, positionEp }) {
    return new GABrowseEvent(EVENTS.subcategoryArrowClick, {
      screen_name: SCREENS.subcategory,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      insight_category: getValue(insightCategory),
      insight_subcategory: getValue(insightSubcategory),
      position_ep: getValue(positionEp)
    });
  }

  static subcategoryScreen({ categoryName, trayName }) {
    return new GABrowseEvent(EVENTS.subcategoryScreen, {
      screen_name: SCREENS.subcategory,
      user_type: getUserType(),
      previous_screen: getPreviousScreen(),
      subscription_status: getSubscriptionStatus(),
      category_screen_type: getValue(CATEGORY_SCREEN_TYPE?.[categoryName]),
      subcategory_screen_type: getValue(trayName)
    });
  }

  static subscriptionOpen() {
    return new GABrowseEvent(
      EVENTS.subscriptionOpen,
      {
        screen_name: SCREENS.subcategory,
        user_type: getUserType(),
        previous_screen: getPreviousScreen(),
        subscription_status: getSubscriptionStatus()
      },
      true
    );
  }
}

export default GABrowseEvent;
