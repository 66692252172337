import React from 'react';
import { bool, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';

import AvatarIcon from 'assets/icons/avatar.svg';
import AddBookmarkIcon from 'assets/icons/add-bookmark.svg';
import MessagesIcon from 'assets/icons/messages.svg';
import SecurityIcon from 'assets/icons/security.svg';
import NoteIcon from 'assets/icons/note.svg';
import ExitIcon from 'assets/icons/exit.svg';

import routesPaths from 'constants/routesPaths';
import useModal from 'hooks/useModal';
import Sidebar from 'components/common/Sidebar';
import LogoutModal from 'components/common/LogoutModal';
import { logout } from 'state/actions/userActions';

import { SidebarItemTypes } from '../Item';

const messages = defineMessages({
  logoutModalTitle: { id: 'privateSidebar.logoutModalTitle' }
});

const PrivateSidebar = ({ toggled, onToggle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { openModal, closeModal } = useModal();

  const handleLogout = () => {
    dispatch(logout());

    closeModal();
    onToggle();
    history.push(routesPaths.home);
  };

  const handleLogoutClick = () => {
    openModal({
      title: (
        <div className="text-weight-medium text-b2">{formatMessage(messages.logoutModalTitle)}</div>
      ),
      content: <LogoutModal closeModal={closeModal} onLogout={handleLogout} />,
      showClose: false
    });
  };

  const items = [
    {
      type: SidebarItemTypes.dropdown,
      labelId: 'settings.tabs.account',
      icon: AvatarIcon,
      items: [
        // @TODO: enable this route in the future
        // {
        //   labelId: 'settings.account.profile',
        //   url: '/'
        // },
        {
          labelId: 'settings.account.manageSubscription',
          url: routesPaths.subscriptions
        },
        {
          labelId: 'settings.account.changePassword',
          url: routesPaths.changePassword
        }
      ]
    },
    {
      type: SidebarItemTypes.linkIcon,
      labelId: 'navigation.links.myInsights',
      url: routesPaths.myInsights,
      icon: AddBookmarkIcon
    },
    {
      type: SidebarItemTypes.linkIcon,
      labelId: 'settings.tabs.feedback',
      url: routesPaths.feedback,
      icon: MessagesIcon
    },
    {
      type: SidebarItemTypes.linkIcon,
      labelId: 'common.privacyPolicy',
      url: routesPaths.privacyPolicy,
      icon: SecurityIcon
    },
    {
      type: SidebarItemTypes.linkIcon,
      labelId: 'common.termsOfUse',
      url: routesPaths.termsOfUse,
      icon: NoteIcon
    },
    {
      type: SidebarItemTypes.callToAction,
      labelId: 'settings.tabs.logOut',
      icon: ExitIcon,
      onClick: handleLogoutClick
    }
  ];

  return <Sidebar items={items} toggled={toggled} onToggle={onToggle} />;
};

PrivateSidebar.propTypes = {
  toggled: bool.isRequired,
  onToggle: func.isRequired
};

export default PrivateSidebar;
