const StoreRegistry = (() => {
  let _store = null;

  function getStore() {
    return _store;
  }

  function register(store) {
    _store = store;
  }

  function getState() {
    return _store.getState();
  }

  return {
    getStore,
    register,
    getState
  };
})();

export default StoreRegistry;
