import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useStatus } from 'hooks';
import { FULFILLED, REJECTED, PENDING } from 'constants/actionStatusConstants';

import { fetchCategories } from 'state/actions/categoriesActions';

export default () => {
  const { status } = useStatus(fetchCategories);
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.home);
  const browseCategories = useSelector(state => state.categories.browse);
  const heroBanner = useSelector(state => state.categories.heroBannerData);

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchCategories());
    }
  }, [categories, dispatch]);

  const getChannelById = useCallback(
    (categoryId, channelId) => {
      const category =
        categories.find(category => category.id === Number(categoryId)) ||
        browseCategories.find(category => category.id === Number(categoryId));

      if (!category) {
        return {
          id: channelId,
          name: categoryId,
          category: {
            name: categoryId,
            browseId: channelId
          }
        };
      }

      const channel = category.channels.find(channel => channel.id === Number(channelId));

      return {
        ...channel,
        category: {
          name: category.name,
          browseId: category.browseId || category.id
        }
      };
    },
    [categories, browseCategories]
  );

  return {
    categories,
    browseCategories,
    heroBanner,
    getChannelById,
    error: status === REJECTED,
    success: status === FULFILLED,
    loading: status === PENDING
  };
};
