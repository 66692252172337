import React from 'react';
import { bool, node, arrayOf, shape, string, func } from 'prop-types';
import cn from 'classnames';

import CrossIcon from 'assets/icons/cross.svg';
import { sidebarDropdownItemShape } from 'constants/shapes';

import Item from './Item';

const Sidebar = ({ items, toggled, onToggle, header }) => {
  const containerClassName = cn('sidebar', {
    'sidebar--open': toggled
  });

  return (
    <aside className={containerClassName}>
      <header className="sidebar__header px-4">
        <div>{header}</div>
        <i onClick={onToggle}>
          <img src={CrossIcon} alt="Cross icon" className="sidebar__header-icon" />
        </i>
      </header>
      <div className="sidebar__content">
        {items.map((item, i) => (
          <Item key={i} item={item} onToggle={onToggle} />
        ))}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  toggled: bool.isRequired,
  onToggle: func.isRequired,
  items: arrayOf(
    shape({
      type: string.isRequired,
      labelId: string.isRequired,
      icon: node.isRequired,
      url: string,
      items: arrayOf(sidebarDropdownItemShape)
    })
  ).isRequired,
  header: node
};

export default Sidebar;
