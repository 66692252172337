import kalturaClient from 'httpClient/kaltura';
import crypto from 'crypto';

import { OBJECT_TYPE, DEVICE_HOUSEHOLD_BRAND_ID, DEVICE_NAME, EXTERNAL } from './constants';

const SESSION_DURATION = '86400';

class UserService {
  static signIn({ email: username, password, udid, isSocial = false }) {
    // email input is called username in the BackEnd
    const body = {
      username,
      password,
      udid
    };

    if (isSocial) {
      body.extraParams = {
        auth: {
          objectType: OBJECT_TYPE.stringValue,
          value: EXTERNAL
        }
      };
    }

    return kalturaClient.post('/ottuser/action/login', body);
  }

  static fetchToken({ ks }) {
    return kalturaClient.post('/appToken/action/add', {
      ks,
      appToken: {
        sessionDuration: SESSION_DURATION
      }
    });
  }

  static revokeSession() {
    return kalturaClient.post('/session/action/revoke');
  }

  static startSession({ ks, id, token, hashType }) {
    const tokenHash = crypto
      .createHash(hashType)
      .update(ks + token)
      .digest('hex');

    return kalturaClient.post('/appToken/action/startSession', {
      ks,
      id,
      tokenHash
    });
  }

  static anonymousLogin() {
    return kalturaClient.get('/ottUser/action/anonymousLogin');
  }

  static logout() {
    return kalturaClient.post('/ottUser/action/logout');
  }

  static signUp({ email, password, ...user }) {
    const body = {
      password,
      user: {
        ...user,
        objectType: OBJECT_TYPE.user,
        username: email,
        email
      }
    };
    return kalturaClient.post('/ottuser/action/register', body);
  }

  static addHousehold({ email, ks }) {
    const body = {
      ks,
      household: {
        name: email,
        objectType: OBJECT_TYPE.houseHold,
        description: ' '
      }
    };
    return kalturaClient.post('/household/action/add', body);
  }

  static addDeviceHousehold({ userId, ks, udid }) {
    const body = {
      ks,
      user_id: userId,
      device: {
        objectType: OBJECT_TYPE.houseHoldDevice,
        name: DEVICE_NAME,
        brandId: DEVICE_HOUSEHOLD_BRAND_ID,
        udid
      }
    };

    return kalturaClient.post('/householddevice/action/add', body);
  }

  static removeDeviceHousehold({ udid, ks }) {
    const body = {
      ks,
      udid
    };

    return kalturaClient.post('/householddevice/action/delete', body);
  }

  static listDeviceHousehold({ ks }) {
    return kalturaClient.post('/householddevice/action/list', { ks });
  }

  static changePassword({ newPassword, currentPassword: oldPassword, username }) {
    return kalturaClient.post('/ottuser/action/updateLoginData', {
      username,
      newPassword,
      oldPassword
    });
  }

  static resetPassword({ password, token }) {
    return kalturaClient.post('/ottuser/action/setInitialPassword', {
      password,
      token
    });
  }

  static forgotPassword({ email }) {
    return kalturaClient.post('/ottuser/action/resetPassword', {
      username: email
    });
  }

  static getCountry({ ks }) {
    return kalturaClient.post('/country/action/list', {
      ks,
      filter: {
        objectType: OBJECT_TYPE.countryFilter,
        ipEqualCurrent: true
      }
    });
  }
}

export default UserService;
