import firebase from 'config/firebase';
import { PROVIDERS } from 'constants/constants';

class FirebaseService {
  static getProvider(providerName) {
    switch (providerName) {
      case PROVIDERS.facebook:
        return new firebase.auth.FacebookAuthProvider();
      case PROVIDERS.google:
      default:
        return new firebase.auth.GoogleAuthProvider();
    }
  }

  static signIn({ providerName }) {
    const provider = this.getProvider(providerName);
    return firebase.auth().signInWithPopup(provider);
  }

  static getIdToken() {
    return firebase.auth().currentUser.getIdToken(true);
  }
}

export default FirebaseService;
