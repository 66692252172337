import { CT_STATUS } from 'services/analytics/constants';
import CleverTapService from '../services/CleverTapService';
import CTAnalyticEvent from './CTAnalyticEvent';

const EVENTS = {
  signin: 'sign in',
  skipForNow: 'skip_for_now'
};

class CTSignInEvent extends CTAnalyticEvent {
  static signIn({ channel, status, userId }) {
    const eventParams = {
      channel,
      Status: status,
      'sign in status': status === CT_STATUS.success
    };

    if (userId) {
      eventParams[CleverTapService.USER_ID_PARAM_KEY] = userId;
    }

    return new CTSignInEvent(EVENTS.signin, eventParams);
  }

  static skipForNow() {
    return new CTSignInEvent(EVENTS.skipForNow);
  }
}

export default CTSignInEvent;
