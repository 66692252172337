import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPackageDetails } from 'state/actions/subscriptionActions';

import { PENDING, FULFILLED } from 'constants/actionStatusConstants';

import useStatus from './useStatus';
import useSubscription from './useSubscription';

export default () => {
  const [isLoadingPackageDetails, setIsLoadingPackageDetails] = useState(true);
  const dispatch = useDispatch();

  const { packageDetails } = useSubscription();

  const { status: packageDetailsStatus } = useStatus(getPackageDetails);

  const getPackageDetailsRequest = useCallback(() => dispatch(getPackageDetails()), [dispatch]);

  useEffect(() => {
    if (!packageDetails) {
      if (packageDetailsStatus !== PENDING) getPackageDetailsRequest();
    } else {
      setIsLoadingPackageDetails(false);
    }
  }, [getPackageDetailsRequest, packageDetails, packageDetailsStatus]);

  useEffect(() => {
    if (packageDetailsStatus === FULFILLED) setIsLoadingPackageDetails(false);
  }, [setIsLoadingPackageDetails, packageDetailsStatus]);

  return {
    isLoadingPackageDetails,
    isSuccessPackageDetails: packageDetailsStatus === FULFILLED,
    packageDetails
  };
};
