import { createAction } from '@reduxjs/toolkit';

export const setUAInfo = createAction('info/setUAInfo', ({ browser, cpu, device, engine, os }) => ({
  payload: {
    browser,
    cpu,
    device,
    engine,
    os
  }
}));

export const setDeviceView = createAction('info/setDeviceView', ({ view }) => ({
  payload: {
    view
  }
}));
