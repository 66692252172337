import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { generatePath } from 'react-router-dom';

import { CURRENCIES } from 'constants/subscriptions';
import routesPaths, { stores } from 'constants/routesPaths';
import kalturaConfig from 'config/kaltura';

const { android, ios } = stores;

export const parseInputErrors = error => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const getResizeImage = ({ url, height, width }) => {
  let resizeUrl = url;

  if (height && width) {
    resizeUrl = `${url}/width/${width}/height/${height}`;
  } else if (height) {
    resizeUrl = `${url}/height/${height}`;
  } else if (width) {
    resizeUrl = `${url}/width/${width}`;
  }

  return resizeUrl;
};

export const buildQueryString = params =>
  Object.keys(params)
    .filter(param => params[param])
    .map(param => `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`)
    .join('&');

export const buildQueryUrl = (url, query) => `${url}?${buildQueryString(query)}`;

export const getStoreLink = () => (window?.navigator.userAgent.match(/Android/i) ? android : ios);

export const setExternalUrl = url => window?.location.assign(url);

export const buildPath = (route, entities) => {
  try {
    return generatePath(route, entities);
  } catch (error) {
    return routesPaths.home;
  }
};

export const annualToMonthlyPrice = fullPrice => Number((fullPrice / 12).toFixed(2));

export const parseCurrencySign = currencySign =>
  !currencySign || currencySign === CURRENCIES.EMPTY ? CURRENCIES.INDIA : currencySign;

export const getCurrency = (currency, currencySign) =>
  currencySign === CURRENCIES.EMPTY ? currency : currencySign;

export const floatSubstraction = (numA, numB) =>
  (Math.round(numA * 100) - Math.round(numB * 100)) / 100;

export const getUrlImageDefault = images => images?.[0]?.url || '';

export const getUrlImageForTypeId = (images, typeId) =>
  images?.find(image => image?.imageTypeId === typeId)?.url || getUrlImageDefault(images);

export const getBannerImageUrl = images =>
  getUrlImageForTypeId(images, kalturaConfig.productsFlavors.bannerTypeId);

export const getPreviewImageUrl = images =>
  getUrlImageForTypeId(images, kalturaConfig.productsFlavors.thumbnailTypeId);

export const getURLImage = (images, isBanner = false) =>
  isBanner ? getBannerImageUrl(images) : getPreviewImageUrl(images);
