import { useSelector, shallowEqual } from 'react-redux';

const useSession = () =>
  useSelector(
    ({ session: { authenticated, user, ks, udid, country, expired } }) => ({
      authenticated,
      user,
      ks,
      udid,
      country,
      expired
    }),
    shallowEqual
  );

export default useSession;
