import React from 'react';
import { bool, node, string } from 'prop-types';
import cn from 'classnames';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

import { useModal, useMediaQueries } from 'hooks';

const MarketingLandingLayout = ({ children, className = '', hideFooter }) => {
  const { isDesktop } = useMediaQueries();
  const { visible: isModalOpen } = useModal();

  return (
    <div
      className={cn('main-layout', {
        'main-layout--no-scroll': isModalOpen
      })}
    >
      <Header hideNav logoUnclickable />
      <div
        className={cn('main-layout__content', {
          'main-layout__content--footer': !isDesktop
        })}
      >
        <main className={className}>{children}</main>
      </div>
      {isDesktop && !hideFooter && <Footer logoUnclickable />}
    </div>
  );
};

MarketingLandingLayout.propTypes = {
  children: node.isRequired,
  className: string,
  hideFooter: bool
};

export default MarketingLandingLayout;
