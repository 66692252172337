import { SERVICES } from 'services/analytics/constants';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  addToWishList: 'AddToWishlist',
  purchase: 'Purchase',
  subscribe: 'Subscribe'
};

class FBSubscriptionEvent extends AnalyticEvent {
  constructor(name, params, setContent = false, services = [SERVICES.FACEBOOK]) {
    super(name, params, setContent, services);
  }

  static addToWishList() {
    return new FBSubscriptionEvent(EVENTS.addToWishList, {});
  }

  static purchase({ amount, currency }) {
    return new FBSubscriptionEvent(EVENTS.purchase, { value: amount, currency });
  }

  static subscribe({ amount, currency }) {
    return new FBSubscriptionEvent(EVENTS.subscribe, { value: amount, currency });
  }
}

export default FBSubscriptionEvent;
