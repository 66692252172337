import {
  SCREENS,
  USER_TYPE,
  SUBSCRIPTION_STATUS,
  NOT_AVAILABLE,
  STATUS,
  PLATFORM,
  CHANNELS
} from 'services/analytics/constants';
import { getCurrency, getPreviousScreen, getUdid, getValue } from 'services/analytics/utils';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  nextButton: 'next_button',
  signup: 'sign_up',
  signupStatus: 'sign_up_status',
  signupSuccess: 'sign_up_success',
  signUpPop: 'sign_up_popup',
  signUpPopupClose: 'sign_up_popup_close',
  createAccount: 'create_account'
};

class GASignUpEvent extends AnalyticEvent {
  static nextButton() {
    return new GASignUpEvent(EVENTS.nextButton, {
      screen_name: SCREENS.signupWithEmail,
      user_type: USER_TYPE.guest,
      platform: PLATFORM.mobile,
      previous_screen: SCREENS.onboarding,
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      currency: getCurrency(),
      channel: CHANNELS.email,
      registration_id: NOT_AVAILABLE,
      subscription_id: NOT_AVAILABLE
    });
  }

  static signUp({ channel }) {
    return new GASignUpEvent(
      EVENTS.signup,
      {
        screen_name: SCREENS.signup,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        channel,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      true
    );
  }

  static signUpSuccess({ user, channel }) {
    return new GASignUpEvent(
      EVENTS.signupSuccess,
      {
        screen_name: SCREENS.signup,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: user.id,
        subscription_id: NOT_AVAILABLE,
        channel
      },
      false
    );
  }

  static signUpStatusSuccess({ user, channel }) {
    return new GASignUpEvent(
      EVENTS.signupStatus,
      {
        screen_name: SCREENS.signup,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: user.id,
        subscription_id: NOT_AVAILABLE,
        sign_up_status: STATUS.success,
        channel
      },
      true
    );
  }

  static signUpStatusFail({ channel }) {
    return new GASignUpEvent(
      EVENTS.signupStatus,
      {
        screen_name: SCREENS.signup,
        user_type: USER_TYPE.guest,
        sign_up_status: STATUS.fail,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE,
        channel
      },
      true
    );
  }

  static createAccount() {
    return new GASignUpEvent(
      EVENTS.createAccount,
      {
        screen_name: SCREENS.signup,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      true
    );
  }

  static signUpPop({ user, entitlements }) {
    return new GASignUpEvent(
      EVENTS.signUpPop,
      {
        screen_name: SCREENS.detail,
        previous_screen: getPreviousScreen(),
        subscription_status: entitlements?.length
          ? SUBSCRIPTION_STATUS.paidUser
          : SUBSCRIPTION_STATUS.freeUser,
        registration_id: getValue(user?.id),
        subscription_id: NOT_AVAILABLE,
        anonymous_id: getUdid(),
        user_type: USER_TYPE.guest
      },
      true
    );
  }

  static signUpPopupClose({ user, entitlements }) {
    return new GASignUpEvent(
      EVENTS.signUpPopupClose,
      {
        screen_name: SCREENS.detail,
        previous_screen: getPreviousScreen(),
        subscription_status: entitlements?.length
          ? SUBSCRIPTION_STATUS.paidUser
          : SUBSCRIPTION_STATUS.freeUser,
        registration_id: getValue(user?.id),
        subscription_id: NOT_AVAILABLE,
        anonymous_id: getUdid(),
        user_type: USER_TYPE.guest
      },
      true
    );
  }
}

export default GASignUpEvent;
