import { useSelector, shallowEqual } from 'react-redux';

export default () =>
  useSelector(
    ({ subscriptions: { validEntitlements, expiredEntitlements, packageDetails } }) => ({
      validEntitlements,
      expiredEntitlements,
      packageDetails,
      isUserPremium: !!validEntitlements?.length
    }),
    shallowEqual
  );
