import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import CategoryService from 'services/CategoryService';
import { CATEGORY_PAGE_SIZE } from 'services/constants';
import parseError from 'utils/parseError';

export const setRelatedVideo = createAction(
  'recommendedForYou/setRelatedVideo',
  ({ relatedVideoId, typeIds }) => ({
    payload: {
      relatedVideoId,
      typeIds
    }
  })
);

export const fetchRecommendedForYou = createAsyncThunk(
  'recommendedForYou/fetchRecommendedForYou',
  async (_, { getState }) => {
    const {
      recommendedForYou: { data, totalCount }
    } = getState();

    if (totalCount === 0 || totalCount > data.length) {
      try {
        const { relatedVideoId, typeIds } = getState().recommendedForYou;
        const {
          data: { objects: videos }
        } = await CategoryService.getRecommendedForYou({
          relatedVideoId,
          typeIds,
          page: data.length / CATEGORY_PAGE_SIZE + 1
        });

        return {
          videos,
          totalCount
        };
      } catch (response) {
        throw parseError(response);
      }
    }
  }
);

export const { fulfilled: fetchRecommendedForYouFulfilled } = fetchRecommendedForYou;
