import axios from 'axios';

import kalturaConfig from 'config/kaltura';
import { APPLICATION_JSON, CONTENT_TYPE } from 'httpClient/constants';

const client = axios.create({
  baseURL: kalturaConfig.baseUrl,
  headers: {
    Accept: APPLICATION_JSON,
    [CONTENT_TYPE]: APPLICATION_JSON
  },
  transformResponse: axios.defaults.transformResponse.concat(data => data.result || data),
  params: {
    apiVersion: kalturaConfig.apiVersion,
    partnerId: kalturaConfig.partnerId
  }
});

export default client;
