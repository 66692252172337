import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useStatus, useSession } from 'hooks';
import { fetchInitialData } from 'state/actions/userActions';

export default () => {
  const { error, loading } = useStatus(fetchInitialData);
  const dispatch = useDispatch();
  const { ks } = useSession();

  useEffect(() => {
    if (!ks) {
      dispatch(fetchInitialData());
    }
  }, [ks, dispatch]);

  return {
    error,
    loading
  };
};
