const KalturaConstants = {
  CategoryTreeName: 'RSCategories',
  Categories: {
    Root: 'RS Root',
    HomeRails: 'RS HomeRails',
    BrowseRails: 'RS BrowseRails',
    HeroBanner: 'Hero Banner',
    ContinueWatching: 'Continue Watching',
    RecommendedForYou: 'Recommended For You',
    TrendingNow: 'Trending Now',
    Books: 'Books Genre',
    Universities: 'University Insights Genre',
    ThoughtLeaders: 'Thought Leaders Genre',
    CourseGenre: 'Course Genre'
  },
  MediaTypesName: 'MediaTypes',
  MediaTypes: {
    Episode: 'Episode',
    Movie: 'Movie',
    Season: 'Season',
    Series: 'Series',
    Trailer: 'Trailer',
    VirtualCategory: 'VirtualCategory',
    VirtualCollection: 'VirtualCollection'
  },
  Tags: {
    Category: {
      Courses: 'Courses'
    }
  }
};

export default KalturaConstants;
