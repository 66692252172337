import { logout } from 'state/actions/userActions';
import noInternetErrorHandler from 'httpClient/noInternetErrorHandler';
import { NETWORK_ERROR } from 'constants/errors';

import requestHandler from './requestHandler';
import errorHandler from './errorHandler';

const UNAUTHORIZED = 401;

export default (store, client) => {
  client.interceptors.request.use(config => requestHandler(config, store));

  client.interceptors.response.use(
    async response => {
      if (response.data.error) {
        return errorHandler(response, store);
      }

      return response;
    },
    error => {
      if (error.message === NETWORK_ERROR) {
        noInternetErrorHandler(error, store);
      }

      if (error.response && error.response.status === UNAUTHORIZED) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );
};
