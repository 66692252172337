import React from 'react';
import { func } from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import SmallLogoIcon from 'assets/icons/logo-light-bg-small.svg';
import CrossIcon from 'assets/icons/cross.svg';
import Button from 'components/common/Button';
import { getStoreLink } from 'utils/helpers';

const messages = defineMessages({
  info: { id: 'downloadPopUp.info' },
  cta: { id: 'downloadPopUp.cta' }
});

const DownloadPopUp = ({ onClose }) => {
  const { formatMessage } = useIntl();

  const handleCTAClick = () => {
    window.location.href = getStoreLink();
  };

  return (
    <div className="download-pop-up d-flex justify-content-around align-items-center d-lg-none pl-4 pr-50 py-3 bg-white">
      <div className="mr-2">
        <img src={SmallLogoIcon} alt="Small Logo Icon" />
      </div>
      <div className="text-b3 text-weight-medium">{formatMessage(messages.info)}</div>
      <Button className="p-2" onClick={handleCTAClick}>
        {formatMessage(messages.cta)}
      </Button>
      <div className="download-pop-up__cross-icon bg-white p-1" onClick={onClose}>
        <img src={CrossIcon} alt="Cross Icon" />
      </div>
    </div>
  );
};

DownloadPopUp.propTypes = {
  onClose: func.isRequired
};

export default DownloadPopUp;
