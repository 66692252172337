import React from 'react';
import { bool, func } from 'prop-types';

import PrivateSidebar from 'components/common/Sidebar/PrivateSidebar';
import PublicSidebar from 'components/common/Sidebar/PublicSidebar';
import { ReactComponent as BurguerIcon } from 'assets/icons/burguer.svg';
import useSession from 'hooks/useSession';

const Hamburger = ({ toggled, onToggle }) => {
  const { user } = useSession();

  return (
    <div className="hamburger">
      <BurguerIcon onClick={onToggle} />
      {toggled && <div className="hamburger__overlay" onClick={onToggle} />}
      {user ? (
        <PrivateSidebar toggled={toggled} onToggle={onToggle} />
      ) : (
        <PublicSidebar toggled={toggled} onToggle={onToggle} />
      )}
    </div>
  );
};

Hamburger.propTypes = {
  toggled: bool.isRequired,
  onToggle: func.isRequired
};

export default Hamburger;
