import {
  SCREENS,
  USER_TYPE,
  SUBSCRIPTION_STATUS,
  NOT_AVAILABLE,
  STATUS
} from 'services/analytics/constants';
import { getCurrentScreen, getPreviousScreen, getValue, getUdid } from 'services/analytics/utils';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  signin: 'sign_in',
  signInInitiate: 'sign_in_initiate',
  signinStatus: 'sign_in_status',
  signupSigninInitiate: 'sign_up_sign_in_initiate',
  signinSuccess: 'sign_in_success',
  skipForNow: 'skip_for_now',
  forgotPassword: 'forgot_password',
  forgotPasswordDetails: 'forgot_password_details',
  resetPassword: 'reset_password'
};

class GASignInEvent extends AnalyticEvent {
  static signIn({ channel }) {
    return new GASignInEvent(
      EVENTS.signin,
      {
        screen_name: SCREENS.signinWithEmail,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        channel,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      true
    );
  }

  static signInInitiate({ channel }) {
    return new GASignInEvent(
      EVENTS.signInInitiate,
      {
        screen_name: SCREENS.signinWithEmail,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        channel,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      true
    );
  }

  static signInSuccess({ channel, user, entitlements }) {
    return new GASignInEvent(
      EVENTS.signinSuccess,
      {
        screen_name: SCREENS.signinWithEmail,
        sign_in_status: STATUS.success,
        previous_screen: SCREENS.onboarding,
        subscription_status: entitlements?.length
          ? SUBSCRIPTION_STATUS.paidUser
          : SUBSCRIPTION_STATUS.freeUser,
        channel,
        registration_id: user.id,
        subscription_id: NOT_AVAILABLE,
        user_type: USER_TYPE.loggedIn
      },
      false
    );
  }

  static signupSigninInitiate({ user, entitlements }) {
    return new GASignInEvent(
      EVENTS.signupSigninInitiate,
      {
        screen_name: getCurrentScreen(),
        previous_screen: getPreviousScreen(),
        subscription_status: entitlements?.length
          ? SUBSCRIPTION_STATUS.paidUser
          : SUBSCRIPTION_STATUS.freeUser,
        registration_id: getValue(user?.id),
        subscription_id: NOT_AVAILABLE,
        user_type: USER_TYPE.guest,
        anonymous_id: getUdid()
      },
      true
    );
  }

  static signInStatusSuccess({ channel, user, entitlements }) {
    return new GASignInEvent(
      EVENTS.signinStatus,
      {
        screen_name: SCREENS.signinWithEmail,
        sign_in_status: STATUS.success,
        previous_screen: SCREENS.onboarding,
        subscription_status: entitlements?.length
          ? SUBSCRIPTION_STATUS.paidUser
          : SUBSCRIPTION_STATUS.freeUser,
        channel,
        registration_id: user.id,
        subscription_id: NOT_AVAILABLE,
        user_type: USER_TYPE.loggedIn
      },
      true
    );
  }

  static signInStatusFail({ channel }) {
    return new GASignInEvent(
      EVENTS.signinStatus,
      {
        screen_name: SCREENS.signinWithEmail,
        user_type: USER_TYPE.guest,
        sign_in_status: STATUS.fail,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        channel,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      true
    );
  }

  static forgotPassword() {
    return new GASignInEvent(
      EVENTS.forgotPassword,
      {
        screen_name: SCREENS.signinWithEmail,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      false
    );
  }

  static forgotPasswordDetails() {
    return new GASignInEvent(
      EVENTS.forgotPasswordDetails,
      {
        screen_name: SCREENS.forgotPassword,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.signinWithEmail,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      false
    );
  }

  static resetPassword() {
    return new GASignInEvent(
      EVENTS.resetPassword,
      {
        screen_name: SCREENS.resetPassword,
        user_type: USER_TYPE.guest,
        previous_screen: NOT_AVAILABLE,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      false
    );
  }

  static skipForNow() {
    return new GASignInEvent(
      EVENTS.skipForNow,
      {
        screen_name: SCREENS.signup,
        user_type: USER_TYPE.guest,
        previous_screen: SCREENS.onboarding,
        subscription_status: SUBSCRIPTION_STATUS.freeUser,
        registration_id: NOT_AVAILABLE,
        subscription_id: NOT_AVAILABLE
      },
      false
    );
  }
}

export default GASignInEvent;
