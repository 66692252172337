import { ENVIRONMENTS } from 'constants/constants';

const config = {
  staging: {
    productsFlavors: {
      bannerTypeId: 180,
      thumbnailTypeId: 158
    }
  },
  production: {
    productsFlavors: {
      bannerTypeId: 51,
      thumbnailTypeId: 37
    }
  }
};

const kalturaConfig = {
  apiVersion: process.env.API_VERSION,
  baseUrl: process.env.KALTURA_API,
  partnerId: 3208,
  playerUiConfId: process.env.KALTURA_PLAYER_UI_CONFIG_ID,
  youboraAccountCode: process.env.YOUBORA_ACCOUNT_CODE,
  productsFlavors:
    process.env.API_ENV === ENVIRONMENTS.PRODUCTION
      ? config.production.productsFlavors
      : config.staging.productsFlavors
};

export default kalturaConfig;
