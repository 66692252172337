import { createSlice } from '@reduxjs/toolkit';
import intersectionBy from 'lodash/intersectionBy';

import { fetchCategoriesFulfilled } from 'state/actions/categoriesActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  home: [],
  browse: [],
  mediaTypes: null,
  heroBannerData: []
};

const mapBrowseId = (item, intersections) => {
  const browse = intersections.find(({ name }) => name === item.name);
  const browseId = browse ? browse.id : null;

  return browseId;
};

const parseHomeCategories = (home, browse) => {
  const intersections = intersectionBy(browse, home, 'name');

  return home.map(item => ({
    id: item.id,
    name: item.name,
    channels: item.unifiedChannels,
    browseId: intersections.length > 0 ? mapBrowseId(item, intersections) : null
  }));
};

const parseBrowseCategories = data =>
  data.map(item => ({
    id: item.id,
    name: item.name,
    channels: item.unifiedChannels
  }));

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  extraReducers: {
    [fetchCategoriesFulfilled]: (state, { payload: { home, browse, mediaTypes } }) => {
      state.home = parseHomeCategories(home, browse);
      state.browse = parseBrowseCategories(browse);
      state.mediaTypes = mediaTypes;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default categoriesSlice.reducer;
