import { createIntl, createIntlCache } from 'react-intl';

let cache;
let intl;

const generateIntl = props => {
  if (cache) {
    cache = null;
  }

  cache = createIntlCache();
  intl = createIntl(props, cache);

  return intl;
};

const getIntl = () => intl;

export { generateIntl, getIntl };
