import { string } from 'prop-types';
import React from 'react';

import { grey } from 'styles/_variables.scss';

const BookMarkerIcon = ({ color = grey, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    fill="none"
    viewBox="0 0 17 18"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.897 17V1H1.007v14.17"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.567 17H2.53a1.695 1.695 0 01-1.524-1.83v0a1.695 1.695 0 011.524-1.829h8.074M4.628 2.11v5.475L6.36 6.122l1.756 1.463V1.841"
    />
  </svg>
);

BookMarkerIcon.propTypes = {
  color: string
};

export default BookMarkerIcon;
