import axios from 'axios';
import userService from 'services/userService';
import { logout, setKs } from 'state/actions/userActions';

import { EXPIRED_TOKEN_ERROR, SERVICE_FORBIDDEN_ERROR } from 'constants/errors';

const expiredTokenHandler = async (response, store) => {
  try {
    const originalRequest = response.config;
    const {
      data: { ks: anonymousKs }
    } = await userService.anonymousLogin();
    let ks = anonymousKs;

    const { authenticated } = store.getState().session;
    if (authenticated) {
      const { id, token, hashType } = authenticated;
      const {
        data: { ks: userKs }
      } = await userService.startSession({
        ks: anonymousKs,
        id,
        token,
        hashType
      });
      ks = userKs;
    }

    if (originalRequest.method === 'get') {
      originalRequest.params.ks = ks;
    }

    if (originalRequest.method === 'post') {
      originalRequest.data = JSON.stringify({
        ...JSON.parse(originalRequest.data),
        ks
      });
    }

    store.dispatch(setKs(ks));

    return axios(originalRequest);
  } catch (err) {
    const { user } = store.getState().session;
    if (user) {
      store.dispatch(logout({ expired: true }));
    }
  }
};

export default (response, store) => {
  const errorCode = response.data.error.code;

  switch (errorCode) {
    case EXPIRED_TOKEN_ERROR:
      return expiredTokenHandler(response, store);
    case SERVICE_FORBIDDEN_ERROR:
      return Promise.reject(response.data);
    default:
      return Promise.reject(response.data);
  }
};
