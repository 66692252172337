import subscriptionService from 'services/subscriptionService';

import parseError from 'utils/parseError';
import createAsyncThunk from 'utils/createAsyncThunk';

import { USA_PLANS, INDIA_PLANS } from 'services/constants';
import { COUNTRIES } from 'constants/constants';
import { PACKAGE_TYPES } from 'constants/subscriptions';

export const getValidEntitlements = createAsyncThunk(
  'subscription/getValidEntitlement',
  async () => {
    try {
      const {
        data: { objects: entitlements }
      } = await subscriptionService.getEntitlement({ isExpired: false });

      return { entitlements };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const getExpiredEntitlements = createAsyncThunk(
  'subscription/getExpiredEntitlements',
  async () => {
    try {
      const {
        data: { objects: entitlements }
      } = await subscriptionService.getEntitlement({ isExpired: true });

      return { entitlements };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const getPackageDetails = createAsyncThunk(
  'subscription/getPackageDetails',
  async (_, { getState }) => {
    try {
      const { country: { code: country } = {} } = getState().session;

      const isIndia = country === COUNTRIES.INDIA;

      const {
        data: { objects: subscriptionPackage }
      } = await subscriptionService.getStripePackage();

      const filteredPackages = subscriptionPackage.filter(({ name }) =>
        Object.values(isIndia ? INDIA_PLANS : USA_PLANS).some(plan => plan === name)
      );

      const packageDetails = await Promise.all(
        filteredPackages.map(async p => {
          const {
            data: {
              objects: [packageDetail]
            }
          } = await subscriptionService.getSubscriptionDetail({ id: p.metas.BaseID.value });

          return {
            ...packageDetail,
            type:
              p.name === USA_PLANS[PACKAGE_TYPES.PREMIUM_ANNUAL] ||
              p.name === INDIA_PLANS[PACKAGE_TYPES.PREMIUM_ANNUAL]
                ? PACKAGE_TYPES.PREMIUM_ANNUAL
                : PACKAGE_TYPES.PREMIUM_MONTHLY
          };
        })
      );

      return { packageDetails };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const cancelRenewal = createAsyncThunk(
  'subscription/cancelRenewal',
  async ({ productId: id }) => {
    try {
      await subscriptionService.cancelRenewal({ id });
      return { id };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const {
  fulfilled: getValidEntitlementsFulfilled,
  reset: getValidEntitlementsReset
} = getValidEntitlements;
export const { fulfilled: getExpiredEntitlementsFulfilled } = getExpiredEntitlements;
export const { fulfilled: getPackageDetailsFulfilled } = getPackageDetails;
export const { fulfilled: cancelRenewalFulfilled } = cancelRenewal;
