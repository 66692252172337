import React from 'react';
import cn from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import useModal from 'hooks/useModal';

const Modal = () => {
  const {
    visible,
    content,
    title,
    footer,
    showClose,
    fromBottom,
    backdrop,
    closeModal
  } = useModal();

  if (!content) return null;

  const handleOverlayClick = ({ currentTarget, target }) => {
    if (backdrop && currentTarget === target) {
      closeModal();
    }
  };

  return (
    <div
      className={cn(
        'modal z-index-2',
        { 'modal--hidden': !visible },
        { 'modal--bottom': fromBottom }
      )}
    >
      <div
        className="modal__background d-flex justify-content-center align-item-center"
        onClick={handleOverlayClick}
      >
        <div className="modal__content p-4 p-lg-5">
          <div className="modal__header">
            {typeof title === 'string' ? (
              <p className="mt-1 mt-lg-0 mb-4 text-weight-bold text-center text-b2 text-lg-h4">
                {title}
              </p>
            ) : (
              title
            )}
            {showClose && <CloseIcon onClick={closeModal} />}
          </div>
          {content}
          {footer}
        </div>
      </div>
    </div>
  );
};

export default Modal;
