import {
  getUser,
  getValue,
  getAnnualPrice,
  getUserType,
  getPreviousScreen
} from 'services/analytics/utils';
import {
  SCREENS,
  SUBSCRIPTION_STATUS,
  DATE_FORMAT,
  ET_PRIME,
  NOT_AVAILABLE,
  USER_TYPE,
  PROMOTION_CODE_APPLIED_GA
} from 'services/analytics/constants';
import { formatDate, getOneYearFromNow } from 'utils/dateHelpers';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  activateNow: 'activate_now',
  subscriptionStatus: 'subscription_status',
  subscriptionSuccessful: 'subscription_successful'
};

class GAETPrimeEvent extends AnalyticEvent {
  static activateNow({ coupon, price }) {
    return new GAETPrimeEvent(EVENTS.activateNow, {
      screen_name: SCREENS.etPrime,
      previous_screen: NOT_AVAILABLE,
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      currency: getValue(price?.currency?.toLowerCase()),
      subscription_start_date: formatDate(new Date(), DATE_FORMAT.SHORT),
      subscription_end_date: formatDate(getOneYearFromNow(), DATE_FORMAT.SHORT),
      revenue_ep: getValue(price?.amount),
      module_name: ET_PRIME.module.name,
      module_id: ET_PRIME.module.id,
      promocode: coupon,
      registration_id: getValue(getUser()?.id),
      subscription_id: NOT_AVAILABLE,
      user_type: getUserType()
    });
  }

  static subscriptionStatus({
    startDate,
    endDate,
    promocode,
    promocodeApplied,
    status,
    subscriptionStatus
  }) {
    const price = getAnnualPrice();

    return new GAETPrimeEvent(EVENTS.subscriptionStatus, {
      currency_ep: getValue(price?.currency?.toLowerCase()),
      module_id: ET_PRIME.module.id,
      module_name: ET_PRIME.module.name,
      promocode: getValue(promocode),
      promocode_applied: promocodeApplied
        ? PROMOTION_CODE_APPLIED_GA.yes
        : PROMOTION_CODE_APPLIED_GA.no,
      previous_screen: getPreviousScreen(),
      revenue_ep: getValue(price?.amount),
      screen_name: SCREENS.subscription,
      status,
      subscription_start_date: getValue(formatDate(startDate, DATE_FORMAT.SHORT)),
      subscription_end_date: getValue(formatDate(endDate, DATE_FORMAT.SHORT)),
      subscription_status: getValue(subscriptionStatus),
      user_type: USER_TYPE.loggedIn
    });
  }

  static subscriptionSuccessful({ startDate, endDate }) {
    const price = getAnnualPrice();

    return new GAETPrimeEvent(EVENTS.subscriptionSuccessful, {
      screen_name: SCREENS.subscription,
      user_type: USER_TYPE.loggedIn,
      previous_screen: SCREENS.payment,
      subscription_status: SUBSCRIPTION_STATUS.paidUser,
      transaction_id: NOT_AVAILABLE,
      currency: getValue(price?.currency?.toLowerCase()),
      revenue_ep: getValue(price?.amount),
      subscription_start_date: getValue(formatDate(startDate, DATE_FORMAT.SHORT)),
      subscription_end_date: getValue(formatDate(endDate, DATE_FORMAT.SHORT)),
      payment_method: NOT_AVAILABLE,
      module_name: ET_PRIME.module.name,
      module_id: ET_PRIME.module.id
    });
  }
}

export default GAETPrimeEvent;
