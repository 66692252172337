import { SERVICES } from '../constants';

import AnalyticEvent from './AnalyticEvent';

class CTAnalyticEvent extends AnalyticEvent {
  constructor(name, params = {}, setContent = false) {
    super(name, params, setContent, [SERVICES.CLEVERTAP]);
  }
}

export default CTAnalyticEvent;
