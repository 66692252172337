import React from 'react';
import { useHistory } from 'react-router';
import { useIntl, defineMessages } from 'react-intl';

import { BUTTON_STYLES } from 'constants/styles';

import Button from 'components/common/Button';
import ForbiddenIcon from 'assets/icons/forbidden.svg';
import useMediaQueries from 'hooks/useMediaQueries';

const messages = defineMessages({
  title: { id: 'noInternet.title' },
  info: { id: 'noInternet.info' },
  cta: { id: 'noInternet.cta' }
});

const NoInternet = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { isMobileOrTablet } = useMediaQueries();

  const handleTryAgainClick = () => history.go(0);

  return (
    <div className="no-internet d-flex flex-column justify-content-center align-items-center mt-6">
      <img src={ForbiddenIcon} alt="forbidden" className="no-internet__image mt-5" />
      <h2 className="text-center text-b2 text-lg-h6 mb-2">{formatMessage(messages.title)}</h2>
      <div className="no-internet__body text-h4 mx-5 text-center">
        {formatMessage(messages.info)}
      </div>
      <Button
        className="no-internet__button mt-5"
        onClick={handleTryAgainClick}
        styleType={isMobileOrTablet ? BUTTON_STYLES.LINK_PRIMARY : BUTTON_STYLES.PRIMARY}
      >
        {formatMessage(messages.cta)}
      </Button>
    </div>
  );
};

export default NoInternet;
