import React, { useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useSession, useInitialData, useSetDeviceView } from 'hooks';
import { initSession } from 'state/actions/userActions';
import AnalyticManager from 'services/analytics/AnalyticManager';
import GAScreenEvent from 'services/analytics/events/GAScreenEvent';
// EI-3384 - [Web] CT Implementation- Turn off web permission popup
// import { requestUserPermission } from 'utils/webPushNotifications';
import { PAGE_LAYOUT } from 'constants/constants';

import Loading from 'components/common/Loading';
import Layout from './common/Layout';

import 'react-toastify/dist/ReactToastify.css';

import Router from './Router';

const App = () => {
  const { user, udid, ks } = useSession();
  const { loading } = useInitialData();
  const dispatch = useDispatch();
  useSetDeviceView();

  useEffect(() => {
    if (!udid) {
      dispatch(initSession());
      AnalyticManager.logScreen(GAScreenEvent.firstTimeWebVisit());
    }

    user && AnalyticManager.login({ user, email: user.email });
  }, [udid, user, dispatch]);

  // EI-3384 - [Web] CT Implementation- Turn off web permission popup
  /*
  useEffect(() => {
    const timer = setTimeout(() => {
      // Postpone the Requesting User Permissions for 5 seconds after the page is loaded.
      requestUserPermission();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  */

  // eslint-disable-next-line react/no-multi-comp
  const renderLoadingWithLayout = () => (
    <Layout type={PAGE_LAYOUT.main}>
      <Loading id="initialStatus" />
    </Layout>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={renderLoadingWithLayout()}>
        {loading && renderLoadingWithLayout()}
        {ks && <Router />}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
