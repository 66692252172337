import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import cn from 'classnames';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import DownloadPopUp from 'components/common/DownloadPopUp';

import { useModal, useMediaQueries } from 'hooks';

const MainLayout = ({ children, showDownload, showMobileFooter, className = '' }) => {
  const { isDesktop, isMobileOrTablet } = useMediaQueries();
  const { visible: isModalOpen } = useModal();
  const [showDownloadPopUp, setShowDownloadPopUp] = useState(showDownload);

  return (
    <div
      className={cn('main-layout', {
        'main-layout--no-scroll': isModalOpen
      })}
    >
      <Header />
      <div
        className={cn('main-layout__content', {
          'main-layout__content--footer': !isDesktop
        })}
      >
        <main className={className}>{children}</main>
      </div>
      {isMobileOrTablet && showDownloadPopUp && (
        <DownloadPopUp onClose={() => setShowDownloadPopUp(false)} />
      )}

      {(isDesktop || (isMobileOrTablet && showMobileFooter)) && (
        <Footer className={cn({ shadow: !showDownloadPopUp })} />
      )}
    </div>
  );
};

MainLayout.propTypes = {
  children: node.isRequired,
  showDownload: bool,
  showMobileFooter: bool,
  className: string
};

export default MainLayout;
