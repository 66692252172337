import { createSlice } from '@reduxjs/toolkit';
import {
  setRelatedVideo,
  fetchRecommendedForYouFulfilled
} from 'state/actions/recommendedForYouActions';
import { reset } from 'state/actions/contineWatchingActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  data: [],
  totalCount: 0,
  relatedVideoId: null,
  typeIds: null
};

const recommendedForYouSlice = createSlice({
  name: 'recommendedForYou',
  initialState,
  extraReducers: {
    [setRelatedVideo]: (state, { payload }) => {
      state.relatedVideoId = payload.relatedVideoId;
      state.typeIds = payload.typeIds;
    },
    [fetchRecommendedForYouFulfilled]: (state, { payload }) => {
      state.data.push(...payload.videos);
      state.page = payload.page;
    },
    [reset]: () => initialState,
    [logoutFulfilled]: () => initialState
  }
});

export default recommendedForYouSlice.reducer;
