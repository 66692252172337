import { PACKAGE_TYPES } from 'constants/subscriptions';
import { CT_STATUS, DATE_FORMAT, PROMOTION_CODE_APPLIED } from 'services/analytics/constants';
import {
  getAnnualPrice,
  getSignupChannel,
  getSubscriptions,
  getUser,
  getValue
} from 'services/analytics/utils';
import { formatDate } from 'utils/dateHelpers';
import CTAnalyticEvent from './CTAnalyticEvent';

const EVENTS = {
  activateNow: 'Activate_Now',
  subscriptionSuccessful: 'Subscription_Successful'
};

class CTETPrimeEvent extends CTAnalyticEvent {
  static activateNow() {
    return new CTETPrimeEvent(EVENTS.activateNow, {
      channel: getSignupChannel()
    });
  }

  static subscriptionSuccessful({ startDate, endDate, promocode }) {
    const price = getAnnualPrice();

    const eventParams = {
      Status: CT_STATUS.success,
      'Start Date': getValue(formatDate(startDate, DATE_FORMAT.SHORT)),
      'End Date': getValue(formatDate(endDate, DATE_FORMAT.SHORT)),
      Module_ID: getValue(getSubscriptions()[PACKAGE_TYPES.PREMIUM_ANNUAL].ID),
      Module_Name: getValue(getSubscriptions()[PACKAGE_TYPES.PREMIUM_ANNUAL].NAME),
      Currency: getValue(price?.cureency?.toLowerCase()),
      Revenue: getValue(price?.amount),
      Registration_ID: getValue(getUser()?.id),
      promocode: getValue(promocode),
      promocode_applied: PROMOTION_CODE_APPLIED.yes
    };
    return new CTETPrimeEvent(EVENTS.subscriptionSuccessful, eventParams);
  }
}

export default CTETPrimeEvent;
