import { combineReducers } from 'redux';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import statusReducer from './statusReducer';
import session from './sessionReducer';
import categories from './categoriesReducer';
import continueWatching from './continueWatchingReducer';
import payment from './paymentReducer';
import recommendedForYou from './recommendedForYouReducer';
import channels from './channelsReducer';
import videos from './videosReducer';
import subscriptions from './subscriptionReducer';
import search from './searchReducer';
import insights from './insightsReducer';
import routing from './routingReducer';
import info from './infoReducer';

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authenticated', 'ks', 'user', 'udid', 'country'],
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, session),
  statusReducer,
  categories,
  continueWatching,
  recommendedForYou,
  videos,
  channels,
  payment,
  subscriptions,
  insights,
  search,
  routing,
  info
});

export default rootReducer;
