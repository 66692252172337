import React from 'react';
import { string, node, func } from 'prop-types';

const CallToAction = ({ label, icon, onClick }) => (
  <div className="d-flex mx-4 py-3" onClick={onClick}>
    <img src={icon} alt={label} />
    <div className="ml-2">{label}</div>
  </div>
);

CallToAction.propTypes = {
  label: string.isRequired,
  icon: node.isRequired,
  onClick: func.isRequired
};

export default CallToAction;
