import React, { useEffect, useRef, useMemo, Suspense } from 'react';
import { node, oneOf } from 'prop-types';
import { Helmet } from 'react-helmet';

import { PAGE_LAYOUT } from 'constants/constants';

import Toast from './Toast';
import Modal from './Modal';
import ModalProvider from './Modal/ModalProvider';

import AuthLayout from './Auth';
import MainLayout from './Main';
import MarketingLandingLayout from './MarketingLandingLayout';
import Loading from '../Loading';

const Layout = ({ type, children, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    const calculateAddressBarHeight = () => {
      const actualHeight = window.visualViewport.height;
      const elementHeight = ref.current?.clientHeight || 0;
      const barHeight = elementHeight - actualHeight;

      document.documentElement.style.setProperty(
        '--addressBarHeight',
        `${barHeight > 0 ? barHeight : 0}px`
      );
    };

    window.visualViewport.addEventListener('resize', calculateAddressBarHeight);
    calculateAddressBarHeight();
  }, []);

  const LayoutComponent = useMemo(() => {
    switch (type) {
      case PAGE_LAYOUT.main:
        return MainLayout;
      case PAGE_LAYOUT.auth:
        return AuthLayout;
      case PAGE_LAYOUT.marketingLanding:
        return MarketingLandingLayout;
      default:
        return React.Fragment;
    }
  }, [type]);

  return (
    <>
      <Helmet>
        <title>Emeritus Insights</title>
      </Helmet>
      <Toast />
      <div ref={ref} id="control-height" />

      <ModalProvider>
        <Modal />
        <LayoutComponent {...rest}>
          <Suspense fallback={<Loading id="lazyPageStatus" />}>{children}</Suspense>
        </LayoutComponent>
      </ModalProvider>
    </>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  type: oneOf(Object.values(PAGE_LAYOUT))
};

export default React.memo(Layout);
