import CTAnalyticEvent from './CTAnalyticEvent';

const EVENTS = {
  logOut: 'Sign Out'
};

class CTSettingsEvent extends CTAnalyticEvent {
  static logOut() {
    return new CTSettingsEvent(EVENTS.logOut);
  }
}

export default CTSettingsEvent;
