import { createSlice } from '@reduxjs/toolkit';
import {
  getGatewaysFulfilled,
  getMethodsFullfilled,
  getConfigurationFullfilled,
  createCustomerFullfilled,
  addCardFullfilled,
  addCardRejected,
  getProductPriceFullfilled,
  resetGetProductPrice,
  validateRedemptionCouponFulfilled,
  validateCouponReset,
  purchaseFullfilled,
  setProductPrice
} from 'state/actions/paymentActions';
import { PAYMENT_CONFIGURATION_KEYS } from 'services/constants';

const initialState = {
  gatewayId: null,
  methods: null,
  configKey: null,
  paymentMethodId: null,
  productPrice: null,
  coupon: null,
  orderId: null
};

const parseConfiguration = (configuration, key) => configuration.find(c => c.key === key)?.value;

const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  extraReducers: {
    [getGatewaysFulfilled]: (state, { payload: { gateways } }) => {
      state.gatewayId = gateways.find(({ name }) => name === 'Stripe')?.id;
    },
    [getMethodsFullfilled]: (state, { payload: { methods } }) => {
      state.methods = methods;
    },
    [getConfigurationFullfilled]: (state, { payload: { configuration } }) => {
      state.configKey = parseConfiguration(
        configuration,
        PAYMENT_CONFIGURATION_KEYS.PUBLISHABLE_KEY
      );
    },
    [createCustomerFullfilled]: (state, { payload: { configuration } }) => {
      const paymentMethodId = parseConfiguration(
        configuration,
        PAYMENT_CONFIGURATION_KEYS.PAYMENT_METHOD_ID
      );
      const paymentGatewayId = parseConfiguration(
        configuration,
        PAYMENT_CONFIGURATION_KEYS.GATEWAY_ID
      );

      state.paymentMethodId = paymentMethodId;
      state.methods = [{ id: paymentMethodId, paymentGatewayId }];
    },
    [addCardFullfilled]: (state, { payload: { configuration } }) => {
      const paymentMethodId = parseConfiguration(
        configuration,
        PAYMENT_CONFIGURATION_KEYS.PAYMENT_METHOD_ID
      );
      const paymentGatewayId = parseConfiguration(
        configuration,
        PAYMENT_CONFIGURATION_KEYS.GATEWAY_ID
      );

      state.paymentMethodId = paymentMethodId;
      state.methods = [{ id: paymentMethodId, paymentGatewayId }];
    },
    [addCardRejected]: state => {
      state.methods = [];
    },
    [getProductPriceFullfilled]: (state, { payload: { productPrice } }) => {
      state.productPrice = productPrice;
    },
    [purchaseFullfilled]: (state, { payload }) => {
      state.orderId = payload.data?.id;
    },
    [setProductPrice]: (state, { payload }) => {
      state.productPrice = payload.productPrice;
    },
    [resetGetProductPrice]: state => {
      state.productPrice = null;
    },
    [validateRedemptionCouponFulfilled]: (state, { payload: { coupon, productPrice } }) => {
      state.coupon = coupon;
      state.productPrice = productPrice;
    },
    [validateCouponReset]: state => {
      state.coupon = null;
      state.productPrice = null;
    }
  }
});

export default paymentSlice.reducer;
