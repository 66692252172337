import kalturaClient from 'httpClient/kaltura';

import { CATEGORY_PAGE_SIZE, OBJECT_TYPE } from './constants';

class SearchService {
  static searchVideo({ term, pageIndex }) {
    const body = {
      filter: {
        objectType: OBJECT_TYPE.assetFilter,
        kSql: `Name~'${term} '`
      },
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageSize: CATEGORY_PAGE_SIZE,
        pageIndex
      }
    };

    return kalturaClient.post('/asset/action/list', body);
  }
}

export default SearchService;
