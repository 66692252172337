import React, { useState, createContext, useCallback } from 'react';
import { node } from 'prop-types';

export const modalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
  visible: false,
  showClose: true,
  fromBottom: false,
  backdrop: true,
  title: null,
  content: null,
  footer: null
});

const ModalProvider = ({ children }) => {
  const { Provider } = modalContext;
  const [modalData, setModalData] = useState({
    visible: false,
    showClose: true,
    fromBottom: false,
    backdrop: true,
    title: null,
    content: null,
    footer: null
  });

  const [onClose, setOnClose] = useState();

  return (
    <Provider
      value={{
        ...modalData,
        openModal: useCallback(
          ({
            title,
            content,
            footer,
            onClose,
            backdrop = true,
            showClose = true,
            fromBottom = false
          }) => {
            setModalData({
              title,
              content,
              footer,
              showClose,
              fromBottom,
              backdrop,
              visible: true
            });
            setOnClose(onClose ? () => onClose : null);
          },
          []
        ),
        closeModal: useCallback(() => {
          setModalData({ content: null, title: null, footer: null, visible: false });
          onClose && onClose();
          setOnClose(null);
        }, [onClose])
      }}
    >
      {children}
    </Provider>
  );
};

ModalProvider.propTypes = {
  children: node.isRequired
};

export default ModalProvider;
