import { createSlice } from '@reduxjs/toolkit';

import { fetchChannelsFulfilled } from 'state/actions/channelsActions';
import { fetchCategoriesFulfilled } from 'state/actions/categoriesActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  byId: {}
};

const parseCategories = categories => {
  const byId = {};
  categories.forEach(category => {
    category.unifiedChannels.forEach(({ id, name }) => {
      byId[id] = {
        categoryId: category.id,
        name,
        data: [],
        totalCount: 0
      };
    });
  });

  return byId;
};

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  extraReducers: {
    [fetchCategoriesFulfilled]: (state, { payload: { home = [], browse = [] } }) => {
      state.byId = parseCategories(home.concat(browse));
    },
    [fetchChannelsFulfilled]: (state, { payload }) => {
      const { id } = payload;

      if (!state.byId[id]) {
        state.byId[id] = {
          data: []
        };
      }

      state.byId[id].data.push(...payload.channels);
      state.byId[id].totalCount = payload.totalCount;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default channelsSlice.reducer;
