import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useStatus } from 'hooks';
import { fetchChannels } from 'state/actions/channelsActions';
import { FULFILLED, REJECTED } from 'constants/actionStatusConstants';

export default id => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { status } = useStatus(fetchChannels);
  const channels = useSelector(state => state.channels.byId[id]);

  const fetchChannelsRequest = useCallback(() => {
    dispatch(fetchChannels({ id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (!channels?.data?.length) {
      fetchChannelsRequest();
    } else {
      setLoading(false);
    }
  }, [id, channels, fetchChannelsRequest]);

  return {
    channels: channels?.data,
    hasMore: channels?.totalCount > channels?.data?.length,
    fetchChannels: fetchChannelsRequest,
    error: status === REJECTED,
    success: status === FULFILLED,
    loading
  };
};
