import { useSelector, shallowEqual } from 'react-redux';

const usePayment = () =>
  useSelector(
    ({ payment: { productPrice, coupon } }) => ({
      productPrice,
      coupon
    }),
    shallowEqual
  );

export default usePayment;
