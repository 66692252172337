import { useEffect } from 'react';

import useDispatch from 'hooks/useDispatch';

export default action => {
  const reset = useDispatch(action.reset);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);
};
