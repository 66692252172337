import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from 'state/actions/userActions';
import { useStatus } from 'hooks';
import AnalyticManager from 'services/analytics/AnalyticManager';
import GASettingsEvent from 'services/analytics/events/GASettingsEvent';
import CTSettingsEvent from 'services/analytics/events/CTSettingsEvent';

import BookMarkerIcon from 'components/icons/BookMarkerIcon';
import PrivacyIcon from 'components/icons/PrivacyIcon';

import { PENDING } from 'constants/actionStatusConstants';
import { SETTING_TABS } from 'constants/constants';

export default () => {
  const [selected, setSelected] = useState(0);
  const dispatch = useDispatch();

  const { status: logoutStatus } = useStatus(logout);

  const isLoading = logoutStatus === PENDING;

  const handleLogoutSelect = () => {
    if (!isLoading) dispatch(logout());
    AnalyticManager.logEvent(GASettingsEvent.logOut());
    AnalyticManager.logEvent(CTSettingsEvent.logOut());
    AnalyticManager.logout();
  };

  const handleMyAccountSelect = () => {
    AnalyticManager.logEvent(GASettingsEvent.myAccount());
  };

  const handleFeedbackSelect = () => {
    AnalyticManager.logEvent(GASettingsEvent.feedback());
  };

  const handlePrivacySelect = () => {
    AnalyticManager.logEvent(GASettingsEvent.privacy());
  };

  const handleTermsSelect = () => {
    AnalyticManager.logEvent(GASettingsEvent.terms());
  };

  const tabs = [
    {
      id: SETTING_TABS.ACCOUNT,
      labelId: 'settings.tabs.account',
      Icon: BookMarkerIcon,
      handleSelect: handleMyAccountSelect
    },
    {
      id: SETTING_TABS.FEEDBACK,
      labelId: 'settings.tabs.feedback',
      Icon: BookMarkerIcon,
      handleSelect: handleFeedbackSelect
    },
    {
      id: SETTING_TABS.PRIVACY,
      labelId: 'settings.tabs.privacy',
      Icon: PrivacyIcon,
      handleSelect: handlePrivacySelect
    },
    {
      id: SETTING_TABS.TERMS,
      labelId: 'settings.tabs.terms',
      Icon: BookMarkerIcon,
      handleSelect: handleTermsSelect
    },
    {
      id: SETTING_TABS.LOGOUT,
      labelId: 'settings.tabs.logOut',
      Icon: BookMarkerIcon,
      handleSelect: handleLogoutSelect
    }
  ];

  const handleSelect = value => {
    setSelected(value);
    tabs[value].handleSelect && tabs[value].handleSelect();
  };

  return { tabs, isLoading, selected, handleSelect };
};
