import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useStatus,
  usePayment,
  useSession,
  useDateTimeFormat,
  useMediaQueries,
  useReset
} from 'hooks';
import { validateRedemptionCoupon, validateCouponReset } from 'state/actions/paymentActions';
import { getValidEntitlementsReset } from 'state/actions/subscriptionActions';

import { formatMillisecondsToDate, formatDate } from 'utils/dateHelpers';

import { FULFILLED, PENDING, REJECTED } from 'constants/actionStatusConstants';
import { COUNTRIES } from 'constants/constants';
import { CURRENCIES } from 'constants/subscriptions';
import routes from 'constants/routesPaths';
import { ALREADY_PURCHASED_USER } from 'constants/errors';

export default ({ productId, preventCleanup = false, preventRedirect = false } = {}) => {
  const history = useHistory();
  const { country } = useSession();
  const { pathname } = useLocation();
  const { coupon, productPrice } = usePayment();
  const { isMobileOrTablet } = useMediaQueries();
  const dateFormat = useDateTimeFormat();
  const { purchaseDate, endDate } = coupon || {};
  const { status: validateStatus, code } = useStatus(validateRedemptionCoupon);
  const dispatch = useDispatch();

  const isSuccess = validateStatus === FULFILLED;
  const isError = validateStatus === REJECTED;
  const isLoading = validateStatus === PENDING;
  const isUserAlreadySubscribed = code === ALREADY_PURCHASED_USER;

  const price = {
    amount: 0,
    currencySign: country === COUNTRIES.INDIA ? CURRENCIES.INDIA : CURRENCIES.USA
  };

  const handleSubmit = useCallback(
    (code, flow) => {
      dispatch(validateRedemptionCoupon({ code, productId, flow }));
    },
    [productId, dispatch]
  );

  const handleCancel = () => {
    dispatch(validateCouponReset());
  };

  useReset(validateRedemptionCoupon);

  useEffect(() => {
    if (validateStatus === FULFILLED) {
      if (productPrice) return;

      dispatch(getValidEntitlementsReset());

      if (
        !preventRedirect &&
        !pathname.includes(routes.settings) &&
        !pathname.includes(routes.subscriptions)
      )
        history.push(isMobileOrTablet ? routes.subscriptions : routes.settings, {
          subscriptionGranted: true
        });
    }
  }, [
    validateStatus,
    isMobileOrTablet,
    pathname,
    productPrice,
    history,
    preventRedirect,
    dispatch
  ]);

  useEffect(
    () => () => {
      if (!preventCleanup && coupon && !coupon?.ok) dispatch(validateRedemptionCoupon.reset());
    },
    [preventCleanup, coupon, dispatch]
  );

  return {
    handleSubmit,
    handleCancel,
    isSuccess,
    isError,
    isLoading,
    isUserAlreadySubscribed,
    priceDetails: { price },
    subscriptionDate: formatDate(formatMillisecondsToDate(purchaseDate), dateFormat.shortDate),
    renewDate: formatDate(formatMillisecondsToDate(endDate), dateFormat.shortDate)
  };
};
