import appConfig from 'config';
import store from 'state/store/storeRegistry';
import {
  NOT_AVAILABLE,
  SERVICES,
  SOURCE_EVENT,
  DATE_FORMAT,
  USER_TYPE_CT,
  SUBSCRIPTION_STATUS_CT
} from 'services/analytics/constants';
import { VIEW } from 'services/constants';
import { formatMillisecondsToDate } from 'utils/dateHelpers';

import AnalyticService from './AnalyticService';
import { getValue } from '../utils';

function parseParams(params) {
  Object.keys(params).forEach(key => {
    if (
      params[key] === undefined ||
      params[key] === null ||
      params[key] === '' ||
      params[key] === NOT_AVAILABLE
    ) {
      delete params[key];
    }
  });
}

class CleverTapService extends AnalyticService {
  static USER_ID_PARAM_KEY = 'User ID';

  constructor() {
    super();
    this.id = SERVICES.CLEVERTAP;
  }

  logEvent(event) {
    const {
      session: { country, user },
      info: {
        os: { name: osName, version: osVersion },
        deviceView: { view }
      }
    } = store.getState();

    let sourceEvent;
    if (view === VIEW.desktop) {
      sourceEvent = SOURCE_EVENT.DESKTOP_WEB;
    } else if (view === VIEW.tablet) {
      sourceEvent = SOURCE_EVENT.TABLE;
    } else {
      sourceEvent = SOURCE_EVENT.MOBILE_WEB;
    }

    let ctOsVersion;
    if (osName && osVersion) {
      ctOsVersion = `${osName} ${osVersion}`;
    } else if (osName) {
      ctOsVersion = osName;
    } else if (osVersion) {
      ctOsVersion = osVersion;
    }

    const eventParams = {
      [CleverTapService.USER_ID_PARAM_KEY]: getValue(user?.id),
      'CT App version': getValue(appConfig.appVersion),
      'CT OS version': getValue(ctOsVersion),
      'CT SDK Version': NOT_AVAILABLE,
      User_Country: getValue(country?.name),
      Source_Event: sourceEvent,
      'User ID': getValue(user?.id),
      ...event.params
    };

    if (!window.clevertap) {
      console.error('CleverTap JavaScript Library is not loaded');
      return;
    }

    if (appConfig.enableLogs.clevertap) {
      console.log('CleverTapService logEvent', event.name, eventParams);
    }

    parseParams(eventParams);
    window.clevertap.event.push(event.name, eventParams);
  }

  login(user, email) {
    if (!window.clevertap) {
      console.error('CleverTap JavaScript Library is not loaded');
      return;
    }

    const {
      session: { country, signupMethod, signupPlatform },
      subscriptions: { validEntitlements }
    } = store.getState();

    const userId = user?.id;

    const eventParams = {
      Site: {
        'User ID': userId,
        Email: email,
        Country: getValue(country?.name),
        Identity: getValue(userId),
        'Login Status': user ? USER_TYPE_CT.loggedIn : USER_TYPE_CT.loggedOut,
        Name: getValue(`${user?.firstName} ${user?.lastName}`),
        'Sign Up Date': getValue(formatMillisecondsToDate(user?.createDate, DATE_FORMAT.SHORT)),
        'Sign Up Method': getValue(signupMethod),
        'Sign Up Platform': getValue(signupPlatform),
        'Subscription date': getValue(
          validEntitlements?.length &&
            formatMillisecondsToDate(validEntitlements[0].purchaseDate, DATE_FORMAT.SHORT)
        ),
        'Subscription end date': getValue(
          validEntitlements?.length &&
            formatMillisecondsToDate(validEntitlements[0].endDate, DATE_FORMAT.SHORT)
        ),
        'Subscription Status': validEntitlements?.length
          ? SUBSCRIPTION_STATUS_CT.paid
          : SUBSCRIPTION_STATUS_CT.free
      }
    };

    if (appConfig.enableLogs.clevertap) {
      console.log('CleverTapService login', userId, email);
    }
    window.clevertap.onUserLogin.push(eventParams);
  }

  setProfile(payload) {
    if (!window.clevertap) {
      console.error('CleverTap JavaScript Library is not loaded');
      return;
    }
    if (appConfig.enableLogs.clevertap) {
      console.log('CleverTapService profile', payload);
    }

    parseParams(payload);
    window.clevertap.profile.push({ Site: payload });
  }

  logout() {
    if (!window.clevertap) {
      console.error('CleverTap JavaScript Library is not loaded');
      return;
    }
    if (appConfig.enableLogs.clevertap) {
      console.log('CleverTapService logout');
    }
    window.clevertap.logout();
  }
}

export default CleverTapService;
