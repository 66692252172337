import { createAction } from '@reduxjs/toolkit';

import createAsyncThunk from 'utils/createAsyncThunk';
import ReferralService from 'services/referralService';
import VideoService from 'services/videoService';
import parseError from 'utils/parseError';

export const getVideo = createAsyncThunk('videos/getVideo', async ({ assetId }) => {
  try {
    const { data } = await VideoService.getVideo({ id: assetId });

    return {
      ...data
    };
  } catch (response) {
    throw parseError(response);
  }
});

export const fetchEpisodeList = createAsyncThunk(
  'videos/fetchEpisodeList',
  async ({ seriesId }) => {
    try {
      const {
        data: { objects = [] }
      } = await VideoService.getEpisodeList({ id: seriesId });

      const episodes = objects
        .filter(({ metas: { EpisodeNumber } }) => EpisodeNumber)
        .sort(
          ({ metas: { EpisodeNumber: episodeA } }, { metas: { EpisodeNumber: episodeB } }) =>
            episodeA.value - episodeB.value
        );
      const serie = objects.find(({ metas: { EpisodeNumber } }) => !EpisodeNumber);

      if (!serie) {
        console.error("The series data wasn't not found");
      }
      return { episodes, serie };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const fetchShareLink = createAsyncThunk(
  'videos/fetchShareLink',
  async ({ video, type, isInsight }) => {
    try {
      const { data } = await ReferralService.getShareDeepLink({ video, type, isInsight });

      return {
        id: video.id,
        data: data.url
      };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const setAutoplay = createAction('videos/autoplay', ({ autoplay }) => ({
  payload: { autoplay }
}));

export const reset = createAction('videos/reset');

export const { fulfilled: getVideoFulfilled, reset: resetGetVideo } = getVideo;
export const { fulfilled: fetchEpisodeListFulfilled } = fetchEpisodeList;
export const { fulfilled: fetchShareLinkFulfilled } = fetchShareLink;
