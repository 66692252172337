import { setContent } from 'services/analytics/utils';
import { SERVICES } from 'services/analytics/constants';

class AnalyticEvent {
  constructor(name, params, hasContent = false, services = [SERVICES.GOOGLE_ANALYTICS]) {
    if (hasContent) setContent(params);

    this.name = name;
    this.params = params;
    this.services = services;
  }
}

export default AnalyticEvent;
