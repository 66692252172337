import kalturaClient from 'httpClient/kaltura';
import referralClient from 'httpClient/referral';

import { OBJECT_TYPE, PRODUCT_TYPES } from './constants';
import { createExtraParameters } from './helpers';

class PaymentService {
  static getGateways() {
    return kalturaClient.post('/householdpaymentgateway/action/list');
  }

  static getMethods() {
    return kalturaClient.post('/householdpaymentmethod/action/list');
  }

  static getConfiguration({ gatewayId }) {
    const body = {
      intent: 'get_configuration',
      paymentGatewayId: gatewayId,
      extraParameters: []
    };

    return kalturaClient.post('/householdpaymentgateway/action/invoke', body);
  }

  static createCustomer({ gatewayId, user, stripeToken }) {
    const body = {
      intent: 'create_customer',
      paymentGatewayId: gatewayId,
      extraParameters: createExtraParameters({ ...user, stripeToken, paymentMethod: 'Credit Card' })
    };

    return kalturaClient.post('/householdpaymentgateway/action/invoke', body);
  }

  static addCard({ gatewayId, user, stripeToken }) {
    const body = {
      intent: 'add_card',
      paymentGatewayId: gatewayId,
      extraParameters: createExtraParameters({ ...user, stripeToken, paymentMethod: 'Credit Card' })
    };

    return kalturaClient.post('/householdpaymentgateway/action/invoke', body);
  }

  static purchase({ paymentMethodId, price, productId, gatewayId, currency, promocode }) {
    const body = {
      purchase: {
        adapterData: '',
        contentId: 0,
        objectType: OBJECT_TYPE.purchase,
        productType: PRODUCT_TYPES.SUBSCRIPTION,
        coupon: promocode,
        currency,
        paymentGatewayId: gatewayId,
        paymentMethodId,
        productId,
        price
      }
    };

    return kalturaClient.post('/transaction/action/purchase', body);
  }

  static removeCard({ paymentMethodId, paymentGatewayId }) {
    const body = {
      paymentGatewayId,
      paymentMethodId
    };

    return kalturaClient.post('/householdpaymentmethod/action/remove', body);
  }

  static getPrice({ productId, promocode }) {
    const body = {
      filter: {
        objectType: OBJECT_TYPE.productPrice,
        isLowest: false,
        subscriptionIdIn: productId,
        couponCodeEqual: promocode
      }
    };

    return kalturaClient.post('/productprice/action/list', body);
  }

  static validateCoupon({ id, code }) {
    const body = {
      id,
      code
    };

    return kalturaClient.post('/subscription/action/validateCoupon', body);
  }

  static validateRedemptionCoupon({
    udid,
    ksToken,
    userId,
    code: couponCode,
    id: subscriptionSelectedId
  }) {
    const body = {
      udid,
      ksToken,
      userId,
      couponCode,
      subscriptionSelectedId
    };

    return referralClient.post('/subscriber/redeem', body);
  }
}

export default PaymentService;
