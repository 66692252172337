import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useStatus, useSession } from 'hooks';
import { FULFILLED, REJECTED, PENDING } from 'constants/actionStatusConstants';

import {
  fetchContinueWatching,
  updateVideoPosition,
  reset
} from 'state/actions/contineWatchingActions';

export default () => {
  const { status } = useStatus(fetchContinueWatching);
  const { user } = useSession();
  const categories = useSelector(state => state.categories.home);
  const continueWatching = useSelector(state => state.continueWatching.data);
  const dispatch = useDispatch();
  const updateVideo = (assetId, position) => dispatch(updateVideoPosition({ assetId, position }));

  useEffect(() => {
    if (user && !continueWatching.length) {
      dispatch(fetchContinueWatching());
    }
  }, [user, dispatch, categories, continueWatching]);

  return {
    continueWatching,
    fetchContinueWatching: () => dispatch(fetchContinueWatching()),
    updateVideoPosition: updateVideo,
    reset: () => dispatch(reset()),
    error: status === REJECTED,
    success: status === FULFILLED,
    loading: status === PENDING
  };
};
