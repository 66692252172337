import React from 'react';
import { node, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useMediaQueries from 'hooks/useMediaQueries';

import { ReactComponent as LogoIcon } from 'assets/icons/logo-light-bg.svg';

import Header from 'components/common/Header';

import routes from 'constants/routesPaths';
import { UI_THEMES } from 'constants/styles';

const AuthLayout = ({ children, className = '' }) => {
  const { isDesktop } = useMediaQueries();

  return (
    <>
      {!isDesktop && <Header theme={UI_THEMES.light} skippable />}
      <div className="auth-layout d-flex flex-column">
        <div className="auth-layout__logo d-none d-sm-block">
          <LogoIcon data-testid="authBrandLogo" />
        </div>
        <div className="flex-1 mb-sm-5 d-sm-flex flex-column align-items-center">
          <main className={className}>{children}</main>
        </div>
        <div className="auth-layout__links">
          <Link to={routes.termsOfUse} target="_blank">
            <FormattedMessage id="common.termsOfUse" />
          </Link>
          <FormattedMessage id="common.and" />
          <Link to={routes.privacyPolicy} target="_blank">
            <FormattedMessage id="common.privacyPolicy" />
          </Link>
        </div>
      </div>
    </>
  );
};

AuthLayout.propTypes = {
  children: node.isRequired,
  className: string
};

export default AuthLayout;
