import { useMediaQuery } from 'react-responsive';
import { md, lg } from 'styles/_variables.scss';

const useMediaQueries = () => {
  const isDesktop = useMediaQuery({ minWidth: lg });
  const _isTablet = useMediaQuery({ minWidth: md });
  // TODO: In case they are needed in the future:
  // const isPortrait = useMediaQuery({ orientation: 'portrait' });
  // const isRetina = useMediaQuery({ minResolution: '2dppx' });

  const isTablet = !isDesktop && _isTablet;
  const isMobile = !isDesktop && !isTablet;
  const isMobileOrTablet = !isDesktop;

  return {
    isDesktop,
    isTablet,
    isMobile,
    isMobileOrTablet
  };
};

export default useMediaQueries;
