import React from 'react';
import { string, node, arrayOf, shape, func } from 'prop-types';
import { useIntl } from 'react-intl';

import Dropdown from './Dropdown';
import LinkIcon from './LinkIcon';
import CallToAction from './CallToAction';

export const SidebarItemTypes = {
  dropdown: 'Dropdown',
  callToAction: 'CallToAction',
  linkIcon: 'LinkIcon'
};

const Item = ({ item, onToggle }) => {
  const { formatMessage } = useIntl();

  const props = {
    label: formatMessage({ id: item.labelId }),
    icon: item.icon,
    onToggle
  };

  switch (item.type) {
    case SidebarItemTypes.dropdown:
      return (
        <Dropdown
          {...props}
          items={item.items.map(i => ({
            label: formatMessage({ id: i.labelId }),
            url: i.url
          }))}
        />
      );
    case SidebarItemTypes.callToAction:
      return <CallToAction onClick={item.onClick} {...props} />;
    case SidebarItemTypes.linkIcon:
    default:
      return <LinkIcon url={item.url} {...props} />;
  }
};

Item.propTypes = {
  item: shape({
    type: string.isRequired,
    labelId: string.isRequired,
    icon: node.isRequired,
    url: string,
    items: arrayOf(
      shape({
        label: string.isRequired,
        url: string.isRequired
      })
    )
  }),
  onToggle: func.isRequired
};

export default Item;
