import { string } from 'prop-types';
import React from 'react';

import { grey } from 'styles/_variables.scss';

const PrivacyIcon = ({ color = grey, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.363 11.407a5.144 5.144 0 01-.253.692c-.34.626-.703 1.23-1.099 1.846a1.506 1.506 0 01-2.198.582l-.747-.417a.527.527 0 00-.835.472v.901A1.482 1.482 0 019.769 17H7.418a1.506 1.506 0 01-1.385-1.517v-.857c0-.56-.385-.769-.89-.505l-.703.384a1.495 1.495 0 01-2.1-.56 26.697 26.697 0 01-1.098-1.956 1.483 1.483 0 01.55-2.077c.219-.143.46-.264.692-.418a.55.55 0 000-1.01c-.231-.143-.473-.264-.693-.407a1.495 1.495 0 01-.571-2.099c.373-.67.758-1.34 1.154-1.989a1.506 1.506 0 012.033-.473c.263.143.505.32.769.451A.528.528 0 006 3.505v-.89a1.483 1.483 0 011.495-1.593c.783-.03 1.564-.03 2.34 0A1.472 1.472 0 0111.32 2.56v.858a.528.528 0 00.846.494c.253-.12.494-.275.747-.417a1.495 1.495 0 012.099.549c.396.66.78 1.319 1.154 2a1.495 1.495 0 01-.56 2.066c-.253.154-.517.297-.77.461a.517.517 0 000 .924c.264.175.55.318.813.483a1.572 1.572 0 01.715 1.429v0zM11.758 9.78a3.186 3.186 0 10-3.846 2.33"
    />
  </svg>
);

PrivacyIcon.propTypes = {
  color: string
};

export default PrivacyIcon;
