import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import union from 'lodash/union';

import CategoryService from 'services/CategoryService';
import { CATEGORY_PAGE_SIZE } from 'services/constants';
import { fetchRecommendedForYou, setRelatedVideo } from 'state/actions/recommendedForYouActions';
import parseError from 'utils/parseError';

export const fetchContinueWatching = createAsyncThunk(
  'categories/fetchContinueWatching',
  async (_, store) => {
    const {
      continueWatching: { totalCount, data }
    } = store.getState();
    try {
      if (totalCount === 0 || totalCount > data.length) {
        const {
          data: { objects: videoData = [], totalCount }
        } = await CategoryService.getContinueWatching({
          pageIndex: data.length / CATEGORY_PAGE_SIZE + 1
        });
        const items = videoData.map(({ assetId }) => assetId);
        if (items.length) {
          const {
            data: { objects: videoAssets = [] }
          } = await CategoryService.getAssets({ items });

          const currentPage = data.length / CATEGORY_PAGE_SIZE + 1;

          if (currentPage === 1) {
            const relatedVideoId = videoAssets[0].id;
            const typeIds = union(videoAssets.map(video => video.type));

            store.dispatch(setRelatedVideo({ relatedVideoId, typeIds }));
            store.dispatch(fetchRecommendedForYou());
          }

          return { assets: videoAssets, data: videoData, totalCount };
        }
      }
      return { assets: [], data: [], totalCount };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const updateVideoPosition = createAction(
  'continueWatching/setRelatedVideo',
  ({ assetId, position }) => ({
    payload: {
      assetId,
      position
    }
  })
);

export const reset = createAction('continueWatching/reset');

export const { fulfilled: fetchContinueWatchingFulfilled } = fetchContinueWatching;
