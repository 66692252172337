import { createSlice } from '@reduxjs/toolkit';
import {
  fetchContinueWatchingFulfilled,
  updateVideoPosition,
  reset
} from 'state/actions/contineWatchingActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  data: [],
  totalCount: 0
};

const parseContinueWatching = ({ data, assets }) => {
  const continueWatching = [];
  const userHistory =
    data.length === assets.length
      ? data.map(video => ({ ...video, ...assets.find(({ id }) => video.assetId === id) }))
      : [];

  userHistory.forEach(repeatedVideo => {
    !continueWatching.some(
      video => repeatedVideo?.metas?.SeriesID?.value === video?.metas?.SeriesID?.value
    ) && continueWatching.push(repeatedVideo);
  });

  return continueWatching.filter(({ finishedWatching }) => !finishedWatching);
};

const continueWatchingSlice = createSlice({
  name: 'continueWatching',
  initialState,
  extraReducers: {
    [fetchContinueWatchingFulfilled]: (state, { payload }) => {
      if (state.totalCount === 0 || state.totalCount < state.data.length) {
        state.data.push(...parseContinueWatching(payload));
        state.totalCount = payload.totalCount || state.totalCount;
      }
    },
    [updateVideoPosition]: (state, { payload }) => {
      const index = state.data.findIndex(video => video.assetId === Number(payload.assetId));
      state.data[index].position = payload.position;

      if (index !== 0) {
        state.data.unshift(state.data[index]);
        state.data.splice(index + 1, 1);
      }
    },
    [logoutFulfilled]: () => initialState,
    [reset]: () => initialState
  }
});

export default continueWatchingSlice.reducer;
