import { COUNTRIES } from 'constants/constants';
import { TIME_FORMATS } from 'constants/time';

import useSession from './useSession';

export default () => {
  const { country } = useSession();
  const { countryCode } = country || {};

  const isIndia = countryCode === COUNTRIES.INDIA;

  const countryDateTimeFormat = {
    shortDate: isIndia ? TIME_FORMATS.DD_MM_YYYY : TIME_FORMATS.MM_DD_YYYY,
    mediumDate: TIME_FORMATS.DD_MMM_YY
  };

  return countryDateTimeFormat;
};
