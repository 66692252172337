import { ENVIRONMENTS } from 'constants/constants';
import { PACKAGE_TYPES } from 'constants/subscriptions';

export const OBJECT_TYPE = {
  assetFilter: 'KalturaSearchAssetFilter',
  countryFilter: 'KalturaCountryFilter',
  channelFilter: 'KalturaChannelFilter',
  dynamicOrder: 'KalturaDynamicOrderBy',
  entitlement: 'KalturaEntitlementFilter',
  houseHold: 'KalturaHousehold',
  houseHoldDevice: 'KalturaHouseholdDevice',
  pager: 'KalturaFilterPager',
  purchase: 'KalturaPurchase',
  relatedFilter: 'KalturaRelatedFilter',
  searchAsset: 'KalturaSearchAssetListFilter',
  subscriptionFilter: 'KalturaSubscriptionFilter',
  stringValue: 'KalturaStringValue',
  user: 'KalturaOTTUser',
  personalList: 'KalturaPersonalList',
  productPrice: 'KalturaProductPriceFilter'
};

export const ASSET_REFERENCE_TYPE = {
  MEDIA: 'media'
};

export const CATEGORY_PAGE_SIZE = 12;

export const EPISODES_PAGE_SIZE = 100;

export const STRIPE_GATEWAY = 'Stripe';

export const PRODUCT_TYPES = {
  SUBSCRIPTION: 'subscription'
};

export const SUBSCRIPTION_ASSET_TYPE = process.env.API_ENV === ENVIRONMENTS.PRODUCTION ? 763 : 682;

export const USA_PLANS = {
  [PACKAGE_TYPES.PREMIUM_ANNUAL]: 'Annual all access pass (Stripe)',
  [PACKAGE_TYPES.PREMIUM_MONTHLY]: 'Monthly all access pass (Stripe)'
};

export const INDIA_PLANS = {
  [PACKAGE_TYPES.PREMIUM_ANNUAL]: 'India Annual all access pass(Stripe)',
  [PACKAGE_TYPES.PREMIUM_MONTHLY]: 'India Monthly subscription(Stripe)'
};

export const DEVICE_HOUSEHOLD_BRAND_ID = 212;

export const ADMIN_KS = {
  id: process.env.ADMIN_ID,
  token: process.env.ADMIN_TOKEN,
  hashType: 'SHA256'
};

export const DEVICE_NAME = `${navigator.platform}-${navigator.appVersion}`;

export const SVOD = 'SVOD';
export const EXTERNAL = 'External';

export const VIEW = {
  desktop: 'desktop',
  mobile: 'mobile',
  tablet: 'tablet'
};

export const PAYMENT_METHODS = {
  CREDIT: 'credit',
  DEBIT: 'debit'
};

export const PAYMENT_CONFIGURATION_KEYS = {
  PAYMENT_METHOD_ID: 'PaymentMethodId',
  GATEWAY_ID: 'GatewayId',
  PUBLISHABLE_KEY: 'PublishableKey'
};
