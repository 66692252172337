import CleverTapService from './services/CleverTapService';
import FacebookService from './services/FacebookService';
import GoogleAnalyticsService from './services/GoogleAnalyticsService';

class AnalyticManager {
  constructor() {
    this.cleverTapService = new CleverTapService();
    this.facebookService = new FacebookService();
    this.googleAnalyticsService = new GoogleAnalyticsService();
    this.services = [this.googleAnalyticsService, this.cleverTapService, this.facebookService];
  }

  logEvent(event) {
    const services = this.services.filter(service => !!event.services.includes(service.id));

    services.forEach(service => service.logEvent(event));
  }

  // @TODO: remove this method when all pages keys will revealed in analytics doc
  logScreen(event) {
    if (event.params.previous_screen && event.params.screen_name) {
      this.logEvent(event);
    }
  }

  login({ user, email }) {
    this.cleverTapService.login(user, email);
  }

  logout() {
    this.cleverTapService.logout();
  }
}

const manager = new AnalyticManager();

export default manager;
