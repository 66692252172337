import { useCallback } from 'react';

export default (
  handleValueChange,
  handleFocus,
  handleBlur,
  values,
  errors,
  activeFields,
  touchedFields
) =>
  useCallback(
    fieldKey => ({
      value: values[fieldKey] || '',
      errors: errors[fieldKey],
      active: activeFields[fieldKey] || false,
      touched: touchedFields[fieldKey] || false,
      onChange: ({ target: { value } }, isInitialSetup) =>
        handleValueChange(fieldKey, value, isInitialSetup),
      onFocus: () => handleFocus(fieldKey),
      onBlur: () => handleBlur(fieldKey)
    }),
    [handleFocus, handleBlur, handleValueChange, values, errors, activeFields, touchedFields]
  );
