const routes = {
  home: '/',
  onboarding: '/onboarding',
  signIn: '/sign-in',
  signUp: '/sign-up',
  detail: '/detail',
  explore: '/explore',
  exploreId: '/explore/:id',
  genreId: '/explore/:categoryId/genre/:genreId',
  myInsights: '/my-insights',
  settings: '/settings',
  termsOfUse: '/terms',
  feedback: '/feedback',
  payment: '/payment',
  privacyPolicy: '/privacy-policy',
  forgotPassword: '/forgot-password',
  resetPassword: '/resetpassword',
  resetPasswordStg: '/reset-password', // TODO: remove when url changes on production emails
  subscriptions: '/subscriptions',
  consent: '/consent/techved',
  downloadApp: '/download-app',
  changePassword: '/change-password',
  etPrime: '/mlpetp',
  etPrimeOnboarding: '/mlpetp/onboarding',
  etPrimeSignUp: '/mlpetp/sign-up',
  etPrimeSignIn: '/mlpetp/sign-in',
  etPrimeForgotPassword: '/mlpetp/forgot-password',
  marketingLanding: '/mlp',
  marketingLandingOnboarding: '/mlp/onboarding',
  marketingLandingSignIn: '/mlp/sign-in',
  marketingLandingSignUpWithEmail: '/mlp/sign-up',
  marketingLandingForgotPassword: '/mlp/forgot-password',
  marketingLandingPayment: '/mlp/payment',
  marketingLandingWSJ: '/mlpwsj',
  marketingLandingWSJ2: '/mlpwsj2',
  marketingLandingWSJOnboarding: '/mlpwsj/onboarding',
  marketingLandingWSJSignIn: '/mlpwsj/sign-in',
  marketingLandingWSJSignUpWithEmail: '/mlpwsj/sign-up',
  marketingLandingWSJForgotPassword: '/mlpwsj/forgot-password',
  marketingLandingWSJPayment: '/mlpwsj/payment',
  marketingLandingValueProp: '/mlpvp',
  marketingLandingValuePropSignIn: '/mlpvp/sign-in',
  marketingLandingValuePropSignUpWithEmail: '/mlpvp/sign-up',
  marketingLandingValuePropForgotPassword: '/mlpvp/forgot-password',
  marketingLandingValuePropPlanDetails: '/mlpvp/plan-details'
};

export const stores = {
  android: 'https://play.google.com/store/apps/details?id=com.emeritus.mobile&hl=en&gl=US',
  ios: 'https://apps.apple.com/us/app/emeritus-online-learning/id1067238922'
};

export const redirections = {
  home: '/home',
  live: '/live'
};

export const UTM = {
  source: 'ei_course_listing',
  medium: 'website',
  campaign: 'ei_course_listing'
};

export const COURSES_URL = `https://emeritus.org/online-certificate-courses?utm_source=${UTM.source}&utm_medium=${UTM.medium}&utm_campaign=${UTM.campaign}`;

export const LIVE_URL = 'https://insightslive.emeritus.org/';

export default routes;
