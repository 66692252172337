import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getValidEntitlements, getExpiredEntitlements } from 'state/actions/subscriptionActions';

import { PENDING } from 'constants/actionStatusConstants';

import useStatus from './useStatus';
import useSubscription from './useSubscription';
import useSession from './useSession';

export default ({ getExpired = false } = {}) => {
  const [isLoadingEntitlement, setIsLoadingEntitlement] = useState(true);

  const dispatch = useDispatch();

  const { user } = useSession();
  const { validEntitlements, expiredEntitlements, isUserPremium } = useSubscription();
  const { status: validEntitlementsStatus } = useStatus(getValidEntitlements);
  const { status: expiredEntitlementsStatus } = useStatus(getExpiredEntitlements);

  const getValidEntitlementsRequest = useCallback(() => dispatch(getValidEntitlements()), [
    dispatch
  ]);
  const getExpiredEntitlementsRequest = useCallback(() => dispatch(getExpiredEntitlements()), [
    dispatch
  ]);

  useEffect(() => {
    if (user && !validEntitlements) {
      if (validEntitlementsStatus !== PENDING) getValidEntitlementsRequest();
    } else if (user && getExpired) {
      if (!expiredEntitlements) {
        if (expiredEntitlementsStatus !== PENDING) getExpiredEntitlementsRequest();
      } else {
        setIsLoadingEntitlement(false);
      }
    } else {
      setIsLoadingEntitlement(false);
    }
  }, [
    user,
    validEntitlements,
    expiredEntitlements,
    expiredEntitlementsStatus,
    validEntitlementsStatus,
    getExpiredEntitlementsRequest,
    getValidEntitlementsRequest,
    setIsLoadingEntitlement,
    getExpired
  ]);

  return { validEntitlements, expiredEntitlements, isLoadingEntitlement, isUserPremium };
};
