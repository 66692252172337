import { node, number, shape, string, array, arrayOf, bool, oneOf } from 'prop-types';
import { PACKAGE_TYPES } from 'constants/subscriptions';

export const TABS = shape({
  id: number.isRequired,
  labelId: string.isRequired,
  icon: node
});

export const videoCard = shape({
  name: string.isRequired,
  images: arrayOf(
    shape({
      url: string.isRequired
    })
  ),
  percentage: number
});

export const episode = shape({
  id: number.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  images: arrayOf(
    shape({
      url: string.isRequired
    }).isRequired
  ).isRequired,
  mediaFiles: arrayOf(
    shape({
      duration: number.isRequired,
      assetId: number.isRequired
    }).isRequired
  ).isRequired,
  metas: shape({
    EpisodeNumber: shape({
      value: number.isRequired
    }).isRequired,
    SeriesID: shape({
      value: string.isRequired
    }).isRequired
  }).isRequired
});

export const categoryShape = shape({
  id: number.isRequired,
  name: string.isRequired,
  channels: array.isRequired,
  browseId: number
});

export const entitlement = shape({
  id: number.isRequired,
  isRenewableForPurchase: bool.isRequired,
  currentDate: number.isRequired,
  endDate: number.isRequired,
  productId: string.isRequired,
  nextRenewalDate: number.isRequired
});

export const paymentDetails = shape({
  id: number.isRequired,
  price: shape({
    amount: number.isRequired,
    currency: string.isRequired,
    currencySign: string
  }).isRequired,
  type: oneOf(Object.values(PACKAGE_TYPES)).isRequired
});

export const sidebarDropdownItemShape = shape({
  label: string.isRequired,
  url: string.isRequired
});

export const redirectStateShape = shape({
  autoplay: bool
});

export const FormatMessageShape = shape({ id: string });

export const TitleWithImageTitleShape = shape({
  text: shape({ id: string.isRequired }).isRequired,
  icon: string
});

export const PremiumFeaturesArrayShape = arrayOf(FormatMessageShape);
