import appConfig from 'config';

import { SERVICES } from 'services/analytics/constants';
import { getGADeviceView } from 'services/analytics/utils';
import store from 'state/store/storeRegistry';

import AnalyticService from './AnalyticService';

class GoogleAnalyticsService extends AnalyticService {
  constructor() {
    super();
    this.dataLayer = window.dataLayer || [];
    this.id = SERVICES.GOOGLE_ANALYTICS;
  }

  logEvent(event) {
    const eventParams = {
      event: event.name,
      app_version: appConfig.appVersion,
      platform: event.params.platform || getGADeviceView(),
      anonymous_id: store.getState().session.udid,
      ...event.params
    };

    if (appConfig.enableLogs.googleAnalytics) {
      window.console.log('GA logEvent', event.name, eventParams);
    }

    this.dataLayer.push(eventParams);
  }
}

export default GoogleAnalyticsService;
