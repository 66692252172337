import axios from 'axios';

import { CONTENT_TYPE, APPLICATION_JSON, ALL, API_KEY } from 'httpClient/constants';

const client = axios.create({
  baseURL: process.env.REFERRAL_API,
  headers: {
    Accept: ALL,
    [CONTENT_TYPE]: APPLICATION_JSON,
    [API_KEY]: process.env.REFERRAL_API_KEY
  }
});

export default client;
