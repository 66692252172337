import React from 'react';
import { func } from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import Button from 'components/common/Button';
import { BUTTON_STYLES } from 'constants/styles';

const messages = defineMessages({
  close: { id: 'common.close' },
  logoutModalTitle: { id: 'logoutModal.cta' }
});

const LogoutModal = ({ closeModal, onLogout }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="row mt-3">
      <div className="col-6 pr-1">
        <Button styleType={BUTTON_STYLES.SECONDARY} className="w-100 py-2" onClick={closeModal}>
          <span className="text-b3">{formatMessage(messages.close)}</span>
        </Button>
      </div>
      <div className="col-6 pl-1">
        <Button className="w-100 h-100 py-2" onClick={onLogout}>
          <span className="text-b3">{formatMessage(messages.logoutModalTitle)}</span>
        </Button>
      </div>
    </div>
  );
};

LogoutModal.propTypes = {
  closeModal: func.isRequired,
  onLogout: func.isRequired
};

export default LogoutModal;
