import { createSlice } from '@reduxjs/toolkit';
import { VIEW } from 'services/constants';
import { setDeviceView, setUAInfo } from 'state/actions/infoActions';

const initialState = {
  browser: {
    name: null,
    version: null
  },
  cpu: {
    architecture: null
  },
  device: {
    model: null,
    type: null,
    vendor: null
  },
  deviceView: {
    view: VIEW.desktop
  },
  engine: {
    name: null,
    version: null
  },
  os: {
    name: null,
    version: null
  }
};

const infoReducer = createSlice({
  name: 'info',
  initialState,
  extraReducers: {
    [setUAInfo]: (state, { payload: { browser, cpu, device, engine, os } }) => {
      state.browser = browser;
      state.cpu = cpu;
      state.device = device;
      state.engine = engine;
      state.os = os;
    },
    [setDeviceView]: (state, { payload: { view } }) => {
      state.deviceView = { view };
    }
  }
});

export default infoReducer.reducer;
