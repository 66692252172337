import { useContext } from 'react';

import { modalContext } from 'components/common/Layout/Modal/ModalProvider';

export default () => {
  const {
    openModal,
    closeModal,
    visible,
    title,
    content,
    footer,
    showClose,
    backdrop,
    fromBottom
  } = useContext(modalContext);

  return {
    openModal,
    closeModal,
    visible,
    title,
    content,
    footer,
    showClose,
    backdrop,
    fromBottom
  };
};
