import { createAction } from '@reduxjs/toolkit';

import paymentService from 'services/paymentService';
import parseError from 'utils/parseError';
import createAsyncThunk from 'utils/createAsyncThunk';

import AnalyticManager from 'services/analytics/AnalyticManager';
import FBSubscriptionEvent from 'services/analytics/events/FBSubscriptionEvent';
import CTSubscriptionEvent from 'services/analytics/events/CTSubscriptionEvent';
import {
  CT_STATUS,
  SUBSCRIPTION_STATUS_CT,
  DATE_FORMAT,
  ET_PRIME,
  SUBSCRIPTION_STATUS,
  STATUS
} from 'services/analytics/constants';
import GASubscriptionEvent from 'services/analytics/events/GASubscriptionEvent';
import GAETPrimeEvent from 'services/analytics/events/GAETPrimeEvent';
import GAErrorEvent from 'services/analytics/events/GAErrorEvent';
import { getIntl } from 'locales/intl';

import { formatMillisecondsToDate, formatDate, getOneYearFromNow } from 'utils/dateHelpers';
import CTETPrimeEvent from 'services/analytics/events/CTETPrimeEvent';
import { PAYMENT_FAILED } from 'constants/errors';

export const getPaymentGateways = createAsyncThunk('payment/getGateways', async () => {
  try {
    const {
      data: { objects: gateways }
    } = await paymentService.getGateways();

    return { gateways };
  } catch (response) {
    throw parseError(response);
  }
});

export const getPaymentMethods = createAsyncThunk('payment/getMethods', async () => {
  try {
    const {
      data: { objects: methods }
    } = await paymentService.getMethods();

    return { methods };
  } catch (response) {
    throw parseError(response);
  }
});

export const getPaymentConfiguration = createAsyncThunk(
  'payment/getConfiguration',
  async (_, store) => {
    try {
      const { gatewayId } = store.getState().payment;

      const {
        data: { paymentGatewayConfiguration: configuration }
      } = await paymentService.getConfiguration({ gatewayId });

      return { configuration };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const createCustomer = createAsyncThunk(
  'payment/createCustomer',
  async ({ stripeToken }, { getState }) => {
    try {
      const {
        payment: { gatewayId },
        session: { user }
      } = getState();

      const { data } = await paymentService.createCustomer({ gatewayId, user, stripeToken });

      if (data.error) throw data;

      const { paymentGatewayConfiguration: configuration } = data;

      return { configuration };
    } catch (response) {
      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response.error.code,
          userMessage: response.error.message,
          errorMessage: response.error.message
        })
      );

      throw parseError(response);
    }
  }
);

export const addCard = createAsyncThunk(
  'payment/addCard',
  async ({ stripeToken }, { getState }) => {
    try {
      const {
        payment: { gatewayId, methods },
        session: { user }
      } = getState();

      const removePromises = methods.map(({ paymentGatewayId, id }) =>
        paymentService.removeCard({ paymentMethodId: id, paymentGatewayId })
      );

      await Promise.all(removePromises);

      const { data } = await paymentService.addCard({ gatewayId, user, stripeToken });

      if (data.error) throw data;

      const { paymentGatewayConfiguration: configuration } = data;

      return { configuration };
    } catch (response) {
      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response?.error?.code,
          userMessage: response?.error?.message,
          errorMessage: response?.error?.message
        })
      );

      throw parseError(response);
    }
  }
);

export const removeCard = createAsyncThunk('payment/removeCard', async (_, { getState }) => {
  try {
    const {
      payment: { gatewayId, paymentMethodId }
    } = getState();

    const { data } = await paymentService.removeCard({
      paymentMethodId,
      paymentGatewayId: gatewayId
    });

    if (data.error) throw data;

    const { paymentGatewayConfiguration: configuration } = data;

    return { configuration };
  } catch (response) {
    AnalyticManager.logEvent(
      GAErrorEvent.errorReceived({
        code: response?.error?.code,
        userMessage: response?.error?.message,
        errorMessage: response?.error?.message
      })
    );

    throw parseError(response);
  }
});

export const purchase = createAsyncThunk(
  'payment/purchase',
  async ({ packageDetails, promocode, startDate, endDate, paymentMethod }, { getState }) => {
    try {
      const {
        payment: { gatewayId, paymentMethodId }
      } = getState();

      const {
        id,
        description,
        price: { amount, currency }
      } = packageDetails;

      const { data } = await paymentService.purchase({
        paymentMethodId,
        price: amount,
        productId: id,
        gatewayId,
        currency,
        promocode
      });

      if (data.error) throw data;

      const subscriptionParams = {
        status: CT_STATUS.success,
        startDate,
        endDate,
        moduleId: id,
        moduleName: description,
        packageType: packageDetails.type,
        currency,
        revenue: amount,
        promocode,
        paymentMethod,
        orderId: data.id
      };

      if (data.error || data.state === PAYMENT_FAILED) throw data;

      AnalyticManager.logEvent(FBSubscriptionEvent.purchase({ amount, currency }));
      AnalyticManager.logEvent(FBSubscriptionEvent.subscribe({ amount, currency }));
      AnalyticManager.cleverTapService.setProfile({
        'Subscription Status': SUBSCRIPTION_STATUS_CT.paid,
        'Subscription date': formatMillisecondsToDate(data.createdAt, DATE_FORMAT.SHORT),
        'Subscription end date': formatDate(subscriptionParams.endDate, DATE_FORMAT.SHORT),
        subscription_id: subscriptionParams.moduleId
      });
      AnalyticManager.logEvent(
        GASubscriptionEvent.subscriptionStatus({
          ...subscriptionParams,
          subscriptionStatus: SUBSCRIPTION_STATUS.paidUser,
          status: STATUS.success
        })
      );
      AnalyticManager.logEvent(GASubscriptionEvent.subscriptionSuccessful(subscriptionParams));
      AnalyticManager.logEvent(CTSubscriptionEvent.subscriptionSuccessful(subscriptionParams));

      return { data };
    } catch (res) {
      let response = res;
      if (res.state === PAYMENT_FAILED) {
        response = {
          error: {
            code: res.failReasonCode,
            message: res.paymentGatewayResponseId
          }
        };
      }

      const subscriptionParams = {
        endDate,
        startDate,
        packageType: packageDetails.type,
        promocode,
        revenue: packageDetails.price?.amount,
        subscriptionStatus: SUBSCRIPTION_STATUS.freeUser
      };
      AnalyticManager.logEvent(
        GASubscriptionEvent.subscriptionStatus({
          ...subscriptionParams,
          status: STATUS.fail
        })
      );
      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response.error.code,
          userMessage: response.error.message,
          errorMessage: response.error.message
        })
      );

      throw parseError(response);
    }
  }
);

export const getProductPrice = createAsyncThunk(
  'payment/getProductPrice',
  async ({ productId, promocode }) => {
    try {
      const {
        data: {
          objects: [productPrice]
        }
      } = await paymentService.getPrice({ productId, promocode });

      return { productPrice };
    } catch (response) {
      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response.error.code,
          userMessage: response.error.message,
          errorMessage: response.error.message
        })
      );

      throw parseError(response);
    }
  }
);

export const setProductPrice = createAction('payment/setProductPrice', ({ productPrice }) => ({
  payload: {
    productPrice
  }
}));

export const validateRedemptionCoupon = createAsyncThunk(
  'subscription/validateRedemptionCoupon',
  async ({ productId: id, code, flow = '' }, { getState }) => {
    try {
      const { udid, ks: ksToken, user } = getState().session;
      const { id: userId = '' } = user || {};

      if (!userId && flow === ET_PRIME.name) {
        const { coupon, productPrice } = getState().payments;
        return { coupon, productPrice };
      }

      if (!userId) {
        const {
          data: {
            objects: [productPrice]
          }
        } = await paymentService.getPrice({ productId: id, promocode: code });

        const invalidCouponCode = productPrice?.fullPrice?.amount === productPrice?.price?.amount;
        if (invalidCouponCode) {
          throw new Error(getIntl().formatMessage({ id: 'payment.promocode.notValid' }));
        }

        return { coupon: { couponCode: code }, flow, productPrice };
      }

      const { data } = await paymentService.validateRedemptionCoupon({
        udid,
        ksToken,
        userId,
        code,
        id
      });

      const { objects: [productPrice] = [] } = data;

      if (data.error) throw data;

      if (flow === ET_PRIME.name) {
        const startDate = new Date();
        const endDate = getOneYearFromNow();

        AnalyticManager.logEvent(
          GAETPrimeEvent.subscriptionStatus({
            promocode: code,
            startDate,
            endDate,
            status: STATUS.success,
            subscriptionStatus: SUBSCRIPTION_STATUS.paidUser,
            promocodeApplied: true
          })
        );
        AnalyticManager.logEvent(
          GAETPrimeEvent.subscriptionSuccessful({
            startDate,
            endDate
          })
        );
        AnalyticManager.logEvent(
          CTETPrimeEvent.subscriptionSuccessful({
            startDate,
            endDate,
            promocode: code
          })
        );
      }

      return { coupon: { couponCode: code, ...data }, flow, productPrice };
    } catch (response) {
      if (flow === ET_PRIME.name) {
        AnalyticManager.logEvent(
          GAETPrimeEvent.subscriptionStatus({
            startDate: new Date(),
            endDate: getOneYearFromNow(),
            promocode: code,
            promocodeApplied: false,
            status: STATUS.fail,
            subscriptionStatus: SUBSCRIPTION_STATUS.freeUser
          })
        );
      }

      if (response?.message) {
        throw parseError({
          error: response.message
        });
      }

      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response.error.code,
          userMessage: response.error.message,
          errorMessage: response.error.message
        })
      );

      // eslint-disable-next-line no-throw-literal
      throw {
        message: parseError(response),
        code: response.error.code
      };
    }
  }
);

export const { fulfilled: getGatewaysFulfilled } = getPaymentGateways;
export const { fulfilled: getMethodsFullfilled } = getPaymentMethods;
export const {
  fulfilled: getConfigurationFullfilled,
  reset: resetGetConfiguration
} = getPaymentConfiguration;
export const { fulfilled: createCustomerFullfilled, reset: resetCreateCustomer } = createCustomer;
export const {
  fulfilled: addCardFullfilled,
  reset: resetAddCard,
  rejected: addCardRejected
} = addCard;
export const { fulfilled: purchaseFullfilled, reset: resetPurchase } = purchase;
export const {
  fulfilled: getProductPriceFullfilled,
  reset: resetGetProductPrice
} = getProductPrice;
export const {
  fulfilled: validateRedemptionCouponFulfilled,
  reset: validateCouponReset
} = validateRedemptionCoupon;
