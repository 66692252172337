import {
  SCREENS,
  USER_TYPE,
  SUBSCRIPTION_STATUS,
  NOT_AVAILABLE,
  PLATFORM
} from 'services/analytics/constants';
import { getSubscriptionStatus, getUser, getUserType, getValue } from 'services/analytics/utils';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  exploreMoreClick: 'explore_more_click',
  exploreVaultClick: 'explore_vault_click',
  signup: 'sign_up',
  startLearning: 'start_learning'
};

class GAMobileLandingEvent extends AnalyticEvent {
  static exploreMoreClick() {
    const user = getUser();
    return new GAMobileLandingEvent(EVENTS.exploreMoreClick, {
      screen_name: SCREENS.mobileLanding,
      platform: PLATFORM.mobile,
      user_type: getUserType(),
      previous_screen: NOT_AVAILABLE,
      subscription_status: getSubscriptionStatus(),
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId)
    });
  }

  static exploreVaultClick() {
    const user = getUser();
    return new GAMobileLandingEvent(EVENTS.exploreVaultClick, {
      screen_name: SCREENS.mobileLanding,
      platform: PLATFORM.mobile,
      user_type: getUserType(),
      previous_screen: NOT_AVAILABLE,
      subscription_status: getSubscriptionStatus(),
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId)
    });
  }

  static signUp() {
    return new GAMobileLandingEvent(EVENTS.signup, {
      screen_name: SCREENS.mobileLanding,
      platform: PLATFORM.mobile,
      user_type: USER_TYPE.guest,
      previous_screen: NOT_AVAILABLE,
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      registration_id: NOT_AVAILABLE,
      subscription_id: NOT_AVAILABLE
    });
  }

  static startLearning({ scroll }) {
    const user = getUser();
    return new GAMobileLandingEvent(EVENTS.startLearning, {
      screen_name: SCREENS.mobileLanding,
      platform: PLATFORM.mobile,
      user_type: getUserType(),
      previous_screen: NOT_AVAILABLE,
      subscription_status: getSubscriptionStatus(),
      registration_id: getValue(user?.id),
      subscription_id: getValue(user?.purchaseId),
      scroll
    });
  }
}

export default GAMobileLandingEvent;
