import noInternetErrorHandler from 'httpClient/noInternetErrorHandler';
import { NETWORK_ERROR } from 'constants/errors';

export default (store, client) => {
  client.interceptors.request.use(config => config);

  client.interceptors.response.use(
    async response => response,
    error => {
      if (error.message === NETWORK_ERROR) {
        noInternetErrorHandler(error, store);
      }

      return Promise.reject(error);
    }
  );
};
