import { createSlice } from '@reduxjs/toolkit';

import { changeRoute } from 'state/actions/routingActions';

const initialState = {
  currentPath: null,
  previousPath: null
};

const routingReducer = createSlice({
  name: 'routing',
  initialState,
  extraReducers: {
    [changeRoute]: (state, { payload: { currentPath } }) => {
      state.previousPath = state.currentPath;
      state.currentPath = currentPath;
    }
  }
});

export default routingReducer.reducer;
