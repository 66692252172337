import store from 'state/store/storeRegistry';

import { USER_TYPE, SUBSCRIPTION_STATUS, NOT_AVAILABLE } from 'services/analytics/constants';
import ROUTES_MAP from 'services/analytics/routes';
import { getValue, getCurrentScreen } from 'services/analytics/utils';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  firstTimeWebVisit: 'first_web_visit',
  screenViewManual: 'screenview_manual'
};

class GAScreenEvent extends AnalyticEvent {
  static firstTimeWebVisit() {
    return new GAScreenEvent(EVENTS.firstTimeWebVisit, {
      screen_name: getCurrentScreen(),
      user_type: USER_TYPE.guest,
      previous_screen: NOT_AVAILABLE,
      subscription_status: SUBSCRIPTION_STATUS.freeUser,
      registration_id: NOT_AVAILABLE,
      anonymous_id: NOT_AVAILABLE,
      subscription_id: NOT_AVAILABLE
    });
  }

  static screen({ currentPath = '', previousPath = '' }) {
    const screenName = ROUTES_MAP[currentPath];
    const previousScreen = ROUTES_MAP[previousPath];

    const { user, udid } = store.getState().session;

    return new GAScreenEvent(
      EVENTS.screenViewManual,
      {
        screen_name: screenName,
        user_type: user ? USER_TYPE.loggedIn : USER_TYPE.guest,
        previous_screen: getValue(previousScreen),
        subscription_status: user?.purchaseId
          ? SUBSCRIPTION_STATUS.paidUser
          : SUBSCRIPTION_STATUS.freeUser,
        registration_id: getValue(user?.id),
        anonymous_id: udid,
        subscription_id: getValue(user?.purchaseId)
      },
      false
    );
  }
}

export default GAScreenEvent;
