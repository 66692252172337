import store from 'state/store/storeRegistry';
import { formatDate } from 'utils/dateHelpers';

import { PROMOTION_CODE_APPLIED, DATE_FORMAT } from '../constants';
import { getValue, getCurrency, getSubscriptions } from '../utils';
import CTAnalyticEvent from './CTAnalyticEvent';

const EVENTS = {
  subscribeNow: 'Subscribe_Now',
  subscriptionSuccessful: 'Subscription_Successful'
};

class CTSubscriptionEvent extends CTAnalyticEvent {
  static subscribeNow({ moduleId, moduleName, currency, revenue, registrationId }) {
    const eventParams = {
      Module_ID: getValue(moduleId),
      Module_Name: getValue(moduleName),
      Currency: getValue(currency),
      Revenue: getValue(revenue),
      Registration_ID: getValue(registrationId)
    };
    return new CTSubscriptionEvent(EVENTS.subscribeNow, eventParams, true);
  }

  static subscriptionSuccessful({
    status,
    startDate,
    endDate,
    moduleId,
    moduleName,
    currency,
    revenue,
    registrationId,
    promocode,
    packageType
  }) {
    const eventParams = {
      Status: getValue(status),
      'Start Date': getValue(
        startDate instanceof Date ? formatDate(startDate, DATE_FORMAT.SHORT) : startDate
      ),
      'End Date': getValue(
        endDate instanceof Date ? formatDate(endDate, DATE_FORMAT.SHORT) : endDate
      ),
      Module_ID: getValue(moduleId || getSubscriptions()[packageType].ID),
      Module_Name: getValue(moduleName || getSubscriptions()[packageType].NAME),
      Currency: getValue(currency || getCurrency()),
      Revenue: getValue(revenue),
      Registration_ID: registrationId || getValue(store?.getState()?.session?.user?.id),
      promocode_applied: promocode ? PROMOTION_CODE_APPLIED.yes : PROMOTION_CODE_APPLIED.no,
      promocode: getValue(promocode)
    };
    return new CTSubscriptionEvent(EVENTS.subscriptionSuccessful, eventParams);
  }
}

export default CTSubscriptionEvent;
