import { useSelector } from 'react-redux';
import { PENDING, FULFILLED, REJECTED } from 'constants/actionStatusConstants';

/**
 * useStatus hook
 *
 * @param {string} action Prefix for the action names
 *
 * @returns {object} Object with status and error keys
 *
 * @example
 * const { status, error } = useStatus(login)
 */

export default action =>
  useSelector(({ statusReducer }) => {
    const { status, error, code } = statusReducer[(action?.typePrefix)] || {};
    return {
      status,
      error,
      code,
      loading: status === PENDING,
      success: status === FULFILLED,
      rejected: status === REJECTED
    };
  });
