import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import { setDeviceView, setUAInfo } from 'state/actions/infoActions';
import { useMediaQueries } from 'hooks';
import { VIEW } from 'services/constants';

const useSetDeviceView = () => {
  const dispatch = useDispatch();
  const { isDesktop, isTablet, isMobile } = useMediaQueries();

  useEffect(() => {
    const { browser, cpu, device, engine, os } = new UAParser().getResult();
    dispatch(
      setUAInfo({
        browser,
        cpu,
        device,
        engine,
        os
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let view;
    if (isDesktop) {
      view = VIEW.desktop;
    } else if (isTablet) {
      view = VIEW.tablet;
    } else {
      view = VIEW.mobile;
    }
    dispatch(setDeviceView({ view }));
  }, [dispatch, isDesktop, isTablet, isMobile]);
};

export default useSetDeviceView;
