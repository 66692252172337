import kalturaClient from 'httpClient/kaltura';
import { OBJECT_TYPE, CATEGORY_PAGE_SIZE } from './constants';

const APPLICATION_NAME = 'com.kaltura.em.web';
const CLIENT_VERSION = '0.0.1';
const PLATFORM = 'Other';
const STATUS_EQUAL = 'progress';

class CategoryService {
  static getRootId() {
    return kalturaClient.get('/configurations/action/serveByDevice', {
      params: {
        applicationName: APPLICATION_NAME,
        clientVersion: CLIENT_VERSION,
        platform: PLATFORM,
        tag: '',
        udid: '""'
      }
    });
  }

  static getAll({ categoryItemId }) {
    return kalturaClient.get('/categorytree/action/get', {
      params: {
        categoryItemId
      }
    });
  }

  static getContinueWatching({ pageIndex }) {
    return kalturaClient.post('/assethistory/action/list', {
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageSize: CATEGORY_PAGE_SIZE,
        statusEqual: STATUS_EQUAL,
        pageIndex
      }
    });
  }

  static getRecommendedForYou({ relatedVideoId, typeIds, page }) {
    return kalturaClient.post('/asset/action/list', {
      filter: {
        objectType: OBJECT_TYPE.relatedFilter,
        typeIn: typeIds.join(),
        idEqual: String(relatedVideoId)
      },
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageIndex: page,
        pageSize: CATEGORY_PAGE_SIZE
      }
    });
  }

  static getAssets({ items }) {
    return kalturaClient.post('/asset/action/list', {
      filter: {
        objectType: OBJECT_TYPE.assetFilter,
        asset_type: 'media',
        kSql: `(and media_id: '${items.join(',')}')`,
        orderBy: 'START_DATE_ASC'
      },
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageIndex: 1
      }
    });
  }

  static getChannel({ id, page }) {
    return kalturaClient.post('/asset/action/list', {
      filter: {
        objectType: OBJECT_TYPE.channelFilter,
        idEqual: id
      },
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageIndex: page,
        pageSize: CATEGORY_PAGE_SIZE
      }
    });
  }
}

export default CategoryService;
