import {
  getCurrency,
  getSubscriptions,
  getSubscriptionStatus,
  getValue,
  getPaymentMethod,
  getCurrentScreen,
  getPreviousScreen
} from 'services/analytics/utils';
import { SCREENS, USER_TYPE, SERVICES, PROMOTION_CODE_APPLIED_GA } from '../constants';
import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  clearPromocode: 'clear_promocode',
  hidePromocode: 'hide_promocode',
  payClick: 'pay_click'
};

class GAPaymentEvent extends AnalyticEvent {
  constructor(name, params = {}, hasContent = true, services = [SERVICES.GOOGLE_ANALYTICS]) {
    Object.assign(params, {
      screen_name: params.screen_name || SCREENS.payment,
      user_type: USER_TYPE.loggedIn,
      currency: getCurrency(),
      subscription_status: getSubscriptionStatus()
    });

    super(name, params, hasContent, services);
  }

  static clearPromocode({ promocode, price, type }) {
    return new GAPaymentEvent(EVENTS.clearPromocode, {
      revenue_ep: getValue(price),
      promocode: getValue(promocode),
      module_id: getSubscriptions()[type].ID,
      module_name: getSubscriptions()[type].NAME,
      screen_name:
        getCurrentScreen() === SCREENS.marketingLanding
          ? SCREENS.marketingLanding
          : SCREENS.payment,
      previous_screen:
        getPreviousScreen() === SCREENS.detail ? SCREENS.upsellScreen : getPreviousScreen()
    });
  }

  static hidePromocode({ promocode, price, type }) {
    return new GAPaymentEvent(EVENTS.hidePromocode, {
      revenue_ep: getValue(price),
      promocode: getValue(promocode),
      promocode_applied: PROMOTION_CODE_APPLIED_GA.no,
      module_id: getSubscriptions()[type].ID,
      module_name: getSubscriptions()[type].NAME,
      previous_screen:
        getPreviousScreen() === SCREENS.detail ? SCREENS.upsellScreen : getPreviousScreen()
    });
  }

  static payClick({ promocode, revenue, funding, packageType }) {
    let previousScreen = getPreviousScreen();
    if (previousScreen === SCREENS.detail) {
      previousScreen = SCREENS.upsellScreen;
    } else if (getCurrentScreen() === SCREENS.marketingLanding) {
      previousScreen = SCREENS.subscription;
    }

    return new GAPaymentEvent(EVENTS.payClick, {
      revenue_ep: getValue(revenue),
      promocode: getValue(promocode),
      promocode_applied: promocode ? PROMOTION_CODE_APPLIED_GA.yes : PROMOTION_CODE_APPLIED_GA.no,
      payment_method: getPaymentMethod(funding),
      module_id: getSubscriptions()[packageType].ID,
      module_name: getSubscriptions()[packageType].NAME,
      previous_screen: previousScreen
    });
  }
}

export default GAPaymentEvent;
