import kalturaClient from 'httpClient/kaltura';

import { OBJECT_TYPE, STRIPE_GATEWAY, SUBSCRIPTION_ASSET_TYPE } from './constants';

class SubscriptionService {
  static getEntitlement({ isExpired, ks }) {
    const body = {
      filter: {
        objectType: OBJECT_TYPE.entitlement,
        orderBy: 'PURCHASE_DATE_ASC',
        entitlementTypeEqual: 'subscription',
        entityReferenceEqual: 'household',
        isExpiredEqual: isExpired
      },
      pager: {
        pageSize: 10,
        pageIndex: 1
      },
      ks
    };

    return kalturaClient.post('/entitlement/action/list', body);
  }

  static getStripePackage() {
    const body = {
      filter: {
        objectType: OBJECT_TYPE.searchAsset,
        kSql: `(and (and asset_type = '${SUBSCRIPTION_ASSET_TYPE}') (and PaymentGatewayName = ${STRIPE_GATEWAY}))`
      },
      pager: {
        pageSize: 50,
        pageIndex: 1
      }
    };

    return kalturaClient.post('/asset/action/list', body);
  }

  static getSubscriptionDetail({ id }) {
    const body = {
      filter: {
        objectType: OBJECT_TYPE.subscriptionFilter,
        subscriptionIdIn: id
      }
    };

    return kalturaClient.post('/subscription/action/list', body);
  }

  static cancelRenewal({ id }) {
    const body = {
      subscriptionId: id
    };

    return kalturaClient.post('/entitlement/action/cancelRenewal', body);
  }
}

export default SubscriptionService;
