import merge from 'lodash/merge';

const AUTH_ENDPOINTS = [
  '/ottuser/action/login',
  '/ottuser/action/register',
  '/ottUser/action/anonymousLogin',
  '/appToken/action/add',
  '/household/action/add',
  '/appToken/action/startSession',
  '/householddevice/action/add',
  '/householddevice/action/delete',
  '/householddevice/action/list'
];

const KS_ENDPOINTS = ['/entitlement/action/list', '/country/action/list'];

export default (config, store) => {
  if (config.method === 'post') {
    config.data = config.data ? merge(config.data, config.params) : config.params;
    config.params = {};
  }

  if (!AUTH_ENDPOINTS.includes(config.url)) {
    const { ks } = store.getState().session;

    if (config.method === 'get') {
      config.params.ks = ks;
    } else if (!KS_ENDPOINTS.includes(config.url) || !config.data.ks) {
      config.data.ks = ks;
    }
  }

  return config;
};
