import React from 'react';
import { string, node, func } from 'prop-types';
import { Link } from 'react-router-dom';

const LinkIcon = ({ label, url, icon, onToggle }) => (
  <Link to={url} onClick={onToggle} className="d-flex mx-4 py-3">
    <img src={icon} alt={label} />
    <div className="ml-2">{label}</div>
  </Link>
);

LinkIcon.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
  icon: node.isRequired,
  onToggle: func.isRequired
};

export default LinkIcon;
