import React, { useState } from 'react';
import { string, node, func, arrayOf } from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { sidebarDropdownItemShape } from 'constants/shapes';

const Dropdown = ({ label, icon, items, onToggle }) => {
  const [dropdownToggled, setDropdownToggled] = useState(false);
  const dropdownClassName = cn('sidebar-dropdown bg-white d-none', {
    'd-block': dropdownToggled
  });

  const dropdownItemClassName = cn('d-flex justify-content-between align-items-center mx-4 py-3', {
    'border-bottom': !dropdownToggled
  });

  const toggleDropdown = () => setDropdownToggled(!dropdownToggled);

  return (
    <>
      <div className={dropdownItemClassName} onClick={toggleDropdown}>
        <div className="d-flex">
          <img src={icon} alt={label} />
          <div className="ml-2">{label}</div>
        </div>
        <ArrowDownIcon />
      </div>

      <div className={dropdownClassName}>
        {items.map((item, i) => (
          <Link key={i} to={item.url} className="d-block mx-4 py-3" onClick={onToggle}>
            {item.label}
          </Link>
        ))}
      </div>
    </>
  );
};

Dropdown.propTypes = {
  label: string.isRequired,
  icon: node.isRequired,
  items: arrayOf(sidebarDropdownItemShape).isRequired,
  onToggle: func.isRequired
};

export default Dropdown;
