import React, { useCallback } from 'react';
import { node, func } from 'prop-types';
import { Route } from 'react-router-dom';

import Layout from 'components/common/Layout';
import NoInternet from 'components/common/NoInternet';
import PrivateRoute from './PrivateRoute';

const RouteFromPath = ({ component, ...route }) => {
  const renderComponent = useCallback(() => {
    if (route.noInternet) return <NoInternet />;
    if (route.private) return <PrivateRoute {...route}>{component}</PrivateRoute>;
    return <Route {...route}>{component}</Route>;
  }, [component, route]);

  return (
    <Layout type={route.layout} {...route.layoutProps}>
      {renderComponent()}
    </Layout>
  );
};

RouteFromPath.propTypes = {
  component: node,
  render: func
};

export default RouteFromPath;
