import { useSelector, useDispatch } from 'react-redux';

import { useStatus } from 'hooks';
import { fetchRecommendedForYou } from 'state/actions/recommendedForYouActions';
import { FULFILLED, REJECTED, PENDING } from 'constants/actionStatusConstants';

export default () => {
  const { status } = useStatus(fetchRecommendedForYou);
  const dispatch = useDispatch();

  return {
    recommendedForYou: useSelector(state => state.recommendedForYou.data),
    fetchRecommendedForYou: () => dispatch(fetchRecommendedForYou()),
    error: status === REJECTED,
    success: status === FULFILLED,
    loading: status === PENDING
  };
};
