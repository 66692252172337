import { getCurrentScreen, getUserType, getValue } from 'services/analytics/utils';
import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  errorReceived: 'error_received'
};

class GAErrorEvent extends AnalyticEvent {
  static errorReceived({ code, errorMessage, userMessage }) {
    return new GAErrorEvent(
      EVENTS.errorReceived,
      {
        screen_name: getCurrentScreen(),
        user_type: getUserType(),
        backend_error_code: getValue(code),
        backend_response: getValue(errorMessage),
        user_message: getValue(userMessage)
      },
      true
    );
  }
}

export default GAErrorEvent;
