import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FULFILLED, PENDING } from 'constants/actionStatusConstants';

import { useStatus, useReset } from 'hooks';
import {
  fetchInsights as fetchInsightsAction,
  deleteInsight as deleteInsightAction
} from 'state/actions/insightsActions';

const useInsights = () => {
  const dispatch = useDispatch();
  const { status } = useStatus(fetchInsightsAction);
  const insights = useSelector(state => state.insights.data);
  useReset(fetchInsightsAction);
  useReset(deleteInsightAction);

  const fetchInsights = useCallback(() => dispatch(fetchInsightsAction()), [dispatch]);
  const deleteInsight = useCallback(insightId => dispatch(deleteInsightAction(insightId)), [
    dispatch
  ]);

  useEffect(() => {
    if (!insights.length) fetchInsights();
  }, [fetchInsights, insights]);

  return {
    insights,
    isLoading: status === PENDING,
    isFulfilled: status === FULFILLED,
    fetchMoreInsights: fetchInsights,
    deleteInsight
  };
};

export default useInsights;
