// App common constants

export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';

export const DETAIL_TYPES = {
  SERIES: 'series',
  EPISODE: 'episode',
  SINGLE_VIDEO: 'single video',
  MOVIE: 'Movie'
};

export const SETTING_TABS = {
  ACCOUNT: 0,
  FEEDBACK: 1,
  PRIVACY: 2,
  TERMS: 3,
  LOGOUT: 4
};

export const WIZARD = {
  FIRST_STEP: 1,
  SECOND_STEP: 2
};

export const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging'
};

export const EMAILS = {
  en: {
    support: 'insightsupport@emeritus.org'
  }
};

export const PROVIDERS = {
  google: 'google',
  facebook: 'facebook'
};

export const COUNTRIES = {
  USA: 'US',
  INDIA: 'IN'
};

export const MARKETING_LANDING_PARAMS = {
  step: 'step',
  moduleID: 'modid',
  autoReply: 'app',
  couponCode: 'cc',
  giftCard: 'gc',
  partnership: 'utm_source',
  email: 'utm_medium',
  campaign: 'utm_campaign',
  socialButtons: 'sb'
};

export const HEADER_HEIGHT = {
  desktop: '70px',
  mobile: '56px'
};

export const PAGE_LAYOUT = {
  main: 'main',
  auth: 'auth',
  marketingLanding: 'marketingLanding'
};

export const VIDEO_PLAYER_FAKE_EVENTS = {
  PLAYLIST_PREV: 'PLAYLIST_PREV',
  PLAYLIST_NEXT: 'PLAYLIST_NEXT'
};
