import { createSlice } from '@reduxjs/toolkit';
import {
  getVideoFulfilled,
  fetchEpisodeListFulfilled,
  fetchShareLinkFulfilled,
  setAutoplay,
  reset
} from 'state/actions/videoActions';

const initialState = {
  episodes: null,
  selected: null,
  serie: null,
  share: {},
  autoplay: false
};

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  extraReducers: {
    [getVideoFulfilled]: (state, { payload }) => {
      state.selected = payload;
    },
    [fetchEpisodeListFulfilled]: (state, { payload: { episodes, serie } }) => {
      state.episodes = episodes;
      state.serie = serie;
    },
    [fetchShareLinkFulfilled]: (state, { payload: { id, data } }) => {
      state.share[id] = data;
    },
    [setAutoplay]: (state, { payload: { autoplay } }) => {
      state.autoplay = autoplay;
    },
    [reset]: () => initialState
  }
});

export default videosSlice.reducer;
