import kalturaClient from 'httpClient/kaltura';

import { ASSET_REFERENCE_TYPE, OBJECT_TYPE, EPISODES_PAGE_SIZE } from './constants';

class VideoService {
  static getVideo({ id }) {
    const body = {
      id,
      assetReferenceType: ASSET_REFERENCE_TYPE.MEDIA
    };

    return kalturaClient.post('/asset/action/get', body);
  }

  static getEpisodeList({ id }) {
    const body = {
      filter: {
        objectType: OBJECT_TYPE.assetFilter,
        kSql: `SeriesId='${id} '`
      },
      pager: {
        objectType: OBJECT_TYPE.pager,
        pageSize: EPISODES_PAGE_SIZE
      }
    };

    return kalturaClient.post('/asset/action/list', body);
  }
}

export default VideoService;
