import createAsyncThunk from 'utils/createAsyncThunk';
import SearchService from 'services/searchService';
import parseError from 'utils/parseError';
import { CATEGORY_PAGE_SIZE } from 'services/constants';

export const searchVideo = createAsyncThunk(
  'search/searchVideo',
  async ({ term }, { getState }) => {
    const {
      search: { term: previousTerm, results: previousResults, totalCount }
    } = getState();
    const isNewSearch = term !== previousTerm;
    const pageIndex = isNewSearch ? 1 : previousResults.length / CATEGORY_PAGE_SIZE + 1;

    try {
      if (totalCount === 0 || totalCount > previousResults.length) {
        const {
          data: { objects, totalCount }
        } = await SearchService.searchVideo({
          term,
          pageIndex
        });
        const results = isNewSearch ? objects : [...previousResults, ...objects];

        return { results, totalCount };
      }
      return { objects: [], totalCount: 0 };
    } catch (response) {
      throw parseError(response);
    }
  }
);

export const { fulfilled: searchVideoFulfilled, pending: searchVideoPending } = searchVideo;
