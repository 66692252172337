import { PROMOTION_CODE_APPLIED } from 'services/analytics/constants';
import { getCurrency, getSubscriptions, getUser, getValue } from '../utils';
import CTAnalyticEvent from './CTAnalyticEvent';

const EVENTS = {
  paywallCard: 'Paywall_card',
  payClick: 'Pay_click'
};

class CTPaymentEvent extends CTAnalyticEvent {
  static paywallCard() {
    return new CTPaymentEvent(EVENTS.paywallCard, {}, true);
  }

  static payClick({ promocode, revenue, packageType, registrationId }) {
    return new CTPaymentEvent(
      EVENTS.payClick,
      {
        Revenue: getValue(revenue),
        Currency: getCurrency(),
        promocode: getValue(promocode),
        promocode_applied: promocode ? PROMOTION_CODE_APPLIED.yes : PROMOTION_CODE_APPLIED.no,
        module_id: getSubscriptions()[packageType].ID,
        module_name: getSubscriptions()[packageType].NAME,
        Registration_ID: registrationId || getValue(getUser()?.id)
      },
      true
    );
  }
}

export default CTPaymentEvent;
