import { SERVICES } from 'services/analytics/constants';

import AnalyticService from './AnalyticService';

class FaceboookService extends AnalyticService {
  constructor() {
    super();
    this.fbq = window.fbq || [];
    this.id = SERVICES.FACEBOOK;
  }

  logEvent(event) {
    if (process.env.API_ENV === 'production') {
      this.fbq('track', event.name, event.params);
    }
  }
}

export default FaceboookService;
