import { createSlice } from '@reduxjs/toolkit';
import { deleteInsightFulfilled, fetchInsightsFulfilled } from 'state/actions/insightsActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  data: [],
  totalCount: 0
};

const insightsReducer = createSlice({
  name: 'insights',
  initialState,
  extraReducers: {
    [fetchInsightsFulfilled]: (state, { payload: { insights, totalCount } }) => {
      state.data.push(...insights);
      state.totalCount += totalCount;
    },
    [deleteInsightFulfilled]: (state, { payload: { insightId: deletedId } }) => {
      state.data = state.data.filter(({ insightId }) => insightId !== deletedId);
      state.totalCount -= 1;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default insightsReducer.reducer;
