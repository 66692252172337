import { SERVICES } from 'services/analytics/constants';
import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  completeRegistration: 'CompleteRegistration'
};

class FBSignUpEvent extends AnalyticEvent {
  constructor(name, params, setContent = false, services = [SERVICES.FACEBOOK]) {
    super(name, params, setContent, services);
  }

  static completeRegistration() {
    return new FBSignUpEvent(EVENTS.completeRegistration, {});
  }
}

export default FBSignUpEvent;
