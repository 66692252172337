/* eslint-disable camelcase */
import { createAction } from '@reduxjs/toolkit';

import parseError from 'utils/parseError';
import createAsyncThunk from 'utils/createAsyncThunk';

import userService from 'services/userService';
import subscriptionService from 'services/subscriptionService';
import AnalyticManager from 'services/analytics/AnalyticManager';
import GASignUpEvent from 'services/analytics/events/GASignUpEvent';
import GASignInEvent from 'services/analytics/events/GASignInEvent';
import GASettingsEvent from 'services/analytics/events/GASettingsEvent';
import GAErrorEvent from 'services/analytics/events/GAErrorEvent';
import CTSignInEvent from 'services/analytics/events/CTSignInEvent';
import CTSignUpEvent from 'services/analytics/events/CTSignUpEvent';
import FBSignUpEvent from 'services/analytics/events/FBSignUpEvent';
import { CHANNELS, CT_STATUS } from 'services/analytics/constants';

import firebaseService from 'services/firebaseService';
import { ADMIN_KS } from 'services/constants';

import { COUNTRIES } from 'constants/constants';
import {
  INCORRECT_PASSWORD,
  DEVICE_ANOTHER_HOUSEHOLD,
  HOUSEHOLD_EXCEEDED_LIMIT,
  LOGIN_POPUP_CLOSED,
  SERVICE_FORBIDDEN_ERROR,
  DEVICE_ALREADY_EXISTS_IN_HOUSEHOLD
} from 'constants/errors';
import { getIntl } from 'locales/intl';

const fetchToken = createAsyncThunk('user/fetchToken', async ({ ks }) => {
  try {
    const {
      data: { id, token, hashType }
    } = await userService.fetchToken({ ks });

    return {
      id,
      token,
      hashType
    };
  } catch (response) {
    throw parseError(response);
  }
});

const addDeviceHousehold = async ({ userId, ks, email, udid }) => {
  try {
    await userService.addDeviceHousehold({
      userId,
      ks,
      udid
    });
  } catch (response) {
    if (response?.error?.code === DEVICE_ANOTHER_HOUSEHOLD) {
      const {
        data: { ks: adminKs }
      } = await userService.startSession({
        ks,
        id: ADMIN_KS.id,
        token: ADMIN_KS.token,
        hashType: ADMIN_KS.hashType
      });

      await userService.removeDeviceHousehold({ ks: adminKs, udid });

      await addDeviceHousehold({ userId, ks, email, udid });
    } else if (response?.error?.code === HOUSEHOLD_EXCEEDED_LIMIT) {
      const {
        data: { objects }
      } = await userService.listDeviceHousehold({ ks });
      const household = objects.find(o => !o.lastActivityTime);

      if (household) {
        await userService.removeDeviceHousehold({ ks, udid: household.udid });
      }

      await addDeviceHousehold({ userId, ks, email, udid });
    } else if (response?.error?.code === DEVICE_ALREADY_EXISTS_IN_HOUSEHOLD) {
      return {};
    } else if (response?.error?.code === SERVICE_FORBIDDEN_ERROR) {
      await userService.addHousehold({ ks, email });

      await addDeviceHousehold({ userId, ks, email, udid });
    } else {
      throw parseError(response);
    }
  }
};

export const signIn = createAsyncThunk(
  'user/signIn',
  async ({ email, password }, { dispatch, getState }) => {
    try {
      const { udid } = getState().session;

      const {
        data: {
          user,
          loginSession: { ks }
        }
      } = await userService.signIn({ email, password, udid });

      const {
        data: { objects: entitlements }
      } = await subscriptionService.getEntitlement({ isExpired: false, ks });

      await addDeviceHousehold({ userId: user.id, ks, email, udid });

      dispatch(fetchToken({ ks }));

      AnalyticManager.logEvent(
        GASignInEvent.signInStatusSuccess({ channel: CHANNELS.email, user, entitlements })
      );
      AnalyticManager.logEvent(
        GASignInEvent.signInSuccess({ channel: CHANNELS.email, user, entitlements })
      );
      AnalyticManager.login({ user, email });
      AnalyticManager.logEvent(
        CTSignInEvent.signIn({
          channel: CHANNELS.email,
          status: CT_STATUS.success,
          userId: user.id
        })
      );

      return {
        ks,
        user,
        channel: CHANNELS.email,
        entitlements
      };
    } catch (response) {
      const errorMessage = parseError(response);

      AnalyticManager.logEvent(GASignInEvent.signInStatusFail({ channel: CHANNELS.email }));
      AnalyticManager.logEvent(
        CTSignInEvent.signIn({ channel: CHANNELS.email, status: CT_STATUS.fail })
      );
      AnalyticManager.logout();
      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response?.error?.code,
          userMessage: errorMessage,
          errorMessage
        })
      );

      throw errorMessage;
    }
  }
);

export const signInWithProvider = createAsyncThunk(
  'user/signInWithProvider',
  async ({ provider, channel }, { dispatch, getState }) => {
    try {
      const {
        additionalUserInfo: {
          profile: { email, given_name, family_name },
          isNewUser
        }
      } = await firebaseService.signIn({ providerName: provider });
      const idToken = await firebaseService.getIdToken();

      const {
        session: { udid },
        info: {
          deviceView: { view }
        }
      } = getState();

      const {
        data: {
          user,
          loginSession: { ks }
        }
      } = await userService.signIn({
        email,
        password: idToken,
        udid,
        isSocial: true
      });

      user.firstName = given_name;
      user.lastName = family_name;

      const {
        data: { objects: entitlements }
      } = await subscriptionService.getEntitlement({ isExpired: false, ks });

      dispatch(fetchToken({ ks }));

      AnalyticManager.login({ user, email });

      if (isNewUser) {
        const { data } = await userService.addHousehold({ ks, email });
        user.householdId = data.id;

        AnalyticManager.logEvent(
          CTSignUpEvent.signUp({
            channel: CHANNELS.email,
            status: CT_STATUS.success,
            userId: user.id
          })
        );
        AnalyticManager.logEvent(GASignUpEvent.signUpStatusSuccess({ channel, user }));
        AnalyticManager.logEvent(GASignUpEvent.signUpSuccess({ channel, user }));
      } else {
        AnalyticManager.logEvent(
          GASignInEvent.signInStatusSuccess({ channel, user, entitlements })
        );
        AnalyticManager.logEvent(GASignInEvent.signInSuccess({ channel, user, entitlements }));
        AnalyticManager.logEvent(
          CTSignInEvent.signIn({
            channel: CHANNELS.email,
            status: CT_STATUS.success,
            userId: user.id
          })
        );
      }

      await addDeviceHousehold({ userId: user.id, ks, email, udid });

      return {
        ks,
        user,
        channel,
        view,
        entitlements
      };
    } catch (response) {
      if (response?.code === LOGIN_POPUP_CLOSED) throw new Error();

      AnalyticManager.logEvent(GASignInEvent.signInStatusFail({ channel }));
      AnalyticManager.logEvent(
        CTSignInEvent.signIn({ channel: CHANNELS.email, status: CT_STATUS.fail })
      );
      AnalyticManager.logout();
      throw parseError(response);
    }
  }
);

export const fetchInitialData = createAsyncThunk('user/fetchInitialData', async () => {
  try {
    const {
      data: { ks }
    } = await userService.anonymousLogin();

    const {
      data: {
        objects: [country]
      }
    } = await userService.getCountry({ ks });

    return {
      ks,
      country
    };
  } catch (response) {
    throw parseError(response);
  }
});

export const logout = createAsyncThunk(
  'user/logout',
  async ({ expired = false } = {}, { getState }) => {
    const { ks, udid } = getState().session;
    userService.logout();
    userService.removeDeviceHousehold({ ks, udid });
    return { expired };
  }
);

export const signUp = createAsyncThunk('user/signup', async (newUser, { dispatch, getState }) => {
  try {
    const {
      deviceView: { view }
    } = getState().info;
    const { udid } = getState().session;
    const { email, password } = newUser;

    await userService.signUp(newUser);

    const {
      data: {
        loginSession: { ks },
        user
      }
    } = await userService.signIn({ email, password });

    dispatch(fetchToken({ ks }));

    const {
      data: { id: householdId }
    } = await userService.addHousehold({ ks, email });

    await addDeviceHousehold({ userId: user.id, ks, email, udid });

    AnalyticManager.logEvent(GASignUpEvent.signUpStatusSuccess({ channel: CHANNELS.email, user }));
    AnalyticManager.logEvent(GASignUpEvent.signUpSuccess({ channel: CHANNELS.email, user }));
    AnalyticManager.login({ user, email });
    AnalyticManager.logEvent(FBSignUpEvent.completeRegistration());
    AnalyticManager.logEvent(
      CTSignUpEvent.signUp({ channel: CHANNELS.email, status: CT_STATUS.success, userId: user.id })
    );

    return {
      ks,
      user,
      householdId,
      view
    };
  } catch (response) {
    const errorMessage = parseError(response);

    AnalyticManager.logEvent(GASignUpEvent.signUpStatusFail({ channel: CHANNELS.email }));
    AnalyticManager.logEvent(
      CTSignUpEvent.signUp({ channel: CHANNELS.email, status: CT_STATUS.fail })
    );
    AnalyticManager.logout();
    AnalyticManager.logEvent(
      GAErrorEvent.errorReceived({
        code: response?.error?.code,
        userMessage: errorMessage,
        errorMessage
      })
    );

    throw errorMessage;
  }
});

export const revokeSession = createAsyncThunk('user/revokeSession', async (_, { dispatch }) => {
  try {
    const { data } = await userService.revokeSession();
    return data;
  } catch (response) {
    dispatch(logout());
    throw parseError(response);
  }
});

export const startSession = createAsyncThunk('user/startSession', async (_, { dispatch }) => {
  try {
    const { data } = await userService.startSession();
    return data;
  } catch (response) {
    dispatch(logout());
    throw parseError(response);
  }
});

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ newPassword, currentPassword }, { getState }) => {
    try {
      const {
        session: {
          user: { username }
        }
      } = getState();
      const { data } = await userService.changePassword({ newPassword, currentPassword, username });

      AnalyticManager.logEvent(GASettingsEvent.changePassword());

      return {
        data
      };
    } catch (response) {
      const errorMessage = parseError(response);

      if (response?.error?.code === INCORRECT_PASSWORD) {
        AnalyticManager.logEvent(
          GAErrorEvent.errorReceived({
            code: response?.error?.code,
            userMessage: getIntl().formatMessage({ id: 'password.wrongPassword' }),
            errorMessage
          })
        );

        throw parseError({
          error: { message: 'password.wrongPassword' }
        });
      }

      AnalyticManager.logEvent(
        GAErrorEvent.errorReceived({
          code: response?.error?.code,
          userMessage: errorMessage,
          errorMessage
        })
      );

      throw errorMessage;
    }
  }
);

export const resetPassword = createAsyncThunk('user/resetPassword', async ({ password, token }) => {
  try {
    await userService.resetPassword({ password, token });
  } catch (response) {
    const errorMessage = parseError(response);

    AnalyticManager.logEvent(
      GAErrorEvent.errorReceived({
        code: response?.error?.code,
        userMessage: errorMessage,
        errorMessage
      })
    );

    throw errorMessage;
  }
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async ({ email }) => {
  try {
    await userService.forgotPassword({ email });
  } catch (response) {
    const errorMessage = parseError(response);

    AnalyticManager.logEvent(
      GAErrorEvent.errorReceived({
        code: response?.error?.code,
        userMessage: errorMessage,
        errorMessage
      })
    );

    throw errorMessage;
  }
});

export const initSession = createAction('user/initSession');

// TODO: the change country action is for qa purposes, remove later
export const changeCountry = createAsyncThunk('user/changeCountry', async (_, { getState }) => {
  try {
    const {
      session: { country }
    } = getState();

    const dataUSA = {
      code: COUNTRIES.USA,
      name: 'United States'
    };

    const dataIndia = {
      code: COUNTRIES.INDIA,
      name: 'India'
    };

    const newCountry = country?.code === COUNTRIES.INDIA ? dataUSA : dataIndia;

    return { country: newCountry };
  } catch (response) {
    throw parseError(response);
  }
});

export const refreshSession = createAsyncThunk('user/refreshSession', async (_, { getState }) => {
  const {
    data: { ks: anonymousKs }
  } = await userService.anonymousLogin();
  const { id, token, hashType } = getState().session.authenticated;
  const {
    data: { ks }
  } = await userService.startSession({
    ks: anonymousKs,
    id,
    token,
    hashType
  });

  return {
    ks
  };
});

export const setKs = createAction('user/setKs', ks => ({
  payload: {
    ks
  }
}));

export const { fulfilled: signInFulfilled, rejected: signInRejected, reset: signInReset } = signIn;
export const {
  fulfilled: signInWithProviderFulfilled,
  rejected: signInWithProviderRejected,
  reset: signInWithProviderReset
} = signInWithProvider;
export const { fulfilled: fetchInitialDataFulfilled } = fetchInitialData;
export const { fulfilled: signUpFulfilled, reset: signUpReset } = signUp;
export const { fulfilled: logoutFulfilled, reset: logoutReset } = logout;
export const { fulfilled: fetchTokenFulfilled } = fetchToken;
export const { fulfilled: revokeSessionFulfilled } = revokeSession;
export const { fulfilled: changePasswordFulfilled } = changePassword;
export const { fulfilled: refreshSessionFulfilled } = refreshSession;
export const { fulfilled: changeCountryFulfilled } = changeCountry;
