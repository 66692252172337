import store from 'state/store/storeRegistry';

import { USER_TYPE, SCREENS, NOT_AVAILABLE } from 'services/analytics/constants';
import { getSubscriptionStatus } from 'services/analytics/utils';
import ROUTES_MAP from 'services/analytics/routes';

import AnalyticEvent from './AnalyticEvent';

const EVENTS = {
  changePassword: 'change_password',
  changePasswordClick: 'change_password_click',
  feedback: 'feedback_help',
  logOut: 'log_out',
  myAccount: 'my_account',
  privacy: 'privacy_policy',
  settingsIconClick: 'settings_icon_click',
  subscriptionOpen: 'subscription_open',
  terms: 'terms_of_use'
};

class GASettingsEvent extends AnalyticEvent {
  constructor(name, params, setContent = false) {
    const { previousPath } = store.getState().routing;
    const previousScreen = ROUTES_MAP[previousPath] || NOT_AVAILABLE;

    const eventParams = {
      screen_name: SCREENS.settings,
      user_type: USER_TYPE.loggedIn,
      previous_screen: previousScreen,
      subscription_status: getSubscriptionStatus(),
      ...params
    };

    super(name, eventParams, setContent);
  }

  static changePassword() {
    return new GASettingsEvent(EVENTS.changePassword);
  }

  static changePasswordClick() {
    return new GASettingsEvent(EVENTS.changePasswordClick);
  }

  static feedback() {
    return new GASettingsEvent(EVENTS.feedback);
  }

  static logOut() {
    return new GASettingsEvent(EVENTS.logOut);
  }

  static myAccount() {
    return new GASettingsEvent(EVENTS.myAccount);
  }

  static privacy() {
    return new GASettingsEvent(EVENTS.privacy);
  }

  static settingsIconClick() {
    return new GASettingsEvent(EVENTS.settingsIconClick);
  }

  static subscriptionOpen() {
    return new GASettingsEvent(EVENTS.subscriptionOpen, {}, true);
  }

  static terms() {
    return new GASettingsEvent(EVENTS.terms);
  }
}

export default GASettingsEvent;
