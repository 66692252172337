import { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getVideo,
  fetchEpisodeList,
  getVideoFulfilled,
  resetGetVideo,
  reset
} from 'state/actions/videoActions';
import { useStatus, useVideos } from 'hooks';
import { buildQueryUrl } from 'utils/helpers';

import { PENDING, FULFILLED, REJECTED } from 'constants/actionStatusConstants';
import { DETAIL_TYPES } from 'constants/constants';
import routes from 'constants/routesPaths';
import store from 'state/store/storeRegistry';

export default ({ assetId, seriesId }) => {
  const history = useHistory();
  const [initialLoading, setInitialLoading] = useState(true);
  const [playItem, setPlayItem] = useState(() => () => {});
  const dispatch = useDispatch();
  const { selected, episodes, serie } = useVideos();
  const { metas: { SeriesID: { value: selectedSeriesId } = {} } = {} } = selected || serie || {};
  const getVideoRequest = useCallback(() => dispatch(getVideo({ assetId: assetId || serie?.id })), [
    dispatch,
    assetId,
    serie?.id
  ]);
  const { ks } = store.getState().session;
  const { status: videoStatus } = useStatus(getVideo);

  const type = useMemo(() => {
    if (seriesId && (!assetId || serie?.id === assetId)) return DETAIL_TYPES.SERIES;
    if (assetId && seriesId) return DETAIL_TYPES.EPISODE;
    if (assetId) return DETAIL_TYPES.SINGLE_VIDEO;
  }, [seriesId, assetId, serie]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (!assetId && !serie) return;
    if (!ks) return;
    if (
      !selected ||
      (seriesId && selectedSeriesId === seriesId && assetId && assetId !== selected?.id)
    ) {
      getVideoRequest();
    } else {
      setInitialLoading(false);
    }
  }, [seriesId, selected, getVideoRequest, assetId, serie, selectedSeriesId, ks]);

  useEffect(() => {
    if (videoStatus === FULFILLED) setInitialLoading(false);
  }, [videoStatus]);

  useEffect(() => () => dispatch(resetGetVideo()), []);

  useEffect(() => {
    const { metas: { SeriesID: { value: seriesIdFromSerie } = {} } = {} } = serie || {};
    seriesId &&
      seriesIdFromSerie &&
      selectedSeriesId &&
      seriesIdFromSerie !== selectedSeriesId &&
      console.error(
        `video seriesId ${selectedSeriesId} is different than serie seriesId ${seriesIdFromSerie} and requested serieId ${seriesId}`
      );
    if (
      seriesId &&
      seriesIdFromSerie !== seriesId &&
      (type === DETAIL_TYPES.EPISODE || type === DETAIL_TYPES.SERIES)
    ) {
      dispatch(fetchEpisodeList({ seriesId }));
    }
  }, [seriesId, type, dispatch, serie, selectedSeriesId]);

  const onPlaylistItemChange = entryId => {
    if (entryId && Number(entryId) === assetId) return;
    dispatch(getVideoFulfilled(episodes.find(({ id }) => id === Number(entryId))));
    history.replace(
      buildQueryUrl(routes.detail, {
        seriesId,
        assetId: entryId
      })
    );
  };

  const changePlaylistItem = assetId => {
    const { scrollTo } = window;

    if (!episodes) return;

    const index = episodes.findIndex(({ id }) => id === assetId);
    onPlaylistItemChange(assetId);
    scrollTo(0, 0);
    playItem(index);
  };

  const isLoading = initialLoading || videoStatus === PENDING;
  const isSuccess = videoStatus === FULFILLED;
  const isError = videoStatus === REJECTED;

  return {
    episodes,
    isLoading,
    isSuccess,
    isError,
    changePlaylistItem,
    onPlaylistItemChange,
    onVideoPlayerInit: ({ playItem }) => setPlayItem(() => playItem),
    detail: selected,
    serie,
    type
  };
};
