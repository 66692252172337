import React from 'react';
import { bool, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import HomeIcon from 'components/icons/HomeIcon';
import BrowseIcon from 'components/icons/BrowseIcon';
import routes from 'constants/routesPaths';
import useMediaQueries from 'hooks/useMediaQueries';

import { primaryColor, grey } from 'styles/_variables.scss';

const messages = defineMessages({
  termsOfUse: { id: 'footer.termsOfUse' },
  privacyPolicy: { id: 'footer.privacyPolicy' }
});

const Footer = ({ className = '', logoUnclickable = false }) => {
  const { formatMessage } = useIntl();
  const { isMobileOrTablet } = useMediaQueries();
  const currentPath = useSelector(state => state.routing.currentPath);

  return (
    <footer
      className={cn('footer d-flex justify-content-center justify-content-lg-between', className)}
    >
      {logoUnclickable ? (
        <div className="d-none d-lg-flex ml-5 align-items-center">
          <LogoIcon />
        </div>
      ) : (
        <Link to={routes.home} className="d-none d-lg-flex ml-5 align-items-center">
          <LogoIcon data-testid="footerBrandLogo" />
        </Link>
      )}
      <ul className="d-flex justify-content-center align-items-center mr-lg-5">
        {isMobileOrTablet ? (
          <>
            <li className="d-inline-flex">
              <Link to={routes.home} className="d-inline-flex">
                <HomeIcon color={currentPath === routes.home ? primaryColor : grey} />
              </Link>
            </li>
            <li className="d-inline-flex">
              <Link
                to={{
                  pathname: routes.explore,
                  state: { autoFocus: false }
                }}
                className="d-inline-flex"
              >
                <BrowseIcon color={currentPath === routes.explore ? primaryColor : grey} />
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="mr-6">
              <Link
                to={routes.termsOfUse}
                target="_blank"
                className="text-b2 text-white text-decoration-none"
              >
                {formatMessage(messages.termsOfUse)}
              </Link>
            </li>
            <li>
              <Link
                to={routes.privacyPolicy}
                target="_blank"
                className="text-b2 text-white text-decoration-none"
              >
                {formatMessage(messages.privacyPolicy)}
              </Link>
            </li>
          </>
        )}
      </ul>
    </footer>
  );
};

Footer.propTypes = {
  className: string,
  logoUnclickable: bool
};

export default Footer;
