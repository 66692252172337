import React, { useCallback, useEffect, useState } from 'react';
import { bool, oneOf } from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import cn from 'classnames';

import { ReactComponent as MobileLogoIconLightBg } from 'assets/icons/logo-light-bg-sm.svg';
import { ReactComponent as MobileLogoIconDarkBg } from 'assets/icons/logo-dark-bg-sm.svg';
import { ReactComponent as DesktopLogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as MobileSearchIcon } from 'assets/icons/search-sm.svg';
import { useMediaQueries, useSession, useToggle } from 'hooks';

import AnalyticManager from 'services/analytics/AnalyticManager';
import GABrowseEvent from 'services/analytics/events/GABrowseEvent';
import GASignInEvent from 'services/analytics/events/GASignInEvent';

// TODO: ArrowDownIcon is removed until the implementation of the dropdown element
// import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';

import routes, { COURSES_URL } from 'constants/routesPaths';
import { UI_THEMES } from 'constants/styles';
import { HEADER_HEIGHT } from 'constants/constants';
import { reset } from 'state/actions/videoActions';

import Hamburger from 'components/common/Hamburger';

import ActionMenu from './ActionMenu';

const messages = defineMessages({
  explore: { id: 'navigation.links.explore' },
  myInsights: { id: 'navigation.links.myInsights' },
  courses: { id: 'navigation.links.courses' }
});

const Themes = {
  [UI_THEMES.dark]: {
    modifier: 'dark',
    icon: <MobileLogoIconDarkBg data-testid="headerBrandLogoMobile" />
  },
  [UI_THEMES.light]: {
    modifier: 'light',
    icon: <MobileLogoIconLightBg data-testid="headerBrandLogoMobile" />
  }
};

const Header = ({
  skippable,
  theme = UI_THEMES.dark,
  hideNav = false,
  logoUnclickable = false
}) => {
  const { pathname, state: { redirect, redirectState = {} } = {} } = useLocation();
  const { user } = useSession();
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { isDesktop } = useMediaQueries();
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [toggled, toggle] = useToggle(false);
  const location = useLocation();

  const handleScroll = useCallback(() => {
    const currentScrollPosition = window.scrollY;

    setScrollPosition(currentScrollPosition);
    setVisible(scrollPosition > currentScrollPosition || scrollPosition < 0);
  }, [scrollPosition, setVisible]);

  useEffect(() => {
    if (isDesktop) return;

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, isDesktop]);

  const handleLogClick = () => {
    AnalyticManager.logEvent(GASignInEvent.signupSigninInitiate({ user }));
    dispatch(reset());
  };

  const handleSkipClick = () => {
    push(redirect?.includes(routes.detail) ? redirect : routes.home, { ...redirectState });
  };

  const handleLinkClick = () => {
    const { pathname } = location;
    AnalyticManager.logEvent(GABrowseEvent.navigationExplore({ currentPath: pathname }));
  };

  useEffect(() => {
    let headerHeight;
    if (visible) {
      headerHeight = isDesktop ? HEADER_HEIGHT.desktop : HEADER_HEIGHT.mobile;
    } else {
      headerHeight = '0px';
    }
    // in order to sticky elements to the header we save css custom property with header height
    document.documentElement.style.setProperty('--header-height', headerHeight);
  }, [visible, isDesktop]);

  return (
    <header
      className={cn('header', `header--${Themes[theme].modifier}`, {
        'header--scroll-down': !isDesktop && !visible && !toggled
      })}
    >
      <nav className="d-flex justify-content-between align-items-center px-4 align-items-lg-stretch px-lg-0">
        {!isDesktop && (
          <>
            {logoUnclickable ? (
              <div>{Themes[theme].icon}</div>
            ) : (
              <Link to={routes.home}>{Themes[theme].icon}</Link>
            )}

            {theme === UI_THEMES.dark && !hideNav ? (
              <aside className="d-flex">
                <Link to={routes.explore} className="d-inline-block">
                  <MobileSearchIcon className="mr-4" />
                </Link>
                <Hamburger toggled={toggled} onToggle={toggle} />
              </aside>
            ) : (
              <>{skippable && <CrossIcon onClick={handleSkipClick} />}</>
            )}
          </>
        )}

        <div className="d-none d-lg-flex" data-testid="desktopNav">
          {logoUnclickable ? (
            <div className="mt-lg-3 mr-lg-6 ml-lg-5">
              <DesktopLogoIcon data-testid="headerBrandLogoDesktop" />
            </div>
          ) : (
            <Link to={routes.home} className="mt-lg-3 mr-lg-6 ml-lg-5">
              <DesktopLogoIcon data-testid="headerBrandLogoDesktop" />
            </Link>
          )}

          {!hideNav && (
            <ul className="d-none d-lg-flex align-items-center header__links">
              <li className="mr-6">
                <Link
                  onClick={handleLinkClick}
                  to={routes.explore}
                  className={pathname === routes.explore ? 'text-weight-bold' : ''}
                >
                  {formatMessage(messages.explore)}
                  {/*
                    // TODO: ArrowDownIcon is removed until the implementation of the dropdown element
                    <ArrowDownIcon className="ml-2" />
                  */}
                </Link>
              </li>
              {user && (
                <li className="mr-6">
                  <Link
                    to={routes.myInsights}
                    className={pathname === routes.myInsights ? 'text-weight-bold' : ''}
                  >
                    {formatMessage(messages.myInsights)}
                  </Link>
                </li>
              )}
              <li>
                <Link to={{ pathname: COURSES_URL }} target="_blank">
                  {formatMessage(messages.courses)}
                </Link>
              </li>
            </ul>
          )}
        </div>
        {!hideNav && (
          <ActionMenu
            onLogClick={handleLogClick}
            className="header__menu-right d-none d-lg-flex mr-5"
          />
        )}
      </nav>
    </header>
  );
};

Header.propTypes = {
  skippable: bool,
  theme: oneOf([UI_THEMES.dark, UI_THEMES.light]),
  hideNav: bool,
  logoUnclickable: bool
};

export default Header;
