import { useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import useStatus from 'hooks/useStatus';
import { searchVideo } from 'state/actions/searchActions';
import { PENDING } from 'constants/actionStatusConstants';

const DEBOUNCE_WAIT_TIME = 1000;
const MINIMUM_SEARCH_LENGTH = 3;

export default () => {
  const [term, setTerm] = useState('');
  const { status } = useStatus(searchVideo);
  const { results, totalCount } = useSelector(state => state.search);
  const dispatch = useDispatch();
  const typingRef = useRef(false);

  const search = useCallback(
    value => {
      if (value.trim() !== '' && value.length >= MINIMUM_SEARCH_LENGTH) {
        dispatch(searchVideo({ term: value }));
      }
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      value => {
        typingRef.current = false;
        search(value);
      },
      DEBOUNCE_WAIT_TIME,
      { trailing: true }
    ),
    []
  );

  const onSearch = useCallback(
    value => {
      setTerm(value);
      search(value);
    },
    [setTerm, search]
  );

  const onDebounceSearch = useCallback(
    value => {
      typingRef.current = true;
      setTerm(value);
      debouncedSearch(value);
    },
    [setTerm, debouncedSearch]
  );

  return {
    onSearch,
    onDebounceSearch,
    results,
    term,
    typing: typingRef.current,
    hasMore: totalCount && results?.length !== totalCount,
    isLoading: status === PENDING
  };
};
